import { gql } from "@apollo/client";

export const LIST_COMPANIES = gql`
    fragment List_companys_1G22uz on Query {
        companies(first: $count, last : $reversecount , after: $endcursor, before: $startcursor) {
            edges {
                node {
                    id
                    name
                    type
                    status
                    createdAt
                    subscriptionStatus
                    pgwSubscriptionPlanId
                    __typename
                }
                cursor
            }
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
            totalCount
        }
    }
`;
