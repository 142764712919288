import { useContext, useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormButton from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import { useMutation } from "@apollo/client";
import Page from "../../../../components/page/Page";
import CircularProgress from "../../../../components/facebook_progress/FacebookProgress";
import MenuItem from "@material-ui/core/MenuItem";
import { useHistory } from "react-router-dom";
import { useStyles } from "./style";
import FormInput from "../../../../components/form/form_input/FormInput";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FormFeedback from "../../../../components/form_feedback/FormFeedback";
import { ADD_BUSINESS_ADMIN_CALL_FOR_HELP } from "adapters/mutations/AddBusinessAdminCallForHelp";
import { RootState } from "redux/reducers/rootReducer";
import { useSelector } from "react-redux";
import { CALL_FOR_HELP_REFETCH } from "adapters/queries/CallForHelpRefetch";
import { SnackbarContext } from "components/common_snackbar/CommonSnackBar";
import { Typography } from "@material-ui/core";
import { Chip } from "@mui/material";
import { Close } from '@mui/icons-material'
// import { FormLabel } from "@material-ui/core";

const priorityOptions = [
  { title: "Low", value: 0 },
  { title: "Medium", value: 1 },
  { title: "High", value: 2 },
];

const re = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm


const schema = yup.object().shape({
  name: yup.string().required(),
  domain: yup.string().matches(re, { message: "Please add valid domain" }).required(),
  primaryLanguage: yup.string().required()
});

export default function AddCallForHelpProfile() {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useSelector((state: RootState) => state.auth);
  const [isBotInterectionsOn, setIsBotInterectionsOn] = useState(false);
  const [isWhatsappRequired, setIsWhatsappRequired] = useState(false);
  const [isChannelSwitchingOn, setIsChannelSwitchingOn] = useState(false);
  const [isAgentHandOverRequired, setIsAgentHandOverRequired] = useState(false);
  const [isScreenSharingRequired, setIsScreenSharingRequired] = useState(false);
  const [isWebRTCVideoRequired, setIsWebRTCVideoRequired] = useState(false);
  const [callRecording, setCallRecording] = useState(false);
  const [isAppointmentsOn, setIsAppointmentsOn] = useState(false);
  const [isRaiseTicketRequired, setIsRaiseTicketRequired] = useState(false);
  const [isCallbackOn, setIsCallbackOn] = useState(false);
  // const [isCallbackOff, setIsCallbackOff] = useState(false);

  const [languageOptions, setLanguageOptions] = useState<Array<{ title: string, value: string }>>([
    { "title": "English", "value": "en" },
    { "title": "Spanish", "value": "es" },
    { "title": "French", "value": "fr" }])
  const [selectedLanguage, setSelectedLanguage] = useState<Array<{ title: string, value: string }>>([])

  const [status, setStatus] = useState(1);
  const [isForwardToPhoneRequired, setIsForwardToPhoneRequired] =
    useState(false);
  const { setSnack } = useContext(SnackbarContext);

  const [mutate, { loading, error }] = useMutation(
    ADD_BUSINESS_ADMIN_CALL_FOR_HELP,
    {
      onCompleted: () => {
        history.push("/bot-profile");
      },
      onError: () => {
        console.log(error?.message);
        setSnack({ message: "Create BOT Profile failed", severity: 'error', open: true });
      },
    }
  );

  // console.log(isWhatsappRequired, isChannelSwitchingOn, isAudioRequired, isAppointmentsOn, isCallbackOn)

  useEffect(() => {
    console.log(process.env);

    if (!user) {
      history.push("/login");
    }
  }, [user, history]);

  useEffect(() => {
    document.title = "Add BOT Profile";
  }, []);

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue
  } = useForm({
    resolver: yupResolver(schema),
  });

  const languageChange: Array<string> = watch(["languages"]);

  useEffect(() => {
    if (languageChange[0]) {
      const newLang = languageOptions.find(opt => opt.value == languageChange[0])

      if (newLang) {
        setSelectedLanguage([...selectedLanguage, newLang])
        setLanguageOptions(languageOptions.filter(opt => opt.value != languageChange[0]))
      }
      setValue("languages", "")
    }

  }, [languageChange]);


  const onSubmit = ({
    name,
    description,
    domain,
    priority,
    forwardMessage,
    forwardNumber,
    primaryLanguage
  }: any) => {
    console.log("primaryLanguage", primaryLanguage);

    mutate({
      refetchQueries: [{ query: CALL_FOR_HELP_REFETCH }],
      variables: {
        input: {
          name: name,
          description: description,
          domain: domain,
          companyId: user?.companyId,
          priority: priority! || 0,
          isAgentRequired: isAgentHandOverRequired,
          primaryLanguage,
          languages: JSON.stringify(selectedLanguage),
          // isWebRTCAudioRequired: isAudioRequired,
          isWebRTCVideoRequired: isWebRTCVideoRequired! || false,
          isScreenSharingRequired: isScreenSharingRequired! || false,
          isForwardToPhoneRequired: isForwardToPhoneRequired! || false,
          isBotInterectionsOn,
          isWhatsappRequired,
          isChannelSwitchingOn,
          isAppointment: isAppointmentsOn,
          isRaiseTicketRequired: isRaiseTicketRequired,
          isCallbackOn,
          forwardNumber: forwardNumber!,
          forwardMessage: forwardMessage!,
          status: status,
          callRecording: callRecording! || false,
        },
      },
    });
  };

  return (
    <Page title="Add BOT Profile">
      <Container className={classes.container} maxWidth={false}>
        <Card>
          <CardHeader
            title="BOT Profile"
          />
          <Divider />
          <form onSubmit={handleSubmit(onSubmit)}>
            <CardContent>
              <FormInput
                control={control}
                type="text"
                name="name"
                label="Name*"
                error={errors && errors.name}
              />
              <br />
              <br />
              <FormInput
                control={control}
                type="text"
                name="description"
                label="Description"
              />
              <br />
              <br />
              <FormInput
                control={control}
                type="text"
                name="domain"
                label="Domain*"
                error={errors && errors.domain}
              />
              <br />
              <br />
              <FormInput
                control={control}
                type="text"
                name="languages"
                label="Languages"
                placeholder="Please select one or more language"
                select={true}
              >
                {languageOptions?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.title}
                  </MenuItem>
                ))}
              </FormInput>
              <br />
              {selectedLanguage.length > 0 && <br />}
              <div>{selectedLanguage.map(selectedLang => <Chip label={selectedLang.title}
                variant='filled'
                key={selectedLang.value}
                color={'secondary'}
                onDelete={() => {
                  const delLang = selectedLanguage.find(opt => opt.value == selectedLang.value)
                  console.log(delLang);

                  if (delLang) {
                    console.log(selectedLanguage.filter(opt => opt.value != delLang.value));

                    setSelectedLanguage(selectedLanguage.filter(opt => opt.value != delLang.value))
                    setLanguageOptions([...languageOptions, delLang])
                  }
                }}
                deleteIcon={<Close />}
                style={{ margin: 4, cursor: 'move' }}
                className={classes.chip} />)}</div>
              <br />
              <FormInput
                control={control}
                type="text"
                name="primaryLanguage"
                label="Primary Language*"
                select="select"
                error={errors && errors.primaryLanguage}
              >
                {selectedLanguage?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.title}
                  </MenuItem>
                ))}
              </FormInput>
              <br />
              <br />
              <FormInput
                control={control}
                type="text"
                name="priority"
                label="Priority"
                select="select"
              >
                {priorityOptions?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.title}
                  </MenuItem>
                ))}
              </FormInput>
              <br />
              <br />
              <Grid container spacing={3}>

                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={(event: any) => {
                          setIsCallbackOn(event.target.checked);
                        }}
                        color="primary"
                        name="callRecording"
                        inputProps={{
                          "aria-label": "primary checkbox",
                        }}
                      />
                    }
                    label="Callback"
                    labelPlacement="start"
                  />
                </Grid>

                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={(event: any) => {
                          setIsAppointmentsOn(event.target.checked);
                        }}
                        color="primary"
                        name="callRecording"
                        inputProps={{
                          "aria-label": "primary checkbox",
                        }}
                      />
                    }
                    label="Appointments"
                    labelPlacement="start"
                  />
                </Grid>

                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={(event: any) => {
                          if (event?.target?.checked!) {
                            setStatus(2);
                          } else {
                            setStatus(1);
                          }
                        }}
                        color="primary"
                        name="status"
                        inputProps={{
                          "aria-label": "primary checkbox",
                        }}
                      />
                    }
                    label="Status"
                    labelPlacement="start"
                  />
                </Grid>

                <Grid item md={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        disabled
                        onChange={(event: any) => {
                          setIsForwardToPhoneRequired(event?.target?.checked!);
                        }}
                        color="primary"
                        name="isForwardToPhoneRequired"
                        inputProps={{
                          "aria-label": "primary checkbox",
                        }}
                      />
                    }
                    label="Forward To Phone"
                    labelPlacement="start"
                  />
                </Grid>
                <Grid item sm={12} md={6}>

                  <Grid container className={isBotInterectionsOn ? classes.chakBoxGroup : classes.chakBoxGroupEmpty}>


                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            onChange={(event: any) => {
                              setIsBotInterectionsOn(event.target.checked);
                            }}
                            color="primary"
                            name="isBotInterectionsOn"
                            inputProps={{
                              "aria-label": "primary checkbox",
                            }}
                          />
                        }
                        label="Bot Q&A"
                        labelPlacement="start"
                      />
                    </Grid>

                    {isBotInterectionsOn && <Grid item xs={12}>
                      <Typography className={classes.chakBoxGroupTitle}>Channels:</Typography>
                    </Grid>}

                    {isBotInterectionsOn && <Grid item xs={12} md={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            onChange={(event: any) => {
                              setIsWhatsappRequired(event.target.checked);
                            }}
                            color="primary"
                            name="isBotInterectionsOn"
                            inputProps={{
                              "aria-label": "primary checkbox",
                            }}
                          />
                        }
                        label="Whatsapp"
                        labelPlacement="start"
                      />
                    </Grid>}
                    {isBotInterectionsOn && <Grid item xs={12} md={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            onChange={(event: any) => {
                              setIsChannelSwitchingOn(event.target.checked);
                            }}
                            color="primary"
                            name="isBotInterectionsOn"
                            inputProps={{
                              "aria-label": "primary checkbox",
                            }}
                          />
                        }
                        label="Channel Switching"
                        labelPlacement="start"
                      />
                    </Grid>}
                  </Grid>

                </Grid >

                <Grid item sm={12} md={6}>

                  <Grid container className={isAgentHandOverRequired ? classes.chakBoxGroup : classes.chakBoxGroupEmpty}>

                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            onChange={(event: any) => {
                              setIsAgentHandOverRequired(event.target.checked);
                            }}
                            color="primary"
                            name="isAgentHandOverRequired"
                            inputProps={{
                              "aria-label": "primary checkbox",
                            }}
                          />
                        }
                        label="Agent Handover"
                        labelPlacement="start"
                      />
                    </Grid>

                    {isAgentHandOverRequired && <Grid item xs={12}>
                      <Typography className={classes.chakBoxGroupTitle}>Conference:</Typography>
                    </Grid>}

                    {isAgentHandOverRequired && <Grid item xs={12} md={6}>
                      <FormControlLabel
                        control={
                          <Switch
                            onChange={(event: any) => {
                              setIsWebRTCVideoRequired(event.target.checked);
                            }}
                            color="primary"
                            name="isWebRTCVideoRequired"
                            inputProps={{
                              "aria-label": "primary checkbox",
                            }}
                          />
                        }
                        label="Video Enabled"
                        labelPlacement="start"
                      />
                    </Grid>}

                    {isAgentHandOverRequired && <Grid item xs={12} md={6}>
                      <FormControlLabel
                        control={
                          <Switch
                            onChange={(event: any) => {
                              setIsScreenSharingRequired(event.target.checked);
                            }}
                            color="primary"
                            name="isScreenSharingRequired"
                            inputProps={{
                              "aria-label": "primary checkbox",
                            }}
                          />
                        }
                        label="Screen Sharing"
                        labelPlacement="start"
                      />
                    </Grid>}

                    {isAgentHandOverRequired && <Grid item xs={12} md={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            onChange={(event: any) => {
                              setCallRecording(event.target.checked);
                            }}
                            color="primary"
                            name="callRecording"
                            inputProps={{
                              "aria-label": "primary checkbox",
                            }}
                          />
                        }
                        label="Call Recording"
                        labelPlacement="start"
                      />
                    </Grid>}

                  </Grid>
                </Grid>
                {process.env.REACT_APP_IS_RAISE_TICKET_FEATURE_ON == "active" && <Grid item xs={12} md={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={(event: any) => {
                          setIsRaiseTicketRequired(event.target.checked);
                        }}
                        color="primary"
                        name="isRaiseTicketRequired"
                        inputProps={{
                          "aria-label": "primary checkbox",
                        }}
                      />
                    }
                    label="Raise a Ticket"
                    labelPlacement="start"
                  />
                </Grid>}


              </Grid>
              {isForwardToPhoneRequired && (
                <>
                  <FormInput
                    control={control}
                    type="text"
                    name="forwardNumber"
                    label="Forwarded Number"
                    helperText="Calls will be forwarded to this number, charges apply"
                  />
                  <br />
                  <br />
                  <FormInput
                    control={control}
                    type="text"
                    name="forwardMessage"
                    label="Forwarded Message"
                    helperText="Message to be displayed to customer on forwaded call. Ex You're call is being forwaded to our IVR"
                  />
                  <br />
                </>
              )}

              {error ? (
                <FormFeedback className={classes.feedback} error>
                  {error.message}
                </FormFeedback>
              ) : null}
            </CardContent>
            <Divider />
            <CardActions>
              <div className={classes.buttonWrapper}>
                <FormButton
                  className={classes.button}
                  type="submit"
                  color="secondary"
                  variant="contained"
                  fullWidth
                >
                  Create
                </FormButton>
                {loading && (
                  <CircularProgress className={classes.buttonProgress} />
                )}
              </div>
            </CardActions>
          </form>
        </Card>
      </Container>
    </Page>
  );
}
