import React from "react";
import { TableCell, TableRow } from "@mui/material";
import { useHistory } from "react-router-dom";
import { format } from "ts-date/esm/locale/en";

interface Ticket {
  id: string;
  reporterId: string | null;
  reporterType: string;
  location: string;
  subject: string;
  company: string;
  description: string;
  priority: string;
  code: string | null;
  customer: {
    firstName: string;
    lastName: string;
    email: string;
  };
  createdAt: Date,
}

interface TicketItemProps {
  ticket: Ticket;
}

const TicketItem: React.FC<TicketItemProps> = ({ ticket }) => {
  const { id, location, subject, company, description, priority, code, customer, createdAt } = ticket;
  const history = useHistory();

  return (
    <TableRow style={{ cursor: 'pointer' }} hover key={id} onClick={() => history.push(`/tickets/edit/${id}`)}>
      <TableCell>
        {`${customer?.firstName || ""} ${customer?.lastName || ""}`}
        <br />
        {customer?.email}
      </TableCell>
      <TableCell>{location}</TableCell>
      <TableCell>{subject}</TableCell>
      <TableCell>{company}</TableCell>
      <TableCell>{description}</TableCell>
      <TableCell>{priority}</TableCell>
      <TableCell>{code}</TableCell>
      <TableCell>{format(new Date(createdAt!), "DD MMM, YYYY hh:mm a")}</TableCell>
    </TableRow>
  );
};

export default TicketItem;
