
import { AgentSessionActionTypes, UPDATE_SESSION } from '../actions/AgentSessionActions';



interface AgentSessionState {
    isCallOn: boolean;
    agentStatus: "online" | "offline" | "busy";
}

const initialState: AgentSessionState = {
    isCallOn: false,
    agentStatus: "online"
}


export const AgentSessionReducer = (state = initialState, action: AgentSessionActionTypes): AgentSessionState => {
    switch (action.type) {
        case UPDATE_SESSION: {
            return {
                isCallOn: action.payload.isCallOn,
                agentStatus: action.payload.agentStatus
            }
        }
        default:
            return state;
    }
}

export default AgentSessionReducer;