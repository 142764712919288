import { gql } from "@apollo/client";

export const ADD_ON_ADMIN_REFETCH = gql`
   query ListAddOn {
    listAddOn {
      id
      name
      description
      allowanceType
      allowanceUnits
      price
      active
    }
  }
`;