import { Route } from "react-router-dom";
import SuperAdminLayout from "../components/layout/super_admin";
import Dashboard from "../domain/super_admin/dashboard/Dashboard";
import List from "../domain/super_admin/businesses/list/List";
import OnBoard from "../domain/super_admin/businesses/onboard/OnBoard";
import Edit from "../domain/super_admin/businesses/edit/Edit";
import CompanyUsersList from "components/user/list/List";//
import CompanyCallsList from "components/calls/list/List";
import BotSessionsList from "components/bot_sessions/list/List";
import AdminUserEdit from "components/user/edit/Edit";//
import AdminVistors from "../domain/super_admin/call_for_help/list/List";
import AdminPayments from "../domain/super_admin/payments/list/List";
import AdminAudit from "../domain/super_admin/audit/list/List";
import AdminAddUser from "components/user/add/Add";//
import ResetPassword from "components/user/reset_password/ResetPassword";
import AdminBotProfileView from "../domain/super_admin/call_for_help/view/View";
//new imports
import AdminAddOnList from "../domain/super_admin/add-on/list/AddonsList";//
import AdminAddOnEdit from "../domain/super_admin/add-on/edit/Edit";//
import AdminAddOnAdd from "../domain/super_admin/add-on/add/Add";//
//Tickets
import AdminTicketList from "../domain/super_admin/ticket/list/TicketsList";//
import CreateTicket from '../domain/super_admin/ticket/create_update/CreateTicket';
import EditTicket from '../domain/super_admin/ticket/create_update/EditTicket';
import TicketLists from "components/ticket/list/TicketsList";






function SuperAdminRoutes() {
  return (
    <>
      <SuperAdminLayout>
        <Route path="/admin/dashboard" component={Dashboard} />
        <Route exact path="/admin/businesses" component={List} />
        <Route path="/admin/businesses/onboard" component={OnBoard} />
        <Route path="/admin/businesses/edit/:id" component={Edit} />
        <Route exact path="/admin/user/:id" component={CompanyUsersList} />
        <Route exact path="/admin/users/edit/:id" component={AdminUserEdit} />
        <Route
          exact
          path="/admin/users/edit/:id/:mode"
          component={AdminUserEdit}
        />
        <Route exact path="/admin/users" component={CompanyUsersList} />
        <Route path="/admin/tickets" exact component={TicketLists} />
        <Route exact path="/admin/agent-sessions" component={CompanyCallsList} />
        <Route exact path="/admin/agent-sessions/:id" component={CompanyCallsList} />
        <Route path="/admin/bot-sessions" exact component={BotSessionsList} />
        <Route
          path="/admin/bot-sessions/:id"
          exact
          component={BotSessionsList}
        />
        <Route exact path="/admin/bot-profile" component={AdminVistors} />
        <Route exact path="/admin/payments" component={AdminPayments} />
        <Route exact path="/admin/payments/:id" component={AdminPayments} />
        <Route exact path="/admin/audit" component={AdminAudit} />
        <Route
          exact
          path="/admin/users/password/change/:id"
          component={ResetPassword}
        />
        <Route exact path="/admin/users/add" component={AdminAddUser} />
        <Route
          exact
          path="/admin/bot-profile/view/:id"
          component={AdminBotProfileView}
        />
        {/* New Routes */}
        <Route exact path="/admin/add-on/:id" component={AdminAddOnList} />
        <Route exact path="/admin/add-ons/edit/:id" component={AdminAddOnEdit} />
        <Route
          exact
          path="/admin/users/edit/:id/:mode"
          component={AdminAddOnEdit}
        />
        <Route exact path="/admin/add-on" component={AdminAddOnList} />
        <Route exact path="/admin/add-ons/add" component={AdminAddOnAdd} />
        {/* Tickets */}
        <Route exact path="/admin/ticket/:id" component={AdminTicketList} />
        <Route exact path="/admin/ticket" component={AdminTicketList} />
       
        <Route exact path="/admin/tickets/create" component={CreateTicket} />
        <Route exact path="/admin/tickets/edit/:id" component={EditTicket} />

      </SuperAdminLayout>
    </>
  );
}
export default SuperAdminRoutes;
