import { Redirect, Route, Switch } from "react-router-dom";
import Login from "../pages/login/Login";
import Logout from "../pages/logout/Logout";
import ProtectedRoute from "./ProtectedRoute";
import SuperAdminRoutes from "./SuperAdminRoutes";
import BusinessAgentRoutes from "./BusinessAgentRoutes";
import BusinessAdminRoutes from "./BusinessAdminRoutes";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";
import CustomerAppointmentConference from "components/conference/appointment/CustomerConference";
import SignUp from "pages/signup/SignUp";
import LinkMessage from "components/link_message/LinkMessage";
import AccountActivation from "pages/signup/AccountActivation";
import AgentAppointmentConference from "components/conference/appointment/AngentConference";
import CommonSnackBar from "components/common_snackbar/CommonSnackBar";
import PaymentMethodSuccess from "domain/business_admin/subscriptions/payment_method/PaymentMethodSuccess";
import PaymentMethodCancel from "domain/business_admin/subscriptions/payment_method/PaymentMethodCancel";
import ForgotPassword from "pages/forgot_password/ForgotPassword";
import NewPassword from "pages/forgot_password/NewPassword";
import PasswordChange from "pages/signup/PasswordChangeNew";
import AssetsDownloadPage from "components/s3_assets/AssetsDownloadPage";
export default function Routes(): JSX.Element {
  const { user } = useSelector((state: RootState) => state.auth);
  // http://localhost:3000/user/verify/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Impqd0B5b3BtYWlsLmNvbSIsInRpbWVzdGFtcCI6IjIwMjMtMDUtMDVUMDQ6MTg6MTguMDAwWiIsImV4cGlyYXRpb24iOiIyMDIzLTA1LTExVDE5OjQ5OjMzLjk1NVoiLCJob3N0IjoiaHR0cHM6Ly9kZXZkb2NrZXIxLWFwcC5ndWlkaXp5LmNvbSIsImlhdCI6MTY4MzY2MTc3M30.9siFPdJ11SWWxEDHRW3w5gGdzHqO-BrMCyH60AFfByI
  return (
    <div>
      <CommonSnackBar>
        <Switch>
          <Redirect exact from="/" to="/login" />
          <Route path="/login" component={Login} />
          <Route path="/logout" component={Logout} />
          <Route path="/forgot_password" component={ForgotPassword} />
          <Route exact path="/signup/:plan(basic|plus|premium|enterprise)" component={SignUp} />
          <Route exact path="/signup" component={SignUp} />
          <Route exact path="/success" component={PaymentMethodSuccess} />
          <Route exact path="/cancel" component={PaymentMethodCancel} />
          <Route
            exact
            path="/verifySignup/:token"
            component={AccountActivation}
          />
          <Route
            exact
            path="/passwordChange/:token"
            component={PasswordChange}
          />
          <Route
            exact
            path="/user/verify/:token"
            component={NewPassword}
          />
          <Route
            exact
            path="/ForcePassword"
            component={NewPassword}
          />
          <Route exact path="/account/verified" component={LinkMessage} />
          <Route
            path="/ticketsassets/:key(.+)"
            component={AssetsDownloadPage}
          />
          <Route
            path="/meet/:appId/:appointmentId/:token"
            component={CustomerAppointmentConference}
          />
          <Route
            path="/meeting/:appId/:appointmentId/:token/:phoneNumber"
            component={AgentAppointmentConference}
          />
          <Route
            path="/meeting/:appId/:appointmentId/:token"
            component={AgentAppointmentConference}
          />
          {user && user?.role! === "admin" ? (
            <ProtectedRoute path="/admin/" component={SuperAdminRoutes} />
          ) : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            user && user.role === "owner" ? (
              <ProtectedRoute path="/" component={BusinessAdminRoutes} />
            ) : user && (user.role === "manager" || user.role === "agent") ? (
              <ProtectedRoute path="/" component={BusinessAgentRoutes} />
            ) : (
              <Redirect to="/login" />
            )}
        </Switch>
      </CommonSnackBar>
    </div>
  );
}
