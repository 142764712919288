import { gql } from "@apollo/client";

export const EDIT_ADMIN_COMPANY = gql`
    mutation EditAdminCompanyMutation($input: UpdateCompanyInput!) {
        updateCompany(input: $input) {
            companyEdge {
                node {
                    id
                }
            }
        }
    }
`;
