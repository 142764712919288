import { gql } from "@apollo/client";

export const LIST_BUSINESS_ADMIN_MANAGERS = gql`
    fragment ListAccount_managers_1G22ux on Query {
        users(role: $role) {
            edges {
                node {
                    id
                    firstName
                    lastName
                    email
                    createdAt
                    updatedAt
                    phone
                    role
                    status
                    __typename
                }
            }
            totalCount
        }
    }
`;
