import Page from "../../../../components/page/Page";
import CircularProgress from "../../../../components/facebook_progress/FacebookProgress";
import { useStyles } from "./style";
import { useQuery } from "@apollo/client";
import EditForm from "./EditForm";
import { ADD_ON_ADMIN_REFETCH } from "../../../../adapters/queries/AddOnAdminRefetch";
import { Container, Grid, Typography } from "@mui/material";
import { AddOn } from "../../../../../src/utils/interfaces"; // Import AddOn interface

export default function EditAddon(props: any) {
  const classes = useStyles();
  const { id } = props.match.params;
  const { data, loading } = useQuery(ADD_ON_ADMIN_REFETCH);

  const addon: AddOn | undefined = data?.listAddOn.find((addon: AddOn) => addon.id === id); 

  if (loading) {
    return <CircularProgress size={15} />;
  }

  return (
    <Page className={classes.root} title="Edit Addon">
      <Container className={classes.container}>
        <Typography variant="h3" sx={{ paddingY : "1rem" }} className={classes.title}>Addon Details</Typography>
        <Grid container spacing={5} justifyContent="center">
          <Grid item md={12} xs={12}>
            {addon && <EditForm addon={addon} />} 
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
export{}