import { gql } from "@apollo/client";
import { EDIT_ADMIN_COMPANY_FRAGMENT } from "../fragments/EditAdminCompany";

export const COMPANY_ADMIN_EDIT = gql`
    ${EDIT_ADMIN_COMPANY_FRAGMENT}
    query CompanyAdminEditQuery($id: ID!) {
        company(id: $id) {
            ...EditAdminCompany_data
        }
    }
`;
