import { gql } from "@apollo/client";
import { LIST_COMPANIES } from "../fragments/ListCompanies";

export const COMPANY_REFETCH = gql`
    ${LIST_COMPANIES}
    query CompanyFragmentRefetchQuery(
        $count: Int 
        $reversecount: Int
        $startcursor: String
        $endcursor: String
    ) {
        ...List_companys_1G22uz
    }
`;
