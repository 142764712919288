import { useContext, useEffect } from "react";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import FormButton from "@material-ui/core/Button";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { useStyles } from "./style";
import FormInput from "../../form/form_input/FormInput";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Popover from "@material-ui/core/Popover";
import { useQuery } from "@apollo/client";
import { USER_BUSINESS_ADMIN_REFETCH } from "../../../adapters/queries/UserBusinessRefetch";
import { MenuItem } from "@material-ui/core";
import { Edge } from "utils/interfaces";
import { SnackbarContext } from "components/common_snackbar/CommonSnackBar";
import { ASSIGNED_MODAL_BUSINESS_ADMIN_CALLBACK } from "adapters/mutations/AssignedModalBusinessAdminCallback";

const schema = yup.object().shape({
  assigneeId: yup.string().required(),
});

export default function AssignModal(props: any) {
  const classes = useStyles();
  const history = useHistory();
  const { setSnack } = useContext(SnackbarContext);

  const { data } = useQuery(USER_BUSINESS_ADMIN_REFETCH);

  const users = data?.users?.edges;
  console.log(users);

  const [mutate, { loading }] = useMutation(
    ASSIGNED_MODAL_BUSINESS_ADMIN_CALLBACK,
    {
      onCompleted: () => {
        history.push("/callbacks");
        props.getAssignedList()
      },
      onError: (error: any) => {
        console.log(error?.message);
        console.log(error);
        setSnack({ message: "Assign failed", severity: 'error', open: true });

      },
    }
  );

  useEffect(() => {
    document.title = "Assign";
  }, []);

  const handleClose = () => {
    props?.onClose(null);
  };

  const open = Boolean(props?.el!);
  const id = open ? "simple-popover" : undefined;
  const callback = props?.callback!;

  console.log("Props", props);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: any) => {
    mutate({
      variables: {
        input: {
          id: callback?.id!,
          assigneeId: data?.assigneeId,
        },
      },
    });
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={props?.el!}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      style={{ maxWidth: "500px" }}
    >
      <Card className={classes.card}>
        <CardHeader title="Assign To  " />
        <Divider />

        <form onSubmit={handleSubmit(onSubmit)}>
          <CardContent>
            <FormInput
              control={control}
              type="text"
              name="assigneeId"
              label="Assignee"
              error={errors && errors.name}
              select="select"
            >
              {users
                ?.filter(
                  (filter: Edge) =>
                    filter?.node?.role === "agent" ||
                    filter?.node?.role === "owner" ||
                    filter?.node?.role === "manager"
                )
                .map((user: Edge) => (
                  <MenuItem key={user?.node?.id} value={user?.node?.id}>
                    {user?.node?.firstName} {user?.node?.lastName} ({user?.node?.role === "owner" ? "Me" : user?.node?.status == 2 ? "Active" : "Inactive"})
                  </MenuItem>
                ))}
            </FormInput>
          </CardContent>
          <Divider />
          <CardActions>
            <FormButton
              className={classes.button}
              disabled={loading}
              type="submit"
              size="large"
              color="secondary"
              variant="contained"
              fullWidth
            >
              Assign
            </FormButton>
          </CardActions>
        </form>
      </Card>
    </Popover>
  );
}
