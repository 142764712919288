import { gql } from "@apollo/client";
import { VIEW_ADMIN_BOT_PROFILE } from "../fragments/ViewAdminBotProfile";

export const BOTPROFILE_ADMIN_VIEW = gql`
    ${VIEW_ADMIN_BOT_PROFILE}
    query BotProfileAdminViewQuery($id: String!) {
        botprofile(id: $id) {
            ...ViewAdminBotProfile
        }
    }
`;
