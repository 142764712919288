import { gql } from "@apollo/client";
import { LIST_ADMIN_USERS } from "../fragments/ListAdminUsers";

export const USER_ADMIN_REFETCH = gql`
    ${LIST_ADMIN_USERS}
    query UserAdminFragmentRefetchQuery(
        $companyId: String
        $count: Int = 10
        $cursor: String
    ) {
        ...ListAdmin_users_3J7gr0
    }
`;
