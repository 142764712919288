import { Theme, createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            minHeight: "100%",
            paddingBottom: theme.spacing(3)
        },
        container: {
            [theme.breakpoints.up("md")]: {
                fontFamily: "Poppins"
            }
        },
        text: {
            fontFamily: "Poppins"
        }
    })
);
