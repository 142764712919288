import {
  CardActions,
  CardContent,
  CircularProgress,
  Divider, Button as FormButton, IconButton, Stack
} from "@mui/material";

import FormFeedback from "../../../../components/form_feedback/FormFeedback";
import FormInput from "../../../../components/form/form_input/FormInput";
import { useStyles } from "./style";
import { useFieldArray, useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { USER_BUSINESS_ADMIN_REFETCH } from "adapters/queries/UserBusinessRefetch";
import { useDispatch } from "react-redux";
import { useContext } from "react";
import { SnackbarContext } from "components/common_snackbar/CommonSnackBar";
import { updateUserCompany } from "redux/actions/AuthActions";
import CloseIcon from '@mui/icons-material/Close';
import * as yup from "yup";
import { PlusOneOutlined } from "@mui/icons-material";
import { yupResolver } from "@hookform/resolvers/yup";
import { EDIT_BUSINESS_EMAIL_LIST } from "adapters/mutations/EditBusinessEmailList";

const schema = yup.object().shape({
  emails: yup.array().of(yup.string().email().required()),
});



export default function EditNotificationEmailsForm({ company }: any) {
  const classes = useStyles();
  const dispatch = useDispatch()
  const { setSnack } = useContext(SnackbarContext);
  const defaultValues = {
    emails: company?.contactEmailList ? JSON.parse(company?.contactEmailList) : [''],
  };



  const [mutate, { loading, error }] = useMutation(
    EDIT_BUSINESS_EMAIL_LIST,
    {
      onCompleted: (res) => {
        dispatch(updateUserCompany(res.updateCustomerEmailList.companyEdge.node))
        setSnack({ message: "Record Updated Successfully", severity: 'success', open: true });
      },
      onError: () => {
        setSnack({ message: "Editing company failed", severity: 'error', open: true });
      },
    }
  );

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema)
  });

  const onSubmit = ({ emails }: { emails: [string] }) => {

    mutate({
      refetchQueries: [{ query: USER_BUSINESS_ADMIN_REFETCH }],
      variables: {
        input: {
          id: company?.id,
          subdomain: company?.subdomain,
          contactEmailList: JSON.stringify(emails)
        },
      },
    });
  };

  //to handle the multiple email in form
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    //@ts-ignore
    name: 'emails', // unique name for your Field Array,
  });

  const addEmail = () => {
    append("")
  }

  const removeEmail = (index: number) => {
    if (fields.length != 1) {
      remove(index)
    }
  }


  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <CardContent>

          {fields.map((item: any, index: number) =>

            <Stack flexDirection={'column'} key={item.id}>
              <Stack display={'flex'} flexDirection={'row'} >

                <FormInput
                  // className={classes.emailField}
                  defaultValue={defaultValues.emails[index]}
                  control={control}
                  type="text"
                  name={`emails[${index}]`}
                  label={`Email ${index + 1}`}
                  vertical={true}
                  error={errors && errors.emails && errors.emails[index]}
                />

                {fields.length > 1 && <IconButton color='primary' onClick={() => removeEmail(index)}>
                  <CloseIcon />
                </IconButton>}

              </Stack>
              <br />
            </Stack>)
          }
          {error ? (
            <FormFeedback className={classes.feedback} error>
              {error.message}
            </FormFeedback>
          ) : null}
        </CardContent>
        <Divider />
        <CardActions className={classes.buttonContainerEmailList}>
          <div className={classes.buttonWrapper}>
            <FormButton variant="contained" startIcon={<PlusOneOutlined />} onClick={() => {
              addEmail()
            }}>
              Add
            </FormButton>
          </div>
          <div className={classes.buttonWrapper}>
            <FormButton
              className={classes.button}
              type="submit"
              color="secondary"
              variant="contained"
              fullWidth
            >
              Update Record
            </FormButton>
            {loading && <CircularProgress className={classes.buttonProgress} />}
          </div>
        </CardActions>
      </form>
    </div>
  );
}
