import { gql } from "@apollo/client";

export const CREATE_ADDON = gql`
  mutation CreateAddOn($addOn: AddOnCreateInput!) {
  createAddOn(addOn: $addOn) {
    active
    allowanceType
    allowanceUnits
    createdAt
    description
    id
    name
    price
    priceId
  }
}
`;