import React, { useState, useMemo } from "react";
import { Card, Container, LinearProgress, Table, TableBody, TablePagination } from "@mui/material";
import Page from "../../../../components/page/Page";
import { useStyles } from "./style";
import TicketToolbar from "../toolbar/Toolbar";
import { useQuery } from "@apollo/client";
import TicketItem from "../list_item/TicketItem";
import { TICKET_ADMIN_REFETCH } from "../../../../adapters/queries/TicketAdminRefetch";
import { Hidden, TableCell, TableHead, TableRow } from "@mui/material";
import Searchbar, { SearchQuery } from "../searchbar/Searchbar";

export function isNotNull<T>(it: T): it is NonNullable<T> {
  return it != null;
}
export default function TicketList() {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5); 
  const [searchQuery, setSearchQuery] = useState<SearchQuery>({ searchText: "", searchBy: "company" });

  const { data, loading } = useQuery(TICKET_ADMIN_REFETCH, {
    variables: { search: searchQuery },
  });
  const tickets = useMemo(() => data?.listTickets || [], [data]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); 
  };

  const handleSearch = (query: SearchQuery) => {
    setSearchQuery(query);
    setPage(0);
  };

  return (
    <Page className={classes.root} title="Tickets">
      <Container maxWidth={"xl"}>
        <TicketToolbar />
        <Searchbar onSearch={handleSearch} />
        <Card sx={{ mt: 3 }}>
          {loading && <LinearProgress />}
          <Table>
            <Hidden smDown>
              <TableHead>
                <TableRow sx={{ borderRadius: "10px" }}>
                  <TableCell>Location</TableCell>
                  <TableCell>Subject</TableCell>
                  <TableCell>Company</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Priority</TableCell>
                  <TableCell>Code</TableCell>
                </TableRow>
              </TableHead>
            </Hidden>
            <TableBody>
              {tickets.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((ticket: any) => (
                <TicketItem key={ticket.id} ticket={ticket} />
              ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={tickets.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage} 
            rowsPerPageOptions={[5, 10, 25]}
          />
        </Card>
      </Container>
    </Page>
  );
}
