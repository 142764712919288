import { gql } from "@apollo/client";

export const LIST_ADMIN_PAYMENTS = gql`
    fragment ListAdmin_payments_3J7gr0 on Query {
        payments(first: $count, last : $reversecount , after: $endcursor, before: $startcursor, companyId: $companyId) {
            edges {
                node {
                    id
                    createdAt
                    planId
                    status
                    amount
                    company {
                        id
                        name
                    }
                    __typename
                }
                cursor
            }
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
            totalCount
        }
    }
`;
