import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { RootState } from "redux/reducers/rootReducer";

interface Props {
  exact?: any;
  path: string;
  component: any;
}

export default function ProtectedRoute({
  component: Component,
  ...rest
}: Props) {
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);

  return (
    <Route
      {...rest}
      render={(routeProps: any) => {
        if (isAuthenticated) return <Component {...routeProps} />;
        else return <Redirect to="/login" />;
      }}
    />
  );
}
