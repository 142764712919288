import { Grid, LinearProgress } from "@mui/material";
import Page from "components/page/Page";
import { DragEvent, useCallback, useEffect, useRef, useState } from "react";
import ReactFlow, {
  useNodesState,
  useEdgesState,
  addEdge,
  Background,
  MiniMap,
  Controls,
  BackgroundVariant,
  ReactFlowProvider,
  ReactFlowInstance,
  Node,
  Edge,
  NodeChange,
  EdgeChange,
  // useReactFlow,
} from "reactflow";
import Sidebar from "./sidebar";
import "reactflow/dist/style.css";
import { useStyles } from "./style";
import { GET_BOT_FRAMEWORK } from "adapters/queries/GetBotFramework";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { UPDATE_BOT_FRAMEWORK } from "adapters/mutations/EditBotFramework";
import { SUBMIT_BOT_FRAMEWORK } from "adapters/queries/SubmitBotFramework";

const initialNodes: Node<any, string | undefined>[] = [];
const initialEdges: Edge<any>[] = [];

export default function WelcomeMessage(props: any) {
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  // const { getNode } = useReactFlow();
  const [reactFlowInstance, setReactFlowInstance] =
    useState<ReactFlowInstance<any, any>>();
  const reactFlowWrapper = useRef<HTMLHeadingElement>(null);
  let { botProfileId, companyId } = props.match.params;

  const {
    data: botFramework,
    error: errorBotFramework,
    loading: loadingBotFramework,
  } = useQuery(GET_BOT_FRAMEWORK, {
    variables: {
      botFrameworkByBotProfileIdId: botProfileId,
    },
  });

  const [editFaq, { loading: editFAQLoading, error: editFAQError }] =
    useMutation(UPDATE_BOT_FRAMEWORK, {
      onCompleted: (res) => {
        console.log(res);
      },
      onError: () => {
        console.log(editFAQError);
      },
    });

  const [submitBotFramework, { loading: loadingSubmit }] = useLazyQuery(
    SUBMIT_BOT_FRAMEWORK,
    {
      onCompleted: (data) => {
        console.log(data);
      },
    }
  );

  useEffect(() => {
    if (botFramework?.botFrameworkByBotProfileId) {
      const edges = JSON.parse(botFramework.botFrameworkByBotProfileId.edges);
      const mainMenuEdges = edges.filter((mEdge: any) => mEdge.source == "2");
      setEdges(JSON.parse(botFramework.botFrameworkByBotProfileId.edges));
      const nodes = JSON.parse(botFramework.botFrameworkByBotProfileId.nodes);
      const updatedNodes = nodes.map((node: any) =>
        mainMenuEdges.find((mmEdge: any) => mmEdge.target == node.id)
          ? {
            ...node,
            style: {
              backgroundColor: "#6366F1",
              botderColor: "#6366F1",
              color: "white",
            },
          }
          : node
      );
      console.log(nodes, mainMenuEdges);

      setNodes(updatedNodes);
    }
  }, [botFramework]);

  const classes = useStyles();

  const onConnect = useCallback(
    (params: any) => setEdges((eds) => addEdge(params, eds)),
    [setEdges]
  );

  const onDragOver = useCallback((event: DragEvent) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onDrop = useCallback(
    (event: DragEvent<HTMLDivElement>) => {
      event.preventDefault();

      if (
        reactFlowWrapper != null &&
        reactFlowWrapper.current != null &&
        reactFlowInstance
      ) {
        const reactFlowBounds =
          reactFlowWrapper.current.getBoundingClientRect();
        const type = event.dataTransfer.getData("application/reactflow");
        const faqId = event.dataTransfer.getData("application/reactflow/faqId");
        const displayName = event.dataTransfer.getData("application/reactflow/displayName");

        // check if the dropped element is valid
        if (typeof type === "undefined" || !type) {
          return;
        }

        const position = reactFlowInstance.project({
          x: event.clientX - reactFlowBounds.left,
          y: event.clientY - reactFlowBounds.top,
        });
        const newNode = {
          id: faqId,
          type,
          position,
          data: { label: `${type}`, displayName },
        };

        setNodes((nds) => nds.concat(newNode));
      }
    },
    [reactFlowInstance]
  );

  const isMacOS = () => {
    const platform = window.navigator.platform;
    return ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'].includes(platform);
  };

  function handleNodesChange(changes: NodeChange[]) {
    const nextChanges = changes.reduce((acc, change) => {

      if (change.type === 'remove' && (change.id == '1' || change.id == '2')) {
        return acc;
      }

      return [...acc, change];
    }, [] as NodeChange[])

    onNodesChange(nextChanges);
  }

  function handleEdgesChange(changes: EdgeChange[]) {
    const nextChanges = changes.reduce((acc, change) => {
      if (change.type === 'remove' && (change.id.startsWith("e1-2"))) {
        return acc;
      }
      return [...acc, change];
    }, [] as EdgeChange[])
    onEdgesChange(nextChanges)

  }

  return (
    <Page className={classes.root} title="Bot Framework">
      {(loadingBotFramework || editFAQLoading || loadingSubmit) && (
        <LinearProgress />
      )}
      <ReactFlowProvider>
        <Grid container>
          <Grid item sm={9}>
            {errorBotFramework ? (
              <div>{errorBotFramework.message}</div>
            ) : (
              <div
                style={{ height: "80vh", width: "100%" }}
                ref={reactFlowWrapper}
              >
                <ReactFlow
                  nodes={nodes}
                  edges={edges}
                  onNodesChange={handleNodesChange}
                  onEdgesChange={handleEdgesChange}
                  onConnect={onConnect}
                  onDrop={onDrop}
                  onDragOver={onDragOver}
                  onInit={(data) => setReactFlowInstance(data)}
                  deleteKeyCode={isMacOS() ? ["Backspace"] : ["Delete"]}
                  fitView
                >
                  <Controls />
                  <MiniMap />
                  <Background
                    variant={BackgroundVariant.Dots}
                    gap={12}
                    size={1}
                  />
                </ReactFlow>
              </div>
            )}
          </Grid>
          <Grid item sm={3}>
            <Sidebar
              botProfileId={botProfileId}
              companyId={companyId}
              welcomeMessage={botFramework?.botFrameworkByBotProfileId?.welcomeMessage}
              fallback={{
                fallbackMessage:
                  botFramework?.botFrameworkByBotProfileId?.fallbackMessage,
                fallbackOptions:
                  botFramework?.botFrameworkByBotProfileId?.fallbackOptions,
              }}
              frmeworkId={botFramework?.botFrameworkByBotProfileId?.id}
              onSave={() => {
                editFaq({
                  variables: {
                    input: {
                      botProfileId: botProfileId,
                      companyId: companyId,
                      edges: JSON.stringify(edges),
                      id: botFramework.botFrameworkByBotProfileId.id,
                      nodes: JSON.stringify(nodes),
                    },
                  },
                });
              }}
              onSubmit={() => {
                submitBotFramework({
                  variables: {
                    input: { id: botProfileId },
                  },
                });
              }}
            />
          </Grid>
          {/* <Stack style={{
                        backgroundColor: 'white',
                        position: 'absolute',
                        zIndex: 1000,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        paddingLeft: 20,
                        paddingRight: 20,
                        marginLeft: 15
                    }}>
                        <div style={{
                            height: 20, width: 20, backgroundColor: '#6366F1',
                            borderRadius: 3, marginRight: 15
                        }} />
                        <Typography>Main Menu</Typography>
                    </Stack> */}
        </Grid>
      </ReactFlowProvider>
    </Page>
  );
}
