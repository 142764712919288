//@ts-ignore
import { useContext, useEffect, useMemo, useState } from "react";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormButton from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import { useMutation, useQuery } from "@apollo/client";
import Page from "../../page/Page";
import CircularProgress from "../../facebook_progress/FacebookProgress";
import MenuItem from "@material-ui/core/MenuItem";
import { useHistory } from "react-router-dom";
import { useStyles } from "./style";
import FormInput from "../../form/form_input/FormInput";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FormFeedback from "../../form_feedback/FormFeedback";
import { USER_ADMIN_REFETCH } from "../../../adapters/queries/UserAdminRefetch";
import { ADD_BUSINESS_ADMIN_USER } from "adapters/mutations/AddBusinessAdminUser";
import PhoneInput from "components/form/phone_input/PhoneInput"
import { SnackbarContext } from "components/common_snackbar/CommonSnackBar";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";
import { MANAGER_BUSINESS_ADMIN_REFETCH } from "adapters/queries/ManagersBusinessRefetch";

const roles = [
  // {
  //   value: "owner",
  //   label: "Admin",
  // },
  {
    value: "manager",
    label: "Manager",
  },
  {
    value: "agent",
    label: "Executive",
  },
];

interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  role: string;
  status: number;
  companyId: string;
  password: string;
  managerId: string;
}

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const schema = yup.object().shape({
  firstName: yup
    .string()
    .required("first name is Required")
    .matches(
      /^(?=.*[A-Za-z])[A-Za-z0-9]+$/,
      'first name should contain Alpha Numeric characters'
    ),
  lastName: yup
    .string()
    .required("last name is Required")
    .matches(
      /^(?=.*[A-Za-z])[A-Za-z0-9]+$/,
      'last name should contain Alpha Numeric characters'
    ),
  email: yup.string()
    .required()
    .matches(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      'email is not valid'
      ),
  password: yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters')
    .max(15, 'Password must be at most 15 characters')
    .matches(/[A-Za-z]/, 'Password must contain at least one letter')
    .matches(/[0-9]/, 'Password must contain at least one number')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter'),
  phone:  yup.string().required().matches(phoneRegExp, 'phone number is not valid'),
  role: yup.string().required(),
});

export function isNotNull<T>(it: T): it is NonNullable<T> {
  return it != null;
}

export default function AddUser() {
  const classes = useStyles();
  const history = useHistory();
  const [status, setStatus] = useState(1);
  const { setSnack } = useContext(SnackbarContext);
  const { user: cUser } = useSelector((state: RootState) => state.auth);
  const [showManagerDropdown, setShowManagerDropdown] = useState(false);

  const [mutate, { loading, error }] = useMutation(ADD_BUSINESS_ADMIN_USER, {
    onCompleted: () => {
      setSnack({ message: "User added successfully", severity: 'success', open: true });
      history.push(cUser?.role == 'admin' ? "/admin/users" : "/users");
    },
    onError: () => {
      console.log(error);
      setSnack({ message: "Create user failed", severity: 'error', open: true });
    },
  });

  const { data, refetch } = useQuery(MANAGER_BUSINESS_ADMIN_REFETCH, {
    variables: {
      role: 'manager'
    },
  });

  useEffect(() => {
    refetch({ role: 'manager' })
  }, [])


  const { users } = data || {};
  const list = useMemo(() => {
    return users && users.edges
      ? users.edges
        .filter(isNotNull)
        .map((edge: any) => edge.node)
        .filter(isNotNull)
      : [];
  }, [users])

  useEffect(() => {
    document.title = "Add User";
  }, []);

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch
  } = useForm({
    resolver: yupResolver(schema),
  });

  const roleChange: Array<string> = watch(['role'])

  useEffect(() => {
    if (roleChange[0] == 'agent') {
      setShowManagerDropdown(true)
    } else {
      setShowManagerDropdown(false)
    }
  }, [roleChange])

  const onSubmit = ({
    firstName,
    lastName,
    email,
    phone,
    role,
    password,
    managerId
  }: User) => {
    mutate({
      refetchQueries: [{ query: USER_ADMIN_REFETCH }],
      variables: {
        input: {
          firstName: firstName,
          lastName: lastName,
          email: email,
          status: status,
          role: role,
          phone: phone,
          password: password,
          managerId,
        },
      },
    });
  };

  return (
    <Page className={classes.root} title="Add User">
      <Container maxWidth={"xs"}>
        <Card>
          <CardHeader title="Add User" />
          <Divider />
          {/* @ts-ignore */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <CardContent>
              <FormInput
                control={control}
                type="text"
                name="firstName"
                label="First Name*"
                error={errors && errors.firstName}
              />
              <br />
              <br />
              <FormInput
                control={control}
                type="text"
                name="lastName"
                label="Last Name*"
                error={errors && errors.lastName}
              />
              <br />
              <br />
              <FormInput
                control={control}
                type="email"
                name="email"
                label="Email*"
                error={errors && errors.email}
              />
              <br />
              <br />
              <FormInput
                control={control}
                type="password"
                name="password"
                label="Password*"
                autoComplete="new-password"
                error={errors && errors.password}
              />
              <br />
              <br />
              <PhoneInput
                control={control}
                name="phone"
                error={errors && errors.phone}
              />
              <br />
              <FormInput
                control={control}
                type="text"
                name="role"
                label="Role*"
                select="select"
                error={errors && errors.role}
              >
                {roles.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </FormInput>
              <br />
              <br />

              {showManagerDropdown && <FormInput
                control={control}
                type="text"
                name="managerId"
                label="Manager*"
                select="select"
              >
                {list.map((item: User) => {
                  return {
                    value: item.id,
                    label: item.firstName
                  }
                }).map((option: { value: string, label: string }) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </FormInput>}
              <br />
              <br />
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={(event: any) => {
                          if (event?.target?.checked!) {
                            setStatus(2);
                          } else {
                            setStatus(1);
                          }
                        }}
                        color="primary"
                        name="status"
                        inputProps={{
                          "aria-label": "primary checkbox",
                        }}
                      />
                    }
                    label="Status"
                    labelPlacement="start"
                  />
                </Grid>
              </Grid>
              {error ? (
                <FormFeedback className={classes.feedback} error>
                  {error.message}
                </FormFeedback>
              ) : null}
            </CardContent>
            <Divider />
            <CardActions>
              <div className={classes.buttonWrapper}>
                <FormButton
                  className={classes.button}
                  type="submit"
                  color="secondary"
                  variant="contained"
                  fullWidth
                >
                  Create
                </FormButton>
                {loading && (
                  <CircularProgress className={classes.buttonProgress} />
                )}
              </div>
            </CardActions>
          </form>
        </Card>
      </Container>
    </Page>
  );
}
