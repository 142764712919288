import { useEffect } from "react";
import Page from "../../../../components/page/Page";
import CircularProgress from "../../../../components/facebook_progress/FacebookProgress";
import { useStyles } from "./style";
import { useQuery } from "@apollo/client";
import EditCRMsync from "./EditCRMsync";
import EditForm from "./EditCompanyForm";
import { COMPANY_BUSINESS_ADMIN_EDIT } from "adapters/queries/CompanyBusinessAdminEdit";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";
import { useLocation } from "react-router-dom";
import EditAddressForm from "./EditAddressForm";
import { Card, CardHeader, Container, Divider, Grid, } from "@mui/material";
import EditNotificationEmailsForm from "./EditNotificationEmailsForm";

export default function EditAccount() {
  const classes = useStyles();
  const { user } = useSelector((state: RootState) => state.auth);
  const { state } = useLocation();

  const stateValue: any = state;
  let id: any = user?.companyId;
  const { data, loading, refetch } = useQuery(COMPANY_BUSINESS_ADMIN_EDIT, {
    variables: { id },
    notifyOnNetworkStatusChange: true
  });
  const company = data?.company;


  useEffect(() => {
    document.title = "Edit Account";

  });

  useEffect(() => {
    if (stateValue?.state) {
      refetch();
      stateValue.state = false;
    }

  }, [stateValue, refetch]);


  if (loading) {
    return <CircularProgress size={15} />;
  }

  return (
    <Page title="Modify Company">
      <Container className={classes.container}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6} lg={4}>
            <Card className={classes.card}>
              <CardHeader
                title="Modify Business Details"
                subheader={`${company?.name!}`}

                className={classes.cardHeader}
                titleTypographyProps={{
                  className: classes.cardTitle,
                }}
                subheaderTypographyProps={{
                  className: classes.cardSubheader,
                }}
              />
              <Divider />

              <EditForm company={data?.company} />
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Card className={classes.card}>
              <CardHeader
                title="Modify Corporate Address "
                subheader={`${company?.name!}`}

                className={classes.cardHeader}
                titleTypographyProps={{
                  className: classes.cardTitle,
                }}
                subheaderTypographyProps={{
                  className: classes.cardSubheader,
                }}
              />
              <Divider />

              <EditAddressForm company={data?.company} />
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Card className={classes.card}>
              <CardHeader
                title="Notification Email List"
                subheader={`${company?.name!}`}

                className={classes.cardHeader}
                titleTypographyProps={{
                  className: classes.cardTitle,
                }}
                subheaderTypographyProps={{
                  className: classes.cardSubheader,
                }}
              />
              <Divider />

              <EditNotificationEmailsForm company={data?.company} />
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Card className={classes.card}>
              <CardHeader
                title="CRM sync details "
                subheader={`${company?.name!}`}

                className={classes.cardHeader}
                titleTypographyProps={{
                  className: classes.cardTitle,
                }}
                subheaderTypographyProps={{
                  className: classes.cardSubheader,
                }}
              />
              <Divider />

              <EditCRMsync />
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
