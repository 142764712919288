import {
  CardActions,
  CardContent,
  CircularProgress,
  Divider,
  InputAdornment,
  MenuItem,
} from "@material-ui/core";
import FormFeedback from "../../../../components/form_feedback/FormFeedback";
import FormInput from "../../../../components/form/form_input/FormInput";
import FormButton from "@material-ui/core/Button";
import { useStyles } from "./style";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { EDIT_ADMIN_COMPANY } from "../../../../adapters/mutations/EditAdminCompany";
import { COMPANY_REFETCH } from "../../../../adapters/queries/CompanyRefetch";
import { Company } from "utils/interfaces";
import { useContext } from "react";
import { SnackbarContext } from "components/common_snackbar/CommonSnackBar";

const types = [
  {
    value: "1",
    label: "General",
  },
  {
    value: "2",
    label: "Real Estate",
  },
  {
    value: "3",
    label: "Accounting",
  },
  {
    value: "4",
    label: "Law Firm",
  },
  {
    value: "5",
    label: "Health Care",
  },
  {
    value: "6",
    label: "Others",
  },
];

const schema = yup.object().shape({
  name: yup.string().required(),
});

export default function EditForm({ company }: { company: Company }) {
  const classes = useStyles();
  const history = useHistory();
  const { setSnack } = useContext(SnackbarContext);
  const defaultValues = {
    ...company,
  };

  const [mutate, { loading, error }] = useMutation(EDIT_ADMIN_COMPANY, {
    onCompleted: () => {
      history.push("/admin/businesses");
    },
    onError: () => {
      console.log(error);
      setSnack({ message: "Adding company failed", severity: 'error', open: true });
    },
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    // @ts-ignore
    defaultValues,
  });

  const onSubmit = ({ name, type, vat, subdomain }: Company) => {
    mutate({
      refetchQueries: [{ query: COMPANY_REFETCH }],
      variables: {
        input: {
          id: company?.id,
          name: name,
          type: type || "1",
          subdomain: `${subdomain}.hnapp.co`,
          vat: vat,
        },
      },
    });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <CardContent>
          <FormInput
            control={control}
            type="text"
            name="name"
            label="Business Name"
            defaultValue={company?.name}
            error={errors && errors.name}
          />
          <br />
          <br />
          <FormInput
            control={control}
            type="text"
            name="type"
            label="Business Type"
            defaultValue={company?.type}
            select="select"
          >
            {types.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </FormInput>
          <br />
          <br />
          <FormInput
            control={control}
            type="number"
            name="vat"
            label="VAT Number"
            defaultValue={company?.vat}
          />
          <br />
          <br />
          <FormInput
            control={control}
            type="text"
            name="subdomain"
            label="Subdomain"
            defaultValue={company?.subdomain}
            inputProps={{
              endAdornment: (
                <InputAdornment position="end">.hnapp.co</InputAdornment>
              ),
            }}
          />
          <br />
          <br />
          {error ? (
            <FormFeedback className={classes.feedback} error>
              {error.message}
            </FormFeedback>
          ) : null}
        </CardContent>
        <Divider />
        <CardActions>
          <div className={classes.buttonWrapper}>
            <FormButton
              className={classes.button}
              type="submit"
              color="secondary"
              variant="contained"
              fullWidth
            >
              Update Record
            </FormButton>
            {loading && <CircularProgress className={classes.buttonProgress} />}
          </div>
        </CardActions>
      </form>
    </div>
  );
}
