import { Theme, createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        container: {
            [theme.breakpoints.up("md")]: {
                width: "60%"
            }
        },
        form: {},
        card: {},
        cardHeader: {
            padding: theme.spacing(0.7, 2)
        },
        cardTitle: {
            fontSize: "1.25rem"
        },
        cardSubheader: {
            fontSize: "0.875rem"
        },
        feedback: {
            marginTop: theme.spacing(2),
            textTransform: "capitalize"
        },
        title: {
            fontSize: 14
        },
        error: {
            textTransform: "capitalize"
        },
        button: {
            color: "white"
        },
        buttonWrapper: {
            position: "relative"
        },
        buttonProgress: {
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: -9,
            marginLeft: -12
        },
        option: {
            fontSize: 15,
            "& > span": {
                marginRight: 10,
                fontSize: 18
            }
        }
    })
);
