import ViewCompany from "domain/business_admin/subscriptions/view/View";
import AddCallForHelpProfile from "domain/business_admin/call_for_help/add/Add";
import Appointments from "../components/appointments/list/List";
import Callbacks from "../components/callbacks/list/List";
import EventLog from "domain/business_admin/event_log/list/List";
import BusinessHours from "domain/business_admin/call_for_help/business_hours/BusinessHours";
import EditCallForHelpProfile from "domain/business_admin/call_for_help/edit/Edit";
import CallForHelpList from "domain/business_admin/call_for_help/list/List";
import PaymentList from "domain/business_admin/payments/list/List";
import ViewPayment from "domain/business_admin/payments/view/View";
import AddUser from "../components/user/add/Add";
import EditUser from "../components/user/edit/Edit";
import UserList from "../components/user/list/List";
import CustomerList from "../components/customers/list/List";
import ResetPassword from "../components/user/reset_password/ResetPassword";
import CallList from "components/calls/list/List";
import BotSessionsList from "components/bot_sessions/list/List";
import { Route } from "react-router-dom";
import BusinessAdminLayout from "../components/layout/business_admin";
import Dashboard from "../domain/business_admin/Dashboard";
import EditAccount from "domain/business_admin/company/edit/Edit";
import ChangeSubscription from "domain/business_admin/subscriptions/change_subscription/Subscriptions";
import Pay from "domain/business_admin/subscriptions/checkoutForm/CheckoutForm";
import BotFramework from "../components/bot_framework";
import AddOnList from "domain/business_admin/add_on/List/List";
import TicketLists from "components/ticket/list/TicketsList";
import EditTickets from "components/ticket/create_update/EditTicket";
import AddTicket from "components/ticket/Add_Ticket/AddTicket";


function BusinessAdminRoutes() {
  return (
    <>
      <BusinessAdminLayout>
        <Route path="/dashboard" exact component={Dashboard} />
        <Route path="/eventlogs" exact component={EventLog} />
        <Route path="/payments" exact component={PaymentList} />
        <Route path="/pay/:plan" exact component={Pay} />
        <Route path="/payments/:id" exact component={ViewPayment} />
        <Route
          path="/bot-framework/:companyId/:botProfileId"
          exact
          component={BotFramework}
        />

        <Route path="/bot-profile" exact component={CallForHelpList} />
        <Route
          path="/bot-profile/business-hours/:id"
          exact
          component={BusinessHours}
        />
        <Route
          path="/bot-profile/add"
          exact
          component={AddCallForHelpProfile}
        />
        <Route
          path="/bot-profile/edit/:id"
          exact
          component={EditCallForHelpProfile}
        />
        <Route path="/users" exact component={UserList} />
        <Route path="/tickets" exact component={TicketLists} />
        <Route path="/users/add" exact component={AddUser} />
        <Route path="/users/edit/:id" exact component={EditUser} />
        <Route path="/users/edit/:id/:mode" exact component={EditUser} />
        <Route path="/tickets/edit/:id" exact component={EditTickets} />
        <Route path="/tickets/edit" exact component={EditTickets} />
        <Route path="/tickets/add" exact component={AddTicket} />
        <Route path="/account/edit" exact component={EditAccount} />
        <Route
          path="/users/password/change/:id"
          exact
          component={ResetPassword}
        />
        <Route path="/subscriptions" exact component={ViewCompany} />
        <Route
          path="/subscriptions/change-subscription"
          exact
          component={ChangeSubscription}
        />
        <Route path="/addons" exact component={AddOnList} />
        <Route path="/customers" exact component={CustomerList} />
        <Route path="/appointments" exact component={Appointments} />
        <Route
          path="/appointments/:customerId"
          exact
          component={Appointments}
        />
        <Route exact path="/callbacks" component={Callbacks} />
        <Route exact path="/callbacks/:customerId" component={Callbacks} />
        <Route path="/agent-sessions" exact component={CallList} />
        <Route path="/agent-sessions/:customerId" exact component={CallList} />
        <Route path="/call/:id" exact component={CallList} />
        <Route path="/bot-sessions" exact component={BotSessionsList} />
        <Route path="/bot-sessions/:id" exact component={BotSessionsList} />
        <Route path="/appointment/:id" exact component={Appointments} />
      </BusinessAdminLayout>
    </>
  );
}
export default BusinessAdminRoutes;
