import { Company } from "utils/interfaces"

export const LOGIN_START = "LOGIN_START"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const UPDATE_USER_COMPANY = "UPDATE_USER_COMPANY"
export const UPDATE_USER = "UPDATE_USER"
export const UPDATE_USER_STATUS = "UPDATE_USER_STATUS"
export const USER_STATUS_CHANGE = "USER_STATUS_CHANGE"
export const LOGIN_FAIL = "LOGIN_FAIL"
export const LOGOUT = "LOGOUT"

export type User = {
    company: Company,
    companyId: string
    email: string
    firstName: string
    id: string
    lastName: string
    role: string
    token: string | null
    status: number
}

export interface LoginStartAction {
    type: typeof LOGIN_START
}

export interface LoginSuccessAction {
    type: typeof LOGIN_SUCCESS
    payload: User
}

export interface UpdateUserCompany {
    type: typeof UPDATE_USER_COMPANY
    payload: { name: string, subdomain: string }
}

export interface UpdateUserAction {
    type: typeof UPDATE_USER
    payload: {
        firstName: string,
        lastName: string,
        role: string,
    }
}

export interface UpdateUserStatusAction {
    type: typeof UPDATE_USER_STATUS
    payload: Partial<User>
}

export interface LoginFailAction {
    type: typeof LOGIN_FAIL
    payload: string
}

export interface LogoutAction {

    type: typeof LOGOUT
}

export interface UserStatusChnageAction {

    type: typeof USER_STATUS_CHANGE
}
export type AuthActionTypes = LoginStartAction | LoginSuccessAction | LoginFailAction | LogoutAction | UserStatusChnageAction | UpdateUserAction | UpdateUserCompany | UpdateUserStatusAction


export const loginStart = (): LoginStartAction => ({
    type: LOGIN_START
})

export const loginSuccess = (user: User): LoginSuccessAction => ({
    type: LOGIN_SUCCESS,
    payload: user
})

export const updateUserCompany = (comapnyData: { name: string, subdomain: string }): UpdateUserCompany => ({
    type: UPDATE_USER_COMPANY,
    payload: comapnyData
}
)
export const updateUser = (userData: {
    firstName: string,
    lastName: string
    role: string
}): UpdateUserAction => ({
    type: UPDATE_USER,
    payload: userData
})

export const updateUserStatus = (userData: Partial<User>): UpdateUserStatusAction => ({
    type: UPDATE_USER_STATUS,
    payload: userData
})

export const loginFail = (error: string): LoginFailAction => ({
    type: LOGIN_FAIL,
    payload: error
})

export const logout = (): LogoutAction => ({
    type: LOGOUT
})

export const userStatusChange = (): UserStatusChnageAction => ({
    type: USER_STATUS_CHANGE
})


