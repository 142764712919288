/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from "react";
import { useEffect } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Page from "../../../../components/page/Page";
import { useQuery } from "@apollo/client";
import { useStyles } from "./style";
import { Box } from "@material-ui/core";
import CallForHelpToolbar from "../toolbar/Toolbar";
import { CALL_FOR_HELP_REFETCH } from "adapters/queries/CallForHelpRefetch";
import CallForHelpItem from "../list_item/List_Item";
import Chip from "@material-ui/core/Chip";

export function isNotNull<T>(it: T): it is NonNullable<T> {
  return it != null;
}

const CallForHelpList = (): JSX.Element => {
  const classes = useStyles();
  const { data, error, fetchMore, loading } = useQuery(CALL_FOR_HELP_REFETCH);
  console.log("Data", data, error);

  useEffect(() => {
    document.title = "Bot Profiles";
    fetchMore({})
  }, []);

  const { botprofiles } = data || {};

  const botprofile = botprofiles;

  const list = useMemo(() => {
    return botprofile && botprofile.edges
      ? botprofile.edges
        .filter(isNotNull)
        .map((edge: any) => edge.node)
        .filter(isNotNull)
      : [];
  }, [botprofile]);

  const LoadMore = () => (
    <div className={classes.rootButton}>
      {botprofiles?.pageInfo?.hasNextPage && (
        <Chip
          onClick={() => {
            fetchMore({
              variables: {
                cursor: botprofiles?.pageInfo?.endCursor,
              },
            });
          }}
          label="Load More"
          className={classes.chip}
          variant="outlined"
        />
      )}
    </div>
  );

  return (
    <Page className={classes.root} title="BotProfiles">
      <CallForHelpToolbar />
      {loading ? null : <Container className={classes.gridList} maxWidth={false}>
        <Box mt={3}>
          <Grid container spacing={3}>
            {list?.length ? (
              list?.map((node: any) => {
                if (node == null) {
                  return null;
                }
                return (
                  <Grid item key={node.id} lg={4} md={6} xs={12}>
                    <CallForHelpItem
                      className={classes.botPrfoileCard}
                      botProfile={node}
                      key={node.id}
                    />
                  </Grid>
                );
              })
            ) : (
              <Grid className={classes.gridEmptyRecords} item xs={12}>
                No Bot Profiles found
              </Grid>
            )}
          </Grid>
        </Box>
        <LoadMore />
      </Container>}
    </Page>
  );
};

export default CallForHelpList;
