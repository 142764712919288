import { gql } from "@apollo/client";

export const EDIT_ADMIN_COMPANY_FRAGMENT = gql`
    fragment EditAdminCompany_data on Company {
        id
        name
        vat
        subdomain
        type
        address {
            id
            billingAddress
            billingCity
            billingState
            billingPostcode
            billingCountry
            correspondanceAddress
            correspondanceCity
            correspondanceState
            correspondancePostcode
            correspondanceCountry
        }
    }
`;
