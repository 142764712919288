import clsx from 'clsx';
import { Doughnut } from 'react-chartjs-2';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { colors } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
  })
);

export default function PieReport({
  className,
  items,
  title,
  ...rest
}: Record<string, any>) {
  const classes = useStyles();
  const theme = useTheme();

  let labels: Array<string> = [];
  let count: Array<number> = [];

  if (items && items.length > 0) {
    items.forEach((item: Record<string, any>) => {
      labels.push(item?.label!);
      if (item?.amount!) {
        count.push(item?.amount!);
      } else if (item?.count!) {
        count.push(item?.count!);
      }
    });
  }

  const data = {
    datasets: [
      {
        data: count,
        backgroundColor: [
          colors.indigo[500],
          colors.red[600],
          colors.orange[600],
        ],
        borderWidth: 8,
        borderColor: colors.common.white,
        hoverBorderColor: colors.common.white,
      },
    ],
    labels: labels,
  };

  const options = {
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    legend: {
      display: true,
      position: 'right',
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary,
    },
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader
        action={
          <Button endIcon={<ArrowDropDownIcon />} size="small" variant="text">
            Last 30 days
          </Button>
        }
        title={title}
      />
      <Divider />
      <CardContent>
        <Box height={400} position="relative">
          <Doughnut data={data} options={options} />
        </Box>
      </CardContent>
    </Card>
  );
}
