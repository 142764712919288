import FormFeedback from "../../form_feedback/FormFeedback";
import FormInput from "../../form/form_input/FormInput";
import FormButton from "@mui/material/Button";
import { useStyles } from "./style";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation, useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { useContext, useEffect, useMemo, useState } from "react";
import { EDIT_BUSINESS_ADMIN_USER } from "adapters/mutations/EditBusinessAdminUser";
import { MANAGER_BUSINESS_ADMIN_REFETCH } from "adapters/queries/ManagersBusinessRefetch";
import PhoneInput from "components/form/phone_input/PhoneInput";
import { isNotNull } from "../list/List";
import { COMPANY_BUSINESS_ADMIN_VIEW } from "adapters/queries/CompanyBusinessAdminView";
import { USER_BUSINESS_ADMIN_REFETCH } from "adapters/queries/UserBusinessRefetch";
import { SnackbarContext } from "components/common_snackbar/CommonSnackBar";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";
import { updateUser } from "redux/actions/AuthActions";
import {
  CardActions,
  CardContent,
  CircularProgress,
  FormControlLabel,
  Grid,
  MenuItem,
  Stack,
  Switch,
} from "@mui/material";

interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  role: string;
  status: number;
  managerId: string;
}

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const schema = yup.object().shape({
  firstName: yup
    .string()
    .required("first name is Required")
    .matches(
      /^(?=.*[A-Za-z])[A-Za-z0-9]+$/,
      'first name should contain Alpha Numeric characters'
    ),
  lastName: yup
    .string()
    .required("last name is Required")
    .matches(
      /^(?=.*[A-Za-z])[A-Za-z0-9]+$/,
      'last name should contain Alpha Numeric characters'
    ),
  email: yup.string()
    .required()
    .matches(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      'email is not valid'
    ),
  phone: yup.string().required().matches(phoneRegExp, 'phone number is not valid'),
  role: yup.string().required(),
});

const roles = [
  // {
  //   value: "owner",
  //   label: "Admin",
  // },
  {
    value: "manager",
    label: "Manager",
  },
  {
    value: "agent",
    label: "Executive",
  },
];

export default function EditForm({
  user,
  isMyProfile,
  viewOnly,
  isOwner,
}: {
  user: any;
  isMyProfile: boolean;
  viewOnly: boolean;
  isOwner: boolean;
}) {
  const active: number = 2;
  const inactive: number = 1;
  const classes = useStyles();
  const history = useHistory();
  const mode = user.status === active ? true : false;
  const [status, setStatus] = useState(mode);
  const [showManagerDropdown, setShowManagerDropdown] = useState(false);
  const { setSnack } = useContext(SnackbarContext);
  const { user: cUser } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  const defaultValues = {
    ...user,
  };

  const [mutate, { loading, error }] = useMutation(EDIT_BUSINESS_ADMIN_USER, {
    onCompleted: (data) => {
      setSnack({
        message: "Record updated successfully",
        severity: "success",
        open: true,
      });
      if (!isMyProfile) {
        history.push(cUser?.role == "admin" ? "/admin/users" : "/users");
      }
      if (isMyProfile) {
        dispatch(updateUser({ ...data.updateUser.userEdge.node }));
      }
    },
    onError: () => {
      console.log(error);
      setSnack({
        message: "Editing user failed",
        severity: "error",
        open: true,
      });
    },
  });

  const { data, refetch } = useQuery(MANAGER_BUSINESS_ADMIN_REFETCH, {
    variables: {
      role: "manager",
    },
  });

  useEffect(() => {
    refetch({ role: "manager" });
  }, []);

  const { users } = data || {};
  const list = useMemo(() => {
    return users && users.edges
      ? users.edges
        .filter(isNotNull)
        .map((edge: any) => edge.node)
        .filter(isNotNull)
      : [];
  }, [users]);

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const roleChange: Array<string> = watch(["role"]);

  useEffect(() => {
    if (roleChange[0] == "agent") {
      setShowManagerDropdown(true);
    } else {
      setShowManagerDropdown(false);
    }
  }, [roleChange]);

  const onSubmit = async ({
    firstName,
    lastName,
    email,
    role,
    phone,
    managerId,
  }: User) => {
    if (!status) {
      const apiUrl = `${process.env.REACT_APP_API_DOMAIN}/api/conferenceService/deactivate_agent`;

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          data: {
            agentId: user?.id,
            agentCompanyId: user?.companyId,
          }
        }),
      };

      fetch(apiUrl, requestOptions)
        .then((res) => console.log(res))
        .catch((err) => console.log(err));
    }

    mutate({
      refetchQueries: [
        { query: USER_BUSINESS_ADMIN_REFETCH },
        { query: COMPANY_BUSINESS_ADMIN_VIEW },
        {
          query: MANAGER_BUSINESS_ADMIN_REFETCH,
          variables: {
            role: "manager",
          },
        },
      ],
      variables: {
        input: isMyProfile
          ? {
            id: user?.id,
            firstName: firstName,
            lastName: lastName,
            email: email,
            phone: phone,
          }
          : {
            id: user?.id,
            firstName: firstName,
            lastName: lastName,
            email: email,
            role: role,
            phone: phone,
            managerId,
            status: status ? active : inactive,
          },
      },
    });
  };

  const maxSize = 15;

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormInput
                disabled={viewOnly}
                control={control}
                type="text"
                name="firstName"
                label="First Name*"
                inputProps={{ maxLength: maxSize }}
                defaultValue={user?.firstName}
                error={errors && errors.firstName}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInput
                disabled={viewOnly}
                control={control}
                type="text"
                name="lastName"
                label="Last Name*"
                defaultValue={user?.lastName}
                error={errors && errors.lastName}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormInput
                disabled
                control={control}
                type="email"
                name="email"
                label="Email"
                defaultValue={user?.email}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <PhoneInput
                disabled={viewOnly}
                control={control}
                name="phone"
                error={errors && errors.phone}
              />
            </Grid>

            {!isMyProfile && (
              <Grid item xs={12} md={6}>
                <FormInput
                  disabled={viewOnly}
                  control={control}
                  type="text"
                  name="role"
                  label="Role"
                  select="select"
                  defaultValue={user?.role}
                  error={errors && errors.role}
                >
                  {roles.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </FormInput>
              </Grid>
            )}

            {(user.role == "agent" || showManagerDropdown) &&
              !isMyProfile &&
              isOwner && (
                <Grid item xs={12} md={6}>
                  <FormInput
                    disabled={viewOnly}
                    control={control}
                    type="text"
                    name="managerId"
                    label="Manager"
                    select="select"
                    defaultValue={user?.managerId}
                  >
                    {list
                      .map((item: User) => {
                        return {
                          value: item.id,
                          label: item.firstName + " " + item.lastName,
                        };
                      })
                      .map((option: { value: string; label: string }) => (
                        <MenuItem
                          key={option.value}
                          value={option.value}
                          disabled={option.value == user?.id}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                  </FormInput>
                </Grid>
              )}

            {!isMyProfile && (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormControlLabel
                    disabled={viewOnly}
                    control={
                      <Switch
                        checked={status}
                        onChange={(event: any) => {
                          setStatus(event.target.checked);
                        }}
                        color="primary"
                        name="statusform"
                        inputProps={{
                          "aria-label": "primary checkbox",
                        }}
                      />
                    }
                    label="Status"
                    labelPlacement="start"
                  />
                </Grid>
              </Grid>
            )}

            {error ? (
              <FormFeedback className={classes.feedback} error>
                {error.message}
              </FormFeedback>
            ) : null}
          </Grid>
        </CardContent>
        {!viewOnly && (
          <Stack direction="row" justifyContent="flex-end" sx={{ paddingX: "1rem" }}>
            <CardActions>
              <div className={classes.buttonWrapper}>
                <FormButton
                  className={classes.button}
                  type="submit"
                  color="secondary"
                  variant="contained"
                  fullWidth
                >
                  Update Record
                </FormButton>

                {loading && (
                  <CircularProgress className={classes.buttonProgress} />
                )}
              </div>
            </CardActions>
          </Stack>
        )}
      </form>
    </div>
  );
}
