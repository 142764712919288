import clsx from "clsx";
import {
  useTheme,
  Hidden,
  Avatar,
  Box,
  Drawer,
  Divider,
  IconButton,
  List,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from "redux/actions/AuthActions";
import { RootState } from "redux/reducers/rootReducer";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import BarChartIcon from "@mui/icons-material/BarChart";
import UsersIcon from "@mui/icons-material/People";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import StorageIcon from "@mui/icons-material/Storage";
import BookIcon from "@mui/icons-material/Book";
import EventAvailable from "@mui/icons-material/EventAvailable";
import Android from "@mui/icons-material/Android";
import { PhoneCallback, SupportAgent } from "@mui/icons-material";
import NavItem from "./NavItem";
import LogoutItem from "components/layout/business_admin/navbar/LogoutItem";
import { useStyles } from "./style";
import { getInitials } from "utils/get-initials";

interface IProps {
  onMobileClose: () => void;
  openMobile: boolean;
  isOpen: boolean;
  setOpen: any;
}

const NavBar = ({ onMobileClose, openMobile, isOpen, setOpen }: IProps) => {
  const { user } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const userProfile = {
    avatar: "/static/images/avatars/avatar_6.png",
    jobTitle: user?.role === "manager" ? "Manager" : "Executive",
    firstName: user?.firstName!,
    lastName: user?.lastName!,
  };

  let items = [
    {
      href: "/dashboard",
      icon: BarChartIcon,
      title: "Dashboard",
    },
  ];

  if (user?.role! === "manager") {
    items = [
      {
        href: "/dashboard",
        icon: BarChartIcon,
        title: "Dashboard",
      },
      {
        href: "/users",
        icon: UsersIcon,
        title: "Users",
      },
    ];
  }

  let postItems = [
    {
      href: "/appointments",
      icon: EventAvailable,
      title: "Appointments",
    },
    {
      href: "/bot-sessions",
      icon: Android,
      title: "Bot Sessions",
    },
    {
      href: "/callbacks",
      icon: PhoneCallback,
      title: "Callbacks",
    },
    {
      href: "/agent-sessions",
      icon: SupportAgent,
      title: "Agent Sessions",
    },
  ];

  const docmeItems = [
    {
      href: "/audit",
      icon: StorageIcon,
      title: "Audit Log",
    },
    {
      href: "/authlogs",
      icon: BookIcon,
      title: "Auth Log",
    },
  ];
  const classes = useStyles();
  const theme = useTheme();

  // useEffect(() => {
  //   if (openMobile && onMobileClose) {
  //     onMobileClose();
  //   }
  // }, [onMobileClose, openMobile]);

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const content = (
    <Box
      sx={{
        backgroundColor: "primary.main",
        height: '100%'
      }}
    >
      <Box
        sx={{
          paddingX: 2,
        }}
      >
        <Box
          className={clsx({
            [classes.hide]: !isOpen,
          })}
          alignItems="center"
          display="flex"
          flexDirection="column"
          color="primary"
          p={2}
        >
          <Avatar
            component={Link}
            to={`/users/edit/${user?.id}`}
            sx={{
              height: 64,
              width: 64,
              fontSize: 24,
              textDecoration: "none",
              margin: 2,
            }}
          >
            {getInitials(userProfile.firstName + " " + userProfile.lastName)}
          </Avatar>
          <Typography color="white" variant="h5">
            {userProfile.firstName}
          </Typography>
          <Typography color="white" variant="h5">
            {userProfile.lastName}
          </Typography>
          <Typography color="white" variant="body2">
            {userProfile.jobTitle}
          </Typography>
        </Box>

        <Divider
          sx={{
            borderColor: "common.white",
          }}
        />

        <Box py={0}>
          <List>
            {items.map((item) => (
              <NavItem
                href={item.href}
                key={item.title}
                title={item.title}
                icon={item.icon}
              />
            ))}
          </List>

          <Divider
            sx={{
              borderColor: "common.white",
            }}
          />

          <List>
            {postItems.map((item) => (
              <NavItem
                href={item.href}
                key={item.title}
                title={item.title}
                icon={item.icon}
              />
            ))}
          </List>

          <Divider
            sx={{
              borderColor: "common.white",
            }}
          />

          <List>
            {docmeItems.map((item) => (
              <NavItem
                href={item.href}
                key={item.title}
                title={item.title}
                icon={item.icon}
              />
            ))}
          </List>

          <Hidden lgUp>
            <Divider
              sx={{
                borderColor: "common.white",
              }}
            />
            <List>
              <LogoutItem
                onClick={() => {
                  dispatch(logout());
                }}
                key={"1"}
                title={`Log Out`}
                icon={PowerSettingsNewIcon}
              />
            </List>
          </Hidden>
        </Box>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              backgroundColor: "primary.main",
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>

      <Hidden mdDown>
        <Drawer
          anchor="left"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: isOpen,
            [classes.drawerClose]: !isOpen,
          })}
          classes={{
            paper: clsx(classes.desktopDrawer, {
              [classes.drawerOpen]: isOpen,
              [classes.drawerClose]: !isOpen,
            }),
          }}
          PaperProps={{
            sx: {
              backgroundColor: "primary.main",
            }
          }}
          open
          variant="permanent"
        >
          <Hidden lgUp>
            <div className={classes.toolbar}>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "rtl" ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )}
              </IconButton>
            </div>
          </Hidden>
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

export default NavBar;
