import { useEffect } from "react";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import Page from "../../../../components/page/Page";
import CircularProgress from "../../../../components/facebook_progress/FacebookProgress";
import { useStyles } from "./style";
import { useQuery } from "@apollo/client";
import EditForm from "./EditForm";
import { CALL_FOR_HELP_BUSINESS_ADMIN_EDIT } from "adapters/queries/CallForHelpBusinessAdminEdit";

export default function EditCallForHelpProfile(props: any) {
  const classes = useStyles();
  let { id } = props.match.params;
  const { data, loading } = useQuery(CALL_FOR_HELP_BUSINESS_ADMIN_EDIT, {
    variables: { id },
  });

  const botProfile = data?.botprofile;

  useEffect(() => {
    document.title = "Edit Bot Profile";
  }, []);

  if (loading) {
    return <CircularProgress size={15} />;
  }

  console.log("botProfile", botProfile);
  return (
    <Page title="Edit Bot Profile">
      <Container className={classes.container} maxWidth={false}>
        <Card>
          <CardHeader
            subheader={botProfile?.name}
            title="Modify Call Bot Profile Button"
          />
          <Divider />

          <Divider />
          <EditForm botProfile={botProfile} />
        </Card>
      </Container>
    </Page>
  );
}
