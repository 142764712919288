import { useEffect } from "react";
import Page from "../../page/Page";
import CircularProgress from "../../facebook_progress/FacebookProgress";
import { useStyles } from "./style";
import { useQuery } from "@apollo/client";
import EditForm from "./EditForm";
import { USER_BUSINESS_ADMIN_EDIT } from "adapters/queries/UserBusinessAdminEdit";
import ResetPassword from "../reset_password/ResetPassword";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";
import { Avatar, Card, CardContent, CardHeader, Container, Grid, Typography } from "@mui/material";
import { getInitials } from "utils/get-initials";


export default function EditUser(props: any) {
  const classes = useStyles();
  let { id, mode } = props.match.params;
  const viewOnly = mode === "view"
  const userId = useSelector((state: RootState) => state.auth.user?.id);
  const isMyProfile = userId === id
  const userRole = useSelector((state: RootState) => state.auth.user?.role);
  const isOwner = userRole === 'owner'
  const { data, loading } = useQuery(USER_BUSINESS_ADMIN_EDIT, {
    variables: { id },
  });

  const user = data?.user;

  useEffect(() => {
    document.title = isMyProfile ? "My Profile" : viewOnly ? "View User" : "Edit User";

  }, []);

  if (loading) {
    return <CircularProgress size={15} />;
  }

  return (
    <Page className={classes.root} title="Edit User">
      <Container className={classes.container}>
        <Typography variant="h3" sx={{paddingY : "1rem"}} className={classes.title}>User Details</Typography>
        <Grid container spacing={5} justifyContent="center">
          <Grid item md={4} xs={12}>
            <Card className={classes.userDetailsCard}>
              <Avatar className={classes.avatarSize} sx={{ width: 80, height: 80 }}>
                <Typography variant="h4">{getInitials(user?.firstName + " " + user?.lastName)}</Typography>
              </Avatar>
              <CardContent>
                <Typography variant="h5">
                  {user?.firstName} {user.lastName}
                </Typography>
                <Typography>{user?.email}</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={8} xs={12}>
            <Card className={classes.card}>
              <CardHeader
                title={isMyProfile ? "My Profile" : viewOnly ? "User Details" : "Modify User Details"}
                subheader={`${user?.firstName}`}
                className={classes.cardHeader}
                titleTypographyProps={{
                  className: classes.cardTitle,
                }}
                subheaderTypographyProps={{
                  className: classes.cardSubheader,
                }}
              />
              <EditForm user={user} isMyProfile={isMyProfile} viewOnly={viewOnly} isOwner={isOwner} />
              {!viewOnly && <ResetPassword user={user} />}
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
