// import { format } from "ts-date/esm/locale/en";
import { IconButton, Stack, Typography } from "@mui/material";
import { CartItem as CartItemType } from '../../../../redux/reducers/AddOnCartReducer'
import Add from "@mui/icons-material/Add";
import Remove from "@mui/icons-material/Remove";
import { useDispatch } from "react-redux";
import { decreaseQuantity, increaseQuantity, removeItem } from "redux/actions/AddOnCartActions";

interface CartItemProps {
  item: CartItemType
}

export default function CartItem({ item }: CartItemProps) {

  const dispatch = useDispatch()

  const increaseItem = () => {
    dispatch(increaseQuantity(item.id))
  };

  const decreaseItem = () => {
    dispatch(decreaseQuantity(item.id))
  };

  const removeCartItem = () => {
    dispatch(removeItem(item.id))
  };



  return (
    <Stack style={{ padding: 5, backgroundColor: 'white', margin: 10, borderRadius: 15 }}>
      <Stack flexDirection={'row'} style={{ padding: 10 }}>
        <Typography variant="subtitle1" flex={1}>{item.name}</Typography>
        <Typography variant="body1">£{item.price}</Typography>
      </Stack>
      <Stack flexDirection={'row'} >
        <Typography flex={1} />
        <Stack flexDirection={'row'} alignItems={'center'} style={{ marginBottom: 7, marginRight: 7 }}>
          <IconButton
            style={{ backgroundColor: '#9c27b0', color: 'white', height: 25, width: 25 }}
            onClick={() => item.quantity > 1 ? decreaseItem() : removeCartItem()}
          >
            <Remove />
          </IconButton>
          <Typography style={{ marginLeft: 10, marginRight: 10 }}>{item.quantity}</Typography>
          <IconButton
            style={{ backgroundColor: '#9c27b0', color: 'white', height: 25, width: 25 }}
            onClick={() => increaseItem()}
          >
            <Add />
          </IconButton>
        </Stack>
      </Stack>
    </Stack >
  );
}
