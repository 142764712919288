import { gql } from "@apollo/client";

export const AGENT_AUDITS = gql`
query audits($userId: String, $first: Int =10, $after: String){
  audits(userId: $userId, first: $first, after: $after){
    pageInfo{
      hasNextPage,
      hasPreviousPage,
      startCursor,
      endCursor
    },
            edges {
                node {
                    id
                    entity
                    action
                    user {
                        firstName
                        lastName
                        id
                    }
                    createdAt
                    __typename
                }
                cursor
            }
  }
}
`;
