import { AddOn } from "domain/business_admin/add_on/List/List"


export const ADD_ITEM = "ADD_ITEM"
export const REMOVE_ITEM = "REMOVE_ITEM"
export const INCREASE_QUANTITY = "INCREASE_QUANTITY"
export const DECREASE_QUANTITY = "DECREASE_QUANTITY"

export interface AddItemAction {
    type: typeof ADD_ITEM,
    payload: AddOn
}

export interface RemoveItemAction {
    type: typeof REMOVE_ITEM
    payload: string
}

export interface IncreaseQuantiyAction {
    type: typeof INCREASE_QUANTITY
    payload: string
}

export interface DecreaseQuantiyAction {
    type: typeof DECREASE_QUANTITY
    payload: string
}

export type AddOnCartActionTypes = AddItemAction | RemoveItemAction | IncreaseQuantiyAction | DecreaseQuantiyAction

export const addItem = (item: AddOn): AddItemAction => ({
    type: ADD_ITEM,
    payload: item
})

export const removeItem = (id: string): RemoveItemAction => ({
    type: REMOVE_ITEM,
    payload: id
})

export const increaseQuantity = (id: string): IncreaseQuantiyAction => ({
    type: INCREASE_QUANTITY,
    payload: id
})

export const decreaseQuantity = (id: string): DecreaseQuantiyAction => ({
    type: DECREASE_QUANTITY,
    payload: id
})
