import { Theme, createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        listItem: {
            borderBottom: "1px solid rgb(224, 224, 224)",
            "&:last-child": {
                borderBottom: "none"
            },
            padding: theme.spacing(2.5, 2)
        }
    })
);
