import { gql } from "@apollo/client";

export const ONBOARD_ADMIN_COMPANY = gql`
    mutation OnBoardAdminCompanyMutation($input: OnBoardInput!) {
        onBoard(input: $input) {
            companyEdge {
                node {
                    id
                }
            }
        }
    }
`;
