import { Table, TableBody, TableCell, TableHead, TableRow, Hidden, LinearProgress, Grid } from '@mui/material';

const GenericTable = ({ columns, data, totalCount, loading, renderItem }: any) => (
  <>
    {loading && <LinearProgress />}
    <Table>
      <Hidden smDown>
        <TableHead>
          <TableRow>
            {columns.map((col: any) => (
              <TableCell key={col.key}>{col.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
      </Hidden>
      <TableBody>
        {totalCount ? (
          data.map(renderItem)
        ) : (
          <Grid container justifyContent="center">
            <Grid item>No data found</Grid>
          </Grid>
        )}
      </TableBody>
    </Table>
  </>
);

export default GenericTable;
