import { useMemo, useState } from "react";
import { useEffect } from "react";
import { useStyles } from "./style";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { Hidden } from "@mui/material";
import ListItem from "@material-ui/core/ListItem";
import ListSubheader from "@material-ui/core/ListSubheader";
import Page from "../../../../components/page/Page";
import CompanyToolbar from "../toolbar/Toolbar";
import { COMPANY_REFETCH } from "../../../../adapters/queries/CompanyRefetch";
// import { COMPANY_BUSINESS_ADMIN_VIEW } from "adapters/queries/CompanyBusinessAdminView";
import { useQuery } from "@apollo/client";
import CompanyItem from "../list_item/ListItem";
import Chip from "@material-ui/core/Chip";

export function isNotNull<T>(it: T): it is NonNullable<T> {
  return it != null;
}

type Edge = {
  node: {
    id: string;
    name: string;
    type: string | null;
    status: string | null;
    createdAt: unknown;
  };
};

const ClientList = (): JSX.Element => {
  const classes = useStyles();
  const [count, setCount] = useState(5);

  //pagination
  // const [page, setPage] = useState(0);

  console.log(setCount);

  const { data, fetchMore } = useQuery(COMPANY_REFETCH, {
    variables: { count }
  });

  

  useEffect(() => {
    document.title = "Businesses";
  }, []);

  const { companies } = data || {};

  const list = useMemo(() => {
    return companies && companies.edges
      ? companies.edges
        .filter(isNotNull)
        .map((edge: Edge) => edge.node)
        .filter(isNotNull)
      : [];
  }, [companies]);
  console.log(companies);

  
  const handleLoadMore = () => {
    setCount(prevCount => prevCount + 5);
  };
  


  const LoadMore = () => (
    <div className={classes.rootButton}>
      {companies?.pageInfo?.hasNextPage && (
        <Chip
          onClick={() => {
            handleLoadMore();
            fetchMore({
              variables: {
                cursor: companies?.pageInfo?.endCursor,
              },
            })
          }
        }
          label="Load More"
          className={classes.chip}
          variant="outlined"
        />
      )}
    </div>
  );

  return (
    <Page className={classes.root} title="companies">
      <Container maxWidth={false}>
        <CompanyToolbar />
        <Grid className={classes.gridList} container direction="row">
          <Hidden smDown>
            <ListItem
              className={classes.gridHeading}
              key={1}
              style={{ width: "100%" }}
            >
              <Grid item md={3}>
                <ListSubheader disableGutters component="div">
                  Name
                </ListSubheader>
              </Grid>

              <Grid item md={3}>
                <ListSubheader disableGutters component="div">
                  Type
                </ListSubheader>
              </Grid>

              <Grid item md={2}>
                <ListSubheader disableGutters component="div">
                  Status
                </ListSubheader>
              </Grid>

              <Grid item md={1}>
                <ListSubheader disableGutters component="div">
                  Plan
                </ListSubheader>
              </Grid>
              <Grid item md={1}>
                <ListSubheader disableGutters component="div"></ListSubheader>
              </Grid>
            </ListItem>
          </Hidden>
          {list?.length ? (
            list?.map((node: any) => (
              <CompanyItem key={node.id} company={node} id={node.id} />
            ))
          ) : (
            <Grid
              className={classes.gridEmptyRecords}
              container
              direction="column"
              item
              xs={12}
            >
              No businesses found
            </Grid>
          )}
        </Grid>
        <LoadMore />
      </Container>
    </Page>
  );
};

export default ClientList;
