import Page from "components/page/Page"
import { useHistory } from "react-router-dom"
import PlanButton from "../change_subscription/PlanButton";

function PaymentMethodCancel() {
    const history = useHistory();

    const handleClick = () => {
        history.push("/subscriptions/change-subscription")
    }
    return (
        <Page title="Cancel ">
            <PlanButton handleClick={handleClick} name="Return to subscriptions" />
        </Page>
    )
}

export default PaymentMethodCancel

