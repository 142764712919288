import { gql } from "@apollo/client";

export const LIST_AGENTS = gql`
  query Users($role: String, $companyId: String) {
    users(role: $role, companyId: $companyId) {
      edges {
        node {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
