import { useEffect } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { useQuery } from "@apollo/client";
import { Card, CardContent, CardHeader, Divider } from "@material-ui/core";
import Page from "components/page/Page";
import { format } from "ts-date/esm/locale/en";
import { PAYMENT_BUSINESS_ADMIN_VIEW } from "adapters/queries/PaymentBusinessAdminView";

export default function ViewPayment(props: any) {
  const { id } = props.match.params;
  const { data, error } = useQuery(PAYMENT_BUSINESS_ADMIN_VIEW, {
    variables: { id },
  });
  console.log(error);

  useEffect(() => {
    document.title = "View Payment";
  }, []);

  const { payment } = data || {};

  let dt: any;
  dt = "";
  if (typeof payment?.createdAt! === "string") {
    const str1 = new Date(payment?.createdAt!).toISOString();
    if (str1) {
      dt = format(new Date(str1.substr(0, 19)), "DD MMM, YYYY - hh:mm A");
    }
  }

  return (
    <Page title="Payment View">
      <Container maxWidth={false}>
        <Card>
          <CardHeader subheader={payment?.planId!} title="Payment View" />
          <Divider />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                {" "}
                Name:{" "}
              </Grid>
              <Grid item xs={8}>
                {" "}
                {payment?.user?.firstName! +
                  " " +
                  payment?.user?.lastName!}{" "}
              </Grid>
              <Grid item xs={4}>
                {" "}
                Company:{" "}
              </Grid>
              <Grid item xs={8}>
                {" "}
                {payment?.company?.name!}{" "}
              </Grid>
              <Grid item xs={4}>
                {" "}
                Subscription:{" "}
              </Grid>
              <Grid item xs={8}>
                {" "}
                {payment?.pgwSubscriptionId!}{" "}
              </Grid>
              <Grid item xs={4}>
                {" "}
                Amount:{" "}
              </Grid>
              <Grid item xs={8}>
                {" "}
                {payment?.amount! ? "£ " + payment?.amount! : "£ 0"}{" "}
              </Grid>
              <Grid item xs={4}>
                {" "}
                Status:{" "}
              </Grid>
              <Grid item xs={8}>
                {" "}
                {payment?.status!}{" "}
              </Grid>
              <Grid item xs={4}>
                {" "}
                Date:{" "}
              </Grid>
              <Grid item xs={8}>
                {" "}
                {dt}{" "}
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
        </Card>
      </Container>
    </Page>
  );
}
