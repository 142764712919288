import { gql } from "@apollo/client";
import { LIST_BUSINESS_ADMIN_MANAGERS } from "adapters/fragments/ListBusinessAdminManagers";

export const MANAGER_BUSINESS_ADMIN_REFETCH = gql`
    ${LIST_BUSINESS_ADMIN_MANAGERS}
    query ManagerAccountFragmentRefetchQuery(
        $role: String
    ) {
        ...ListAccount_managers_1G22ux
    }
`;
