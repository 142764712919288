import { createContext, useState } from 'react';
import { Snackbar } from "@material-ui/core";
import { Alert, Color } from '@material-ui/lab';

export const SnackbarContext = createContext({
  snack: {
    message: "",
    severity: "success",
    open: false,
  }, setSnack: (data: {
    message: string,
    severity: Color,
    open: boolean,
  }) => {
    console.log(data);
  }
});

export default function CommonSnackBar(props: any): JSX.Element {
  const [snack, setSnack] = useState<{
    message: string,
    severity: Color,
    open: boolean
  }>({
    message: '',
    severity: 'info',
    open: false,
  });

  const handleClose = () => {
    setSnack({ ...snack, open: false })
  }

  return (
    <div>
      <SnackbarContext.Provider value={{ snack, setSnack }}>
        <Snackbar open={snack.open} autoHideDuration={10000} onClose={handleClose}>
          <Alert elevation={6} variant="filled" onClose={handleClose} severity={snack.severity}>
            {snack.message}
          </Alert>
        </Snackbar>
        {props.children}
      </SnackbarContext.Provider>
    </div>
  );
}
