
import {
  CardActions,
  CardContent,
  CircularProgress,
  Divider, Button as FormButton,
  MenuItem
} from "@mui/material";

import FormFeedback from "../../../../components/form_feedback/FormFeedback";
import FormInput from "../../../../components/form/form_input/FormInput";
import { useStyles } from "./style";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation } from "@apollo/client";
import { USER_BUSINESS_ADMIN_REFETCH } from "adapters/queries/UserBusinessRefetch";
import { EDIT_BUSINESS_ADMIN_COMPANY } from "adapters/mutations/EditBusinessAdminCompany";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";
import PhoneInput from "components/form/phone_input/PhoneInput"
import { useContext } from "react";
import { SnackbarContext } from "components/common_snackbar/CommonSnackBar";
import { updateUserCompany } from "redux/actions/AuthActions";

const schema = yup.object().shape({
  name: yup.string().required(),
});

const types = [
  {
    value: "1",
    label: "General",
  },
  {
    value: "2",
    label: "Real Estate",
  },
  {
    value: "3",
    label: "Accounting",
  },
  {
    value: "4",
    label: "Law Firm",
  },
  {
    value: "5",
    label: "Health Care",
  },
  {
    value: "6",
    label: "Others",
  },
];

export default function EditCompanyForm({ company }: any) {
  const classes = useStyles();
  const { user } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch()
  const { setSnack } = useContext(SnackbarContext);
  const defaultValues = {
    ...company,
  };

  const [mutate, { loading, error }] = useMutation(
    EDIT_BUSINESS_ADMIN_COMPANY,
    {
      onCompleted: (res) => {
        dispatch(updateUserCompany(res.updateCompany.companyEdge.node))
        setSnack({ message: "Record Updated Successfully", severity: 'success', open: true });
      },
      onError: () => {
        setSnack({ message: "Editing company failed", severity: 'error', open: true });
      },
    }
  );

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onSubmit = ({ name, type, vat, phone, subdomain }: any) => {
    mutate({
      refetchQueries: [{ query: USER_BUSINESS_ADMIN_REFETCH }],
      variables: {
        input: {
          id: company?.id!,
          name: name!,
          type: type!,
          vat: vat!,
          phone: phone!,
          subdomain: subdomain,
          planId: company?.planId
        },
      },
    });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <CardContent>
          <FormInput
            control={control}
            type="text"
            name="name"
            label="Businesss Name*"
            defaultValue={company?.name}
            error={errors && errors.name}
            fullWidth
          />
          <br />
          <br />
          <FormInput
            control={control}
            name="type"
            defaultValue={company?.type}
            label="Business Type"
            select={true}
            fullWidth
          >
            {types.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </FormInput>
          <br />
          <br />
          <FormInput
            control={control}
            type="text"
            name="vat"
            defaultValue={company?.vat}
            label="VAT Number"
            fullWidth
          ></FormInput>
          <br />
          <br />
          <FormInput
            control={control}
            type="email"
            defaultValue={user?.email}
            name="email"
            label="Email"
            fullWidth
            disabled
          />
          <br />
          <br />
          <PhoneInput
            control={control}
            name="phone"
          />
          <br />
          <FormInput
            control={control}
            type="text"
            name="subdomain"
            defaultValue={company?.subdomain}
            fullWidth
            label="Subdomain"
            disabled
          />
          <br />
          {
            error ? (
              <FormFeedback className={classes.feedback} error>
                {error.message}
              </FormFeedback>
            ) : null
          }
        </CardContent>
        <Divider />
        <CardActions className={classes.buttonContainer}>
          <div className={classes.buttonWrapper}>
            <FormButton
              className={classes.button}
              type="submit"
              color="secondary"
              variant="contained"
              fullWidth
            >
              Update Record
            </FormButton>
            {loading && <CircularProgress className={classes.buttonProgress} />}
          </div>
        </CardActions>
      </form>
    </div>
  );
}
