//@ts-nocheck
import "./App.css";
import { useState, useMemo, useEffect, useRef } from "react";
import Routes from "./routes/Routes";
import { Socket, io } from "socket.io-client";
import { SocketEventContext } from "context/SocketEventContext";
import { useSnackbar } from "notistack";
import { Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";
import { CREATE_SESSION } from "adapters/mutations/CreateSession";
import { useLazyQuery, useMutation } from "@apollo/client";
import { callStatus } from "utils/constants";
import { updateUserStatus, userStatusChange } from "redux/actions/AuthActions";
import CallAlert from "components/call_alert/CallAlert";
import { createEmotionCache } from "utils/create-emotion-cache";
import { createTheme } from "theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { CacheProvider } from "@emotion/react";
import { GET_USER_DETAILS } from "adapters/queries/GetUserDetails";
import { updateSession } from "redux/actions/AgentSessionActions";
// import ScreenCapture from "components/screen_capture/ScreenCapture";
// import AgentConference from "components/conference/appointment/AngentConference";

const clientSideEmotionCache = createEmotionCache();

function App(props) {
  const theme = createTheme();
  const { emotionCache = clientSideEmotionCache } = props;

  const { user } = useSelector((state: RootState) => state.auth);
  const { agentStatus } = useSelector((state: RootState) => state.agentSession);


  const dispatch = useDispatch()

  const socketRef = useRef(null)

  const agent = {
    from: "agent",
    to: "application",
    id: "12345",
    agent: user,
  };

  const [runQuery] = useLazyQuery(GET_USER_DETAILS, {
    onCompleted: (data) => {
      dispatch(updateUserStatus(data?.user))
    }
  });

  const [socketEvent, setSocketEvent] = useState(null);
  const eventValue = useMemo(
    () => ({ socketEvent, setSocketEvent }),
    [socketEvent, setSocketEvent]
  );

  const [createSession, { error: err }] = useMutation(CREATE_SESSION, {
    onCompleted: () => { },
    onError: (error) => {
      console.log(err?.message);
      console.log(error);
    },
  });

  const { enqueueSnackbar } = useSnackbar();


  useEffect(() => {
    let socket: Socket = null

    if (agentStatus == 'offline' || agentStatus == 'busy') {
      if (socket != null && socket?.connected) {
        socket.disconnect()
      }
    }

    if ((user?.role == "manager" || user?.role == "agent") && user?.status == 2) {

      console.log("callled socket");

      if (socket == null || !socket?.connected) {
        socket = io(process.env.REACT_APP_SOCKET_NSP_URL, {
          transports: ["websocket", "xhr-polling", "htmlfile", "jsonp-polling"],
          query: {
            token: user?.token,
          },
        });
      }

      socket.on("connect", () => {
        if (agentStatus == "online") {
          socketRef.current = socket
          console.log("connected");
          socket.emit("call", "conferenceService.agent_available", agent);
        }
      });

      socket.once("customer-assigned", (event) => {
        const data = event && JSON.parse(event);
        localStorage.setItem(
          "chatRoomId",
          data.chatRoomId.replace("==", "").toLowerCase()
        );
        localStorage.setItem("jitsi_token", data?.token);
        setSocketEvent(data);


        //As soon as Customser is Assgined to Agent, create session record with "MISSED" status
        createSession({
          variables: {
            input: {
              companyId: data?.companyId,
              customerId: data?.customerId,
              userId: data?.agentId,
              chatRoomId: data?.chatRoomId,
              status: callStatus.MISSED,
            },
          },
        });
      });

      socket.once("connect_error", (err) => {
        console.log(`connect_error due to ${JSON.stringify(err)}`);
        dispatch(updateSession({ agentStatus: 'offline', isCallOn: true }))
      });

      socket.once("agent-status-change", () => {
        console.log(`User status chnage`);
        //add active listener
        enqueueSnackbar("Your licence have been rewoked!", { variant: "warning", autoHideDuration: 5000 });
        dispatch(userStatusChange())
        socket.disconnect()
      });

    } else {
      if (socket != null) {
        socket.disconnect()
      }
    }

    return () => {
      if (socket != null) {
        socket.disconnect()
      }
    }
  }, [user, agentStatus])

  useEffect(() => {
    if (user?.id) {
      runQuery({
        variables: {
          userId: user?.id
        },
      })
    }

  }, [])

  console.log("Rendering main screen");

  useEffect(() => {
    if (user?.id) {
      runQuery({
        variables: {
          userId: user?.id
        },
      })
    }

  }, [])

  return (
    <div>
      <CacheProvider value={emotionCache}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Switch>
            <SocketEventContext.Provider value={eventValue}>
              {/* <ScreenCapture /> */}
              <CallAlert open={socketEvent !== null} onReject={() => {
                if (socketRef.current !== null) {
                  socketRef.current.disconnect()
                }
                setSocketEvent(null)
                window.location.reload();
              }} message={socketEvent?.message} />
              <Routes />
              {/* <AgentConference/> */}
            </SocketEventContext.Provider>
          </Switch>
        </ThemeProvider>
      </CacheProvider>
    </div>
  );
}
export default App;
