import { useEffect } from "react";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import Page from "../../../../components/page/Page";
import CircularProgress from "../../../../components/facebook_progress/FacebookProgress";
import { useStyles } from "./style";
import { useQuery } from "@apollo/client";
import { COMPANY_ADMIN_EDIT } from "../../../../adapters/queries/CompanyAdminEdit";
import EditForm from "./EditForm";

export default function EditCompany(props: any) {
  const classes = useStyles();
  let { id } = props.match.params;
  const { data, loading } = useQuery(COMPANY_ADMIN_EDIT, {
    variables: { id },
  });
  const company = data?.company;

  useEffect(() => {
    document.title = "Modify Business Details";
  }, []);

  if (loading) {
    return <CircularProgress size={15} />;
  }

  return (
    <Page className={classes.root} title="Modify Company">
      <Container className={classes.container} maxWidth="xs">
        <Card className={classes.card}>
          <CardHeader
            title="Modify Business Details"
            subheader={`${company?.name!}`}
            className={classes.cardHeader}
            titleTypographyProps={{
              className: classes.cardTitle,
            }}
            subheaderTypographyProps={{
              className: classes.cardSubheader,
            }}
          />
          <Divider />
          <EditForm company={company} />
        </Card>
      </Container>
    </Page>
  );
}
