//@ts-nocheck
import { useMutation, useQuery } from "@apollo/client";
import {
  Typography,
  Grid,
  Card,
  Divider,
  CardHeader,
  Box,
  Button,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import { CHANGE_SUBSCRIPTION_COMPANY } from "adapters/mutations/ChangeSubscriptionCompany";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";
import { Link } from "react-router-dom";
import "./styles.css";
import { useStyles } from "./style";
import { useContext, useState } from "react";
import { RETRIEVE_UPCOMING_INVOICE } from "adapters/queries/RetrieveUpcomingInvoice";
import PlanButton from "../change_subscription/PlanButton";
import { RETRIEVE_CUSTOMER_PAYMENT_METHOD } from "adapters/queries/RetrieveCustomerPaymentMethod";
import { UPDATE_PAYMENT_METHOD } from "../../../../adapters/mutations/UpdatePaymentMethod";
import { SnackbarContext } from "components/common_snackbar/CommonSnackBar";

const CheckoutForm = ({ match }) => {
  const { user } = useSelector((state: RootState) => state.auth);
  const { plan } = match.params;
  const classes = useStyles();
  let currentPlan = user?.company?.plan?.name;
  const [checkoutUrl, setCheckoutUrl] = useState("");
  // const [subscriptionChangeModal, setSubscriptionChangeModal] = useState(false);
  const { setSnack } = useContext(SnackbarContext);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [changeSubscription] = useMutation(CHANGE_SUBSCRIPTION_COMPANY, {
    onCompleted: () => {
      setSnack({
        message: "Subscription successfully updated. Please logout and login",
        severity: "success",
        open: true,
      });
    },
    onError: () => {
      setSnack({
        message: "Could not change subscription. Please try again",
        severity: "error",
        open: true,
      });
    },
  });

  const [updatePayment, { data: payment, loading: paymentLoading }] =
    useMutation(UPDATE_PAYMENT_METHOD, {
      onCompleted: () => {
        setCheckoutUrl(payment?.updatePaymentMethod?.url);
      },
      onError: () => {
        setMessage("Could not update payment. Please try again later");
      },
    });

  if (!paymentLoading && payment) {
    window.location.href = payment.updatePaymentMethod?.url;
  }

  const { data, loading } = useQuery(RETRIEVE_UPCOMING_INVOICE, {
    variables: {
      planId: plan,
    },
  });

  const { data: invoice, loading: invoiceLoading } = useQuery(
    RETRIEVE_CUSTOMER_PAYMENT_METHOD,
    {
      variables: {
        planId: plan,
      },
    }
  );

  console.log("checkoutUrl", checkoutUrl);

  const handlePayment = () => {
    updatePayment();
    setTimeout(() => {
      if (checkoutUrl) {
        window.location.href = checkoutUrl;
      }
    }, 3000);
  };

  // const triggerSubscriptionChangeModal = () => {
  //   if (!subscriptionChangeModal){
  //     setSubscriptionChangeModal(true);
  //   }
  // };

  const handleSubscriptionChange = () => {
    changeSubscription({
      variables: {
        planId: plan,
      },
    });

    handleClose();
  };

  const billDate = new Date(
    data?.retrieveUpcomingInvoice.nextBillPeriod * 1000
  ).toLocaleDateString();
  if (loading || invoiceLoading) return <p>Loading...</p>;
  return (
    <>
      <div className={classes.pageTitle}>
        <h1>Update Your Plan</h1>
      </div>
      <div className={classes.outerDiv}>
        <div className={classes.innerDivLeft}>
          <Card className={classes.root}>
            <CardHeader
              title="Review and Place Order"
              className={classes.cardHeader}
            />
            <Divider />
            <CardContent>
              <div className={classes.cardContent}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box className={classes.spaceBetween}>
                      <Typography color="textSecondary">
                        Current Plan:{" "}
                      </Typography>
                      <Typography>
                        <span className={classes.planName}>
                          {currentPlan || "free"}
                        </span>
                      </Typography>
                    </Box>
                    <Box className={classes.spaceBetween}>
                      <Typography color="textSecondary">New Plan:</Typography>
                      <Typography>
                        <span className={classes.planName}>{plan}</span>
                      </Typography>
                    </Box>
                    <Box className={classes.spaceBetween}>
                      <Typography color="textSecondary">Amount due:</Typography>
                      <Typography>
                        <span className={classes.planName}>{`£${
                          data?.retrieveUpcomingInvoice?.amount_due / 100
                        }`}</span>
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </div>
            </CardContent>
          </Card>
          <Card className={classes.root}>
            <CardHeader
              title="Payment Details"
              className={classes.cardHeader}
            />
            <Divider />
            <CardContent>
              <div className={classes.cardContent}>
                <Grid container>
                  <Grid item xs={12}>
                    <div className="paymentMethod">
                      <Typography color="textSecondary">
                        Payment method:{" "}
                        <span
                          className={classes.planName}
                        >{`${invoice?.retrieveCustomerPaymentMethod?.card?.brand} ending in ${invoice?.retrieveCustomerPaymentMethod?.card?.last4}`}</span>
                        <Link to="/subscriptions/change-subscription"></Link>
                      </Typography>
                      <button
                        onClick={handlePayment}
                        className="updatePaymentBtn"
                      >
                        Update payment
                      </button>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </CardContent>
          </Card>
        </div>
        <div className={classes.innerDivRight}>
          <Card className={classes.root}>
            <CardHeader title="Confirmation" className={classes.cardHeader} />
            <Divider />
            <CardContent>
              <div className={classes.cardContent}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box className={classes.spaceBetween}>
                      <Typography color="textSecondary">Amount due:</Typography>
                      <Typography>
                        <span className={classes.planName}>{`£${
                          data?.retrieveUpcomingInvoice?.amount_due / 100
                        }`}</span>
                      </Typography>
                    </Box>
                    <Typography color="textSecondary">
                      Your next bill is for{" "}
                      <span className={classes.planName}>
                        {`£${
                          data?.retrieveUpcomingInvoice.nextBillAmount / 100
                        } `}{" "}
                      </span>{" "}
                      on{" "}
                      <span className={classes.planName}>{`${billDate}`}</span>
                      <Link to="/subscriptions/change-subscription"></Link>
                    </Typography>
                    <Typography component="div" color="textSecondary">
                      <Box className={classes.disclaimerNote}>
                        Please note that downgrading to a plan that offers fewer
                        agents than you currently have will deactivate all
                        agents and you will have to manually reactivate the ones
                        you would like to activate
                      </Box>
                    </Typography>
                  </Grid>
                </Grid>
                <br />
                <div className={classes.changePlanBtn}>
                  <PlanButton
                    name="Change plan"
                    handleClick={handleClickOpen}
                    variant="contained"
                  />
                </div>
                <div>
                  <Dialog
                    open={open}
                    onClose={handleClose}
                  >
                    <DialogContent>
                      <DialogContentText style={{color : 'black', fontSize : "20px" , }} >
                        Do you want to confirm your plan changes?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose}>Cancel</Button>
                      <Button onClick={handleSubscriptionChange} autoFocus className={classes.changePlanBtn} variant="contained" color="primary">
                        Confirm
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  );
};

export default CheckoutForm;
