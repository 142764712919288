import React, { useMemo } from "react";
import { useQuery } from "@apollo/client";
import {
  Grid,
  Typography,
  LinearProgress,
  Container,
  Stack,
  IconButton,
} from "@mui/material";
import useStyles from "./style";
import Page from "../../../../components/page/Page";
import AddOnItem from "../add_on_item";
import ShoppingCart from "@mui/icons-material/ShoppingCart";
import Close from "@mui/icons-material/Close";
import AddOnCart from "../add_on_cart";
import { useDispatch } from "react-redux";
import { addItem } from "redux/actions/AddOnCartActions";
import { ADD_ONS_REFETCH } from "adapters/queries/AddOnsRefetch";

export interface AddOn {
  id: string;
  name: string;
  description: string;
  allowanceType: string;
  allowanceUnits: number;
  price: number;
  active: boolean;
  expiryDate?: Date;
  imageUrl?: string;
}

const AddOnList: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const [isCartOpen, setIsCartOpen] = React.useState(false); // New state for sidebar visibility

  const { data, loading, error } = useQuery(ADD_ONS_REFETCH, {
    variables: {
      first: null,
      before: null,
      last: null,
      after: "",
    },
    notifyOnNetworkStatusChange: true,
  });

  const addOns = useMemo(
    () =>
      data?.listAddOn.filter((addon: any) => addon.active) ||
      [],
    [data]
  );

  if (error) {
    return <div>Error loading add-ons. Please try again later.</div>;
  }

  const addItemToCart = (addOn: AddOn) => {
    dispatch(addItem(addOn))
    setIsCartOpen(true); // Open sidebar on button click
  };

  const toggleCart = () => {
    setIsCartOpen(!isCartOpen)
  }

  return (
    <Page className={classes.root} title="Add-Ons">
      {loading && <LinearProgress />}
      <Stack flexDirection={'row'} alignItems={'center'}>
        <Typography variant="h5" marginLeft={5} flex={1}>
          Add Ons
        </Typography>
        <IconButton
          style={{ marginRight: 20 }}
          onClick={() => toggleCart()}
        >
          {isCartOpen ? <Close /> : <ShoppingCart />}
        </IconButton>
      </Stack>
      <Grid container>
        <Grid item md={isCartOpen ? 8 : 12} xl={isCartOpen ? 9 : 12} sm={isCartOpen ? 6 : 12} xs={isCartOpen ? 0 : 12}
          display={{ xs: isCartOpen ? "none" : "block", sm: "block" }}>
          <Container style={{ marginTop: 25 }} maxWidth={false}>
            <Grid container spacing={2}>
              {addOns.map((addOn: any) => (
                <Grid item xl={isCartOpen ? 4 : 3} md={isCartOpen ? 6 : 4} sm={isCartOpen ? 12 : 6} xs={12} key={addOn.id}>
                  <AddOnItem addOn={addOn} addItem={addItemToCart} />
                </Grid>
              ))}
            </Grid>
          </Container>
        </Grid>
        {isCartOpen && ( // Conditionally render sidebar
          <Grid item md={4} xl={3} sm={6} xs={12}>
            <AddOnCart />
          </Grid>
        )}
      </Grid>
    </Page>
  );
};

export default AddOnList;
