import React, { useContext } from 'react';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import CircularProgress from '../../../../components/facebook_progress/FacebookProgress';
import FormInput from '../../../../components/form/form_input/FormInput';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SnackbarContext } from 'components/common_snackbar/CommonSnackBar';
import { TicketFormData } from "../../../../../src/utils/interfaces";
import Grid from '@mui/material/Grid'; 

export interface TicketFormProps {
  onSubmit: (data: TicketFormData) => void;
  loading: boolean;
  initialValues?: TicketFormData;
}

const TicketForm: React.FC<TicketFormProps> = ({ onSubmit, loading, initialValues }) => {
  const { setSnack } = useContext(SnackbarContext);
  const schema = yup.object().shape({
    company: yup.string().required('Company is required'),
    description: yup.string().required('Description is required'),
    location: yup.string().required('Location is required'),
    code: yup.string().required('Code is required'),
    companyId: yup.string().required('Company ID is required'),
    reporterId: yup.string().required('Reporter ID is required'),
    customerId: yup.string().required('Customer ID is required'),
    subject: yup.string().required('Subject is required'),
  });

  const { handleSubmit, control, reset: resetForm, formState: { errors } } = useForm<TicketFormData>({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  });

  const handleFormSubmit = async (data: TicketFormData) => {
    try {
      await onSubmit(data);
      resetForm();
      setSnack({
        message: initialValues ? 'Ticket updated successfully' : 'Ticket created successfully',
        severity: 'success',
        open: true,
      });
    } catch (error) {
      console.error(error);
      setSnack({
        message: initialValues ? 'Update ticket failed' : 'Create ticket failed',
        severity: 'error',
        open: true,
      });
    }
  };

  return (
    <>
      <CardContent>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <Grid container spacing={2}> 
            <Grid item xs={12} md={6}> 
              <FormInput control={control} type="text" name="company" label="Company*" value={initialValues?.company} error={errors?.company} />
            </Grid>
            <Grid item xs={12} md={6}> 
              <FormInput control={control} type="text" name="location" label="Location*" value={initialValues?.location} error={errors?.location} />
            </Grid>
            <Grid item xs={12} md={6}> 
              <FormInput control={control} type="text" name="code" label="Code*" value={initialValues?.code} error={errors?.code} />
            </Grid>
            <Grid item xs={12} md={6}> 
              <FormInput control={control} type="text" name="companyId" label="Company ID*" error={errors?.companyId} />
            </Grid>
            <Grid item xs={12} md={6}> 
              <FormInput control={control} type="text" name="reporterId" label="Reporter ID*" error={errors?.reporterId} />
            </Grid>
            <Grid item xs={12} md={6}> 
              <FormInput control={control} type="text" name="customerId" label="Customer ID*" error={errors?.customerId} />
            </Grid>
            <Grid item xs={12} md={6}> 
              <FormInput control={control} type="text" name="subject" label="Subject*" value={initialValues?.subject} error={errors?.subject} />
            </Grid>
            <Grid item xs={12} md={6}> 
              <FormInput control={control} type="text" name="description" label="Description*" value={initialValues?.description} error={errors?.description} />
            </Grid>
          </Grid>
          <Divider />
          <CardActions>
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              fullWidth
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : initialValues ? 'Update Ticket' : 'Create Ticket'}
            </Button>
          </CardActions>
        </form>
      </CardContent>
    </>
  );
};

export default TicketForm;