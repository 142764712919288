import { Theme, createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            [theme.breakpoints.up("md")]: {
                width: "60%"
            }
        },

        feedback: {
            marginTop: theme.spacing(2),
            textTransform: "capitalize"
        },
        title: {
            fontSize: 14
        },
        error: {
            textTransform: "capitalize"
        },
        button: {
            color: "white"
        },
        buttonWrapper: {
            position: "relative"
        },
        buttonProgress: {
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: -9,
            marginLeft: -12
        },
        option: {
            fontSize: 15,
            "& > span": {
                marginRight: 10,
                fontSize: 18
            }
        },
        chakBoxGroup: {
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
            borderRadius: 5
        },
        chakBoxGroupEmpty: {
            backgroundColor: 'rgba(0, 0, 0, 0)',
            borderRadius: 5
        },
        chakBoxGroupTitle: {
            fontWeight: 600,
            marginLeft: 15,
            marginTop: 15,
            marginBottom: 10
        },
        chip: {
            margin: 5,
            backgroundColor: 'red'
        },
    })
);
