import { useEffect } from "react";
import { format } from "ts-date/esm/locale/en";
// import { reasons, types } from "../../../utils/CompanyTypes";
import { Button } from "@material-ui/core";
import {
  AppointmentsPlaceHolders,
  AppointmentStatus,
} from "../searchbar/Searchbar";
import { useMutation } from "@apollo/client";
import { GENERATE_JAAS_TOKEN } from "adapters/mutations/GenerateToken";
import { CREATE_SESSION } from "adapters/mutations/CreateSession";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";
import { useHistory } from "react-router-dom";
import { callStatus } from "utils/constants";
import { TableRow, TableCell, Hidden, Grid, Typography } from "@mui/material";

export default function AppointmentItem(props: any) {
  const { user } = useSelector((state: RootState) => state.auth);
  const history = useHistory();

  const [mutate, { error, data }] = useMutation(GENERATE_JAAS_TOKEN, {
    onCompleted: () => { },
    onError: () => {
      console.log(error?.message);
    },
  });

  const [createSession, { error: err }] = useMutation(CREATE_SESSION, {
    onCompleted: () => { },
    onError: () => {
      console.log(err?.message);
    },
  });

  useEffect(() => {
    mutate({
      variables: {
        input: {
          id: user?.id,
          name: user?.firstName + " " + user?.lastName,
          email: user?.email,
          moderator: "true",
          nbf: Math.round(
            new Date(props?.appointment?.startDate).setHours(
              new Date(props?.appointment?.startDate).getHours() - 12
            ) / 1000
          ),
          exp: Math.round(
            new Date(props?.appointment?.startDate).setHours(
              new Date(props?.appointment?.startDate).getHours() + 12
            ) / 1000
          ),
        },
      },
    });
  }, []);

  const r = props?.appointment?.reason!
    ? props?.appointment?.reason!
    : "Not Available";

  const str1 = new Date(props?.appointment?.startDate!);
  let dt: any;
  dt = "";
  if (str1) {
    dt = format(new Date(str1), "DD MMM, YYYY - hh:mm A");
  }

  const isExpired = () => {
    var d1 = new Date();
    var d2 = new Date(props?.appointment?.startDate);
    return d1.getTime() > d2.getTime() + 600000;
  };

  const handleClick = (event: any) => {
    event.preventDefault();
    history.push(
      `/meeting/${process.env.REACT_APP_JAAS_APP_ID}/${props.appointment?.id}/${data?.generateJaasToken?.token}`
    );

    createSession({
      variables: {
        input: {
          chatRoomId: `${process.env.REACT_APP_JAAS_APP_ID}/${props.appointment?.id}`,
          companyId: user?.company?.id,
          customerId: props.appointment?.customer.id,
          userId: user?.id,
          status: callStatus.ANSWERED,
        },
      },
    });
  };

  const showJoinButton = () => {
    if (user?.role == "agent" || user?.role == "manager" || user?.role == "owner") {
      return (
        props.appointment?.status == AppointmentStatus.CONFIRMED &&
        user?.id === props.appointment?.assignee?.id &&
        !isExpired()
      );
    } else {
      return false;
    }
  };

  const checkJoinENabled = () => {
    const currentTime = new Date().getTime();
    const appointmentTime = new Date(props.appointment.startDate).getTime();
    if (
      appointmentTime - 600000 < currentTime &&
      currentTime < appointmentTime + 7200000
    ) {
      return true;
    }
    return false;
  };

  return (
    <TableRow hover>
      {/* Desktop view-set */}
      <Hidden smDown>
        <TableCell>
          {props?.appointment?.customer?.firstName!}
          {props?.appointment?.customer?.lastName!
            ? props?.appointment?.customer?.lastName! ===
              props?.appointment?.customer?.firstName!
              ? " "
              : " " + props?.appointment?.customer?.lastName!
            : ""}
          <br />
          {props?.appointment?.customer?.email!}
          <br />
          {props?.appointment?.customer?.phone!}
        </TableCell>
        <TableCell>
          {isExpired() ? "Expired" : AppointmentsPlaceHolders(props?.request?.status)}
        </TableCell>
        <TableCell>{r}</TableCell>
        <TableCell>{dt}</TableCell>
        <TableCell>
          {props?.appointment?.assignee?.firstName! +
            " " +
            props?.appointment?.assignee?.lastName!}
          <br />
          {props?.appointment?.assignee?.email!}
        </TableCell>
        <TableCell>
          {showJoinButton() && (
            <Button
              variant="contained"
              size="small"
              color="primary"
              disabled={!checkJoinENabled()}
              onClick={handleClick}
            >
              join
            </Button>
          )}

        </TableCell>
      </Hidden>

      {/* Mobile view-set */}
      <Hidden mdUp>
        <Grid
          container
          spacing={1}
          padding={1}
          style={{ position: "relative" }}
        >
          <Grid item xs={12}>
            <Typography variant="body2">
              {props?.appointment?.customer?.firstName!}
              {props?.appointment?.customer?.lastName!
                ? props?.appointment?.customer?.lastName! ===
                  props?.appointment?.customer?.firstName!
                  ? " "
                  : " " + props?.appointment?.customer?.lastName!
                : ""}
              <br />
              {props?.appointment?.customer?.email!}
              <br />
              {props?.appointment?.customer?.phone!}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              {isExpired() ? "Expired" : AppointmentsPlaceHolders(props?.request?.status)}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">{r}</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">{dt}</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">
              {props?.appointment?.assignee?.firstName! +
                " " +
                props?.appointment?.assignee?.lastName!}
              <br />
              {props?.appointment?.assignee?.email!}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            container
            justifyContent="space-between"
            alignItems="center"
            style={{ marginTop: "16px" }}
          >
            {showJoinButton() && (
              <Button
                variant="contained"
                size="small"
                color="primary"
                disabled={!checkJoinENabled()}
                onClick={handleClick}
              >
                join
              </Button>
            )}
          </Grid>
        </Grid>
      </Hidden>
    </TableRow>
  );
}
