import { format } from "ts-date/esm/locale/en";
import {
  Avatar,
  Grid,
  Hidden,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { getInitials } from "utils/get-initials";
import { useHistory } from "react-router-dom";
import { Call, CalendarMonth, PhoneCallback } from '@mui/icons-material';

export default function CompanyItem(props: any) {
  const history = useHistory();

  const { firstName, lastName, email, createdAt, id } =
    props.customer;

  return (
    <TableRow hover key={id}>
      <Hidden smDown>
        <TableCell>
          <Stack alignItems="center" direction="row" spacing={2}>
            <Avatar>{getInitials(firstName + " " + lastName)}</Avatar>
            <Typography variant="subtitle2">
              {firstName + " " + lastName}
            </Typography>
          </Stack>
        </TableCell>

        <TableCell>{email}</TableCell>
        <TableCell>
          {format(new Date(createdAt!), "DD MMM, YYYY hh:mm a")}
        </TableCell>
        <TableCell>

          <IconButton
            onClick={() => history.push(props?.role == "admin" ? `/admin/agent-sessions/${email}` : `/agent-sessions/${email}`)}
            aria-label="assign"
            aria-haspopup="true"
          >
            <Call color="primary" />
          </IconButton>
        </TableCell>
        <TableCell>
          <IconButton
            onClick={() => history.push(props?.role == "admin" ? `/appointments/${email}` : `/appointments/${email}`)}
            aria-label="assign"
            aria-haspopup="true"
          >
            <CalendarMonth color="primary" />
          </IconButton>
        </TableCell>
        <TableCell>
          <IconButton
            onClick={() => history.push(props?.role == "admin" ? `/admin/callbacks/${email}` : `/callbacks/${email}`)}
            aria-label="assign"
            aria-haspopup="true"
          >
            <PhoneCallback color="primary" />
          </IconButton>
        </TableCell>
      </Hidden>
      <Hidden smUp>
        <TableCell>
          <Grid container item direction="row" spacing={2}>
            <Grid item md={2} sm={5} xs={11}>
              <Stack alignItems="center" direction="row" spacing={2}>
                <Avatar>{getInitials(firstName + " " + lastName)}</Avatar>
                <Typography variant="subtitle2">
                  {firstName} {lastName}
                </Typography>
              </Stack>
            </Grid>

            <Grid item md={3} sm={5} xs={11}>
              <Typography variant="body2">{email}</Typography>
            </Grid>

            <Grid item md={2} sm={5} xs={11}>
              <Typography variant="body2">
                {format(new Date(createdAt!), "DD MMM, YYYY hh:mm a")}
              </Typography>
            </Grid>

            <Grid item md={2} sm={6} xs={11} justifyContent={'space-between'} display={'flex'}>

              <IconButton
                onClick={() => history.push(props?.role == "admin" ? `/admin/agent-sessions/${email}` : `/agent-sessions/${email}`)}
                aria-label="assign"
                aria-haspopup="true"
              >
                <Call color="primary" />
              </IconButton>
              <IconButton
                onClick={() => history.push(props?.role == "admin" ? `/appointments/${email}` : `/appointments/${email}`)}
                aria-label="assign"
                aria-haspopup="true"
              >
                <CalendarMonth color="primary" />
              </IconButton>

              <IconButton
                onClick={() => history.push(props?.role == "admin" ? `/admin/callbacks/${email}` : `/callbacks/${email}`)}
                aria-label="assign"
                aria-haspopup="true"
              >
                <PhoneCallback color="primary" />
              </IconButton>
            </Grid>
          </Grid>
        </TableCell>
      </Hidden>
    </TableRow>
  );
}
