import { gql } from "@apollo/client";

export const MODAL_STATUS_ADMIN_COMPANY = gql`
    mutation ModalStatusAdminCompanyMutation(
        $input: UpdateCompanyInput!
    ) {
        updateCompany(input: $input) {
            companyEdge {
                node {
                    id
                }
            }
        }
    }
`;
