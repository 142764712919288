import { Route } from "react-router-dom";
import BusinessAgentLayout from "../components/layout/business_agent";
import Appointments from "../components/appointments/list/List";
import Callbacks from "../components/callbacks/list/List";
import AuditList from "../domain/business_agent/audit/list/List";
import AuthLog from "../domain/business_agent/auth_log/list/List";
import CallList from "components/calls/list/List";
import BotSessionsList from "components/bot_sessions/list/List";
import Dashboard from "../domain/business_agent/dashboard/Dashboard";
import EditUser from "components/user/edit/Edit";
import UserList from "components/user/list/List";
import AddUser from "components/user/add/Add";
import BAConference from "components/conference/business_agent/BA-Console";
import ChangePassword from "components/user/reset_password/ResetPassword";

function BusinessAgentRoutes() {
  return (
    <>
      <BusinessAgentLayout>
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/audit" component={AuditList} />
        <Route exact path="/authlogs" component={AuthLog} />
        <Route exact path="/appointments" component={Appointments} />
        <Route exact path="/callbacks" component={Callbacks} />
        <Route exact path="/users" component={UserList} />
        <Route exact path="/users/add" component={AddUser} />
        <Route exact path="/users/edit/:id" component={EditUser} />
        <Route exact path="/users/edit/:id/:mode" component={EditUser} />
        <Route exact path="/agent-sessions" component={CallList} />
        <Route exact path="/call/:id" component={CallList} />
        <Route path="/bot-sessions" exact component={BotSessionsList} />
        <Route path="/bot-sessions/:id" exact component={BotSessionsList} />
        <Route
          exact
          path="/users/password/change/:id"
          component={ChangePassword}
        />
        <Route exact path="/console" component={BAConference} />
      </BusinessAgentLayout>
    </>
  );
}
export default BusinessAgentRoutes;
