import {
  CardActions,
  CardContent,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import FormFeedback from "../../../../components/form_feedback/FormFeedback";
import FormInput from "../../../../components/form/form_input/FormInput";
import FormButton from "@material-ui/core/Button";
import { useStyles } from "./style";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import Switch from "@material-ui/core/Switch";
import { EDIT_BUSINESS_ADMIN_CALL_FOR_HELP } from "adapters/mutations/EditBusinessAdminCallForHelp";
import { CALL_FOR_HELP_REFETCH } from "adapters/queries/CallForHelpRefetch";
import { SnackbarContext } from "components/common_snackbar/CommonSnackBar";
import { Chip } from "@mui/material";
import { Close } from '@mui/icons-material'
import { CALL_FOR_HELP_BUSINESS_ADMIN_EDIT } from "adapters/queries/CallForHelpBusinessAdminEdit";

const priorityOptions = [
  { title: "Low", value: 0 },
  { title: "Medium", value: 1 },
  { title: "High", value: 2 },
];

const re = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm


const schema = yup.object().shape({
  name: yup.string().required(),
  domain: yup.string().matches(re, { message: "Please add valid domain" }).required(),
  description: yup.string().required(),
});


export default function EditForm({ botProfile }: any) {
  const classes = useStyles();
  const history = useHistory();
  const { setSnack } = useContext(SnackbarContext);
  const active: number = 2;

  const [isScreenSharingRequired, setIsScreenSharingRequired] = useState(
    botProfile?.isScreenSharingRequired
  );
  const [isWebRTCVideoRequired, setIsWebRTCVideoRequired] = useState(
    botProfile?.isWebRTCVideoRequired
  );
  const mode = botProfile?.status === active ? true : false;
  const [status, setStatus] = useState(mode);
  const [callRecording, setCallRecording] = useState(
    botProfile?.callRecording
  );
  const [isForwardToPhoneRequired, setIsForwardToPhoneRequired] = useState(
    botProfile?.isForwardToPhoneRequired
  );
  const [isBotInterectionsOn, setIsBotInterectionsOn] = useState(botProfile.isBotInterectionsOn);
  const [isWhatsappRequired, setIsWhatsappRequired] = useState(botProfile.isWhatsappRequired);
  const [isChannelSwitchingOn, setIsChannelSwitchingOn] = useState(botProfile.isChannelSwitchingOn);
  const [isAgentHandOverRequired, setIsAgentHandOverRequired] = useState(botProfile.isAgentRequired);
  const [isAppointmentsOn, setIsAppointmentsOn] = useState(botProfile.isAppointment);
  const [isCallbackOn, setIsCallbackOn] = useState(botProfile.isCallbackOn);
  const [isRaiseTicketRequired, setIsRaiseTicketRequired] = useState(botProfile.isRaiseTicketRequired);

  const [languageOptions, setLanguageOptions] = useState<Array<{ title: string, value: string }>>([
    { "title": "English", "value": "en" },
    { "title": "Spanish", "value": "es" },
    { "title": "French", "value": "fr" }])

  const [selectedLanguage, setSelectedLanguage] = useState<Array<{ title: string, value: string }>>([])

  const defaultValues = {
    ...botProfile,
  };


  const [mutate, { loading, error }] = useMutation(
    EDIT_BUSINESS_ADMIN_CALL_FOR_HELP,
    {
      onCompleted: () => {
        history.push("/bot-profile");
      },
      onError: () => {
        console.log(error);
        setSnack({ message: "Editing Bot Profile failed", severity: 'error', open: true });
      },
    }
  );

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });


  useEffect(() => {
    if (botProfile.languages && botProfile.primaryLanguage) {
      setSelectedLanguage(JSON.parse(botProfile.languages))
      setLanguageOptions([...languageOptions.filter(lOpt => !(JSON.parse(botProfile.languages).find((botLanguage: { value: string; }) => botLanguage.value == lOpt.value)))])
    }
  }, [botProfile.primaryLanguage])

  const languageChange: Array<string> = watch(["languages"]);

  useEffect(() => {
    if (languageChange[0]) {
      const newLang = languageOptions.find(opt => opt.value == languageChange[0])

      if (newLang) {
        setSelectedLanguage([...selectedLanguage, newLang])
        setLanguageOptions(languageOptions.filter(opt => opt.value != languageChange[0]))
      }
      setValue("languages", "")
    }

  }, [languageChange]);

  const onSubmit = ({
    name,
    description,
    domain,
    priority,
    forwardNumber,
    forwardMessage
  }: any) => {
    mutate({
      refetchQueries: [{ query: CALL_FOR_HELP_REFETCH }, { query: CALL_FOR_HELP_BUSINESS_ADMIN_EDIT, variables: { id: botProfile.id } }],
      variables: {
        input: {
          id: botProfile.id,
          name: name,
          description: description,
          domain: domain,
          languages: JSON.stringify(selectedLanguage),
          priority: priority! || 0,
          isWebRTCVideoRequired: isWebRTCVideoRequired! || false,
          isScreenSharingRequired: isScreenSharingRequired! || false,
          isForwardToPhoneRequired: isForwardToPhoneRequired! || false,
          isBotInterectionsOn: isBotInterectionsOn! || false,
          isWhatsappRequired: isWhatsappRequired! || false,
          isChannelSwitchingOn: isChannelSwitchingOn! || false,
          isAgentRequired: isAgentHandOverRequired! || false,
          isAppointment: isAppointmentsOn! || false,
          isCallbackOn: isCallbackOn! || false,
          isRaiseTicketRequired: isRaiseTicketRequired! || false,
          forwardNumber: forwardNumber!,
          forwardMessage: forwardMessage!,
          status: status ? 2 : 1,
          callRecording: callRecording! || false,
        },
      },
    });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardContent>
          <FormInput
            control={control}
            type="text"
            name="name"
            label="Name*"
            defaultValue={botProfile?.name}
            error={errors && errors.name}
          />
          <br />
          <br />
          <FormInput
            control={control}
            type="text"
            name="description"
            label="Description"
            defaultValue={botProfile?.description}
            error={errors && errors.description}
          />
          <br />
          <br />
          <FormInput
            control={control}
            type="text"
            name="domain"
            label="Domain*"
            defaultValue={botProfile?.domain}
            error={errors && errors.domain}
          />
          <br />
          <br />
          <FormInput
            control={control}
            type="text"
            name="languages"
            label="Languages"
            placeholder="Please select one or more language"
            select="select"
          >
            {languageOptions?.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.title}
              </MenuItem>
            ))}
          </FormInput>
          <br />
          {selectedLanguage.length > 0 && <br />}
          <div>{selectedLanguage.map(selectedLang => <Chip label={selectedLang.title}
            variant='filled'
            key={selectedLang.value}
            color={'secondary'}
            onDelete={() => {
              const delLang = selectedLanguage.find(opt => opt.value == selectedLang.value)
              console.log(delLang);

              if (delLang) {
                console.log(selectedLanguage.filter(opt => opt.value != delLang.value));

                setSelectedLanguage(selectedLanguage.filter(opt => opt.value != delLang.value))
                setLanguageOptions([...languageOptions, delLang])
              }
            }}
            deleteIcon={defaultValues.primaryLanguage != selectedLang.value ? <Close /> : <></>}
            style={{ margin: 4, cursor: 'move' }}
            className={classes.chip} />)}</div>
          <br />

          <FormInput
            control={control}
            type="text"
            name="priority"
            label="Priority"
            select="select"
            defaultValue={botProfile?.priority}
          >
            {priorityOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.title}
              </MenuItem>
            ))}
          </FormInput>
          <br />
          <br />
          <Grid container spacing={3}>

            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={isCallbackOn}
                    onChange={(event: any) => {
                      setIsCallbackOn(event.target.checked);
                    }}
                    color="primary"
                    name="callRecording"
                    inputProps={{
                      "aria-label": "primary checkbox",
                    }}
                  />
                }
                label="Callback"
                labelPlacement="start"
              />
            </Grid>

            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={isAppointmentsOn}
                    onChange={(event: any) => {
                      setIsAppointmentsOn(event.target.checked);
                    }}
                    color="primary"
                    name="callRecording"
                    inputProps={{
                      "aria-label": "primary checkbox",
                    }}
                  />
                }
                label="Appointments"
                labelPlacement="start"
              />
            </Grid>

            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={status}
                    onChange={(event: any) => {
                      setStatus(event.target.checked);
                    }}
                    color="primary"
                    name="status"
                    inputProps={{
                      "aria-label": "primary checkbox",
                    }}
                  />
                }
                label="Status"
                labelPlacement="start"
              />
            </Grid>

            <Grid item md={6}>
              <FormControlLabel
                control={
                  <Switch
                    disabled
                    onChange={(event: any) => {
                      setIsForwardToPhoneRequired(event?.target?.checked!);
                    }}
                    color="primary"
                    name="isForwardToPhoneRequired"
                    inputProps={{
                      "aria-label": "primary checkbox",
                    }}
                  />
                }
                label="Forward To Phone"
                labelPlacement="start"
              />
            </Grid>

            <Grid item sm={12} md={6}>

              <Grid container className={isBotInterectionsOn ? classes.chakBoxGroup : classes.chakBoxGroupEmpty}>

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isBotInterectionsOn}
                        onChange={(event: any) => {
                          setIsBotInterectionsOn(event.target.checked);
                        }}
                        color="primary"
                        name="isBotInterectionsOn"
                        inputProps={{
                          "aria-label": "primary checkbox",
                        }}
                      />
                    }
                    label="Bot Q&A"
                    labelPlacement="start"
                  />
                </Grid>

                {isBotInterectionsOn && <Grid item xs={12}>
                  <Typography className={classes.chakBoxGroupTitle}>Channels:</Typography>
                </Grid>}

                {isBotInterectionsOn && <Grid item xs={12} md={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isWhatsappRequired}
                        onChange={(event: any) => {
                          setIsWhatsappRequired(event.target.checked);
                        }}
                        color="primary"
                        name="isWhatsappRequired"
                        inputProps={{
                          "aria-label": "primary checkbox",
                        }}
                      />
                    }
                    label="Whatsapp"
                    labelPlacement="start"
                  />
                </Grid>}
                {isBotInterectionsOn && <Grid item xs={12} md={12}>
                  <FormControlLabel
                    checked={isChannelSwitchingOn}
                    control={
                      <Switch
                        onChange={(event: any) => {
                          setIsChannelSwitchingOn(event.target.checked);
                        }}
                        color="primary"
                        name="isChannelSwitchingOn"
                        inputProps={{
                          "aria-label": "primary checkbox",
                        }}
                      />
                    }
                    label="Channel Switching"
                    labelPlacement="start"
                  />
                </Grid>}
              </Grid>

            </Grid >

            <Grid item sm={12} md={6}>

              <Grid container className={isAgentHandOverRequired ? classes.chakBoxGroup : classes.chakBoxGroupEmpty}>

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isAgentHandOverRequired}
                        onChange={(event: any) => {
                          setIsAgentHandOverRequired(event.target.checked);
                        }}
                        color="primary"
                        name="isAgentHandOverRequired"
                        inputProps={{
                          "aria-label": "primary checkbox",
                        }}
                      />
                    }
                    label="Agent Handover"
                    labelPlacement="start"
                  />
                </Grid>

                {isAgentHandOverRequired && <Grid item xs={12}>
                  <Typography className={classes.chakBoxGroupTitle}>Conference:</Typography>
                </Grid>}

                {isAgentHandOverRequired && <Grid item xs={12} md={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isWebRTCVideoRequired}
                        onChange={(event: any) => {
                          setIsWebRTCVideoRequired(event.target.checked);
                        }}
                        color="primary"
                        name="isWebRTCVideoRequired"
                        inputProps={{
                          "aria-label": "primary checkbox",
                        }}
                      />
                    }
                    label="Video Enabled"
                    labelPlacement="start"
                  />
                </Grid>}

                {isAgentHandOverRequired && <Grid item xs={12} md={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isScreenSharingRequired}
                        onChange={(event: any) => {
                          setIsScreenSharingRequired(event.target.checked);
                        }}
                        color="primary"
                        name="isScreenSharingRequired"
                        inputProps={{
                          "aria-label": "primary checkbox",
                        }}
                      />
                    }
                    label="Screen Sharing"
                    labelPlacement="start"
                  />
                </Grid>}

                {isAgentHandOverRequired && <Grid item xs={12} md={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={callRecording}
                        onChange={(event: any) => {
                          setCallRecording(event.target.checked);
                        }}
                        color="primary"
                        name="callRecording"
                        inputProps={{
                          "aria-label": "primary checkbox",
                        }}
                      />
                    }
                    label="Call Recording"
                    labelPlacement="start"
                  />
                </Grid>}
              </Grid>
            </Grid>

            {process.env.REACT_APP_IS_RAISE_TICKET_FEATURE_ON == "active" && <Grid item xs={12} md={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={isRaiseTicketRequired}
                    onChange={(event: any) => {
                      setIsRaiseTicketRequired(event.target.checked);
                    }}
                    color="primary"
                    name="isRaiseTicketRequired"
                    inputProps={{
                      "aria-label": "primary checkbox",
                    }}
                  />
                }
                label="Raise a Ticket"
                labelPlacement="start"
              />
            </Grid>}
          </Grid>
          {isForwardToPhoneRequired && (
            <>
              <FormInput
                control={control}
                type="text"
                name="forwardNumber"
                label="Forwarded Number"
                helperText="Calls will be forwarded to this number, charges apply"
                error={errors && errors.forwardNumber}
              />
              <br />
              <br />
              <FormInput
                control={control}
                type="text"
                name="forwardMessage"
                label="Forwarded Message"
                helperText="Message to be displayed to customer on forwaded call. Ex You're call is being forwaded to our IVR"
                error={errors && errors.forwardMessage}
              />
              <br />
            </>
          )}

          {error ? (
            <FormFeedback className={classes.feedback} error>
              {error.message}
            </FormFeedback>
          ) : null}
        </CardContent>
        <Divider />
        <CardActions>
          <div className={classes.buttonWrapper}>
            <FormButton
              className={classes.button}
              type="submit"
              color="secondary"
              variant="contained"
              fullWidth
            >
              Update Record
            </FormButton>
            {loading && <CircularProgress className={classes.buttonProgress} />}
          </div>
        </CardActions>
      </form>
    </div>
  );
}
