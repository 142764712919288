import { useMemo, useState } from "react";
import { useEffect } from "react";
import { useStyles } from "./style";
import Container from "@material-ui/core/Container";
import { useQuery } from "@apollo/client";
import { APPOINTMENT_BUSINESS_ADMIN_REFETCH } from "adapters/queries/AppointmentBusinessAdminRefetch";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";

import Page from "../../page/Page";
import Toolbar from "../toolbar/Toolbar";
import Searchbar, {
  AppointmentStatus,
  SearchQuery,
} from "../searchbar/Searchbar";
import AppointmentItem from "../list_item/ListItem";
import PendingItem from "../pendingItem/PendingItem";
import AssignedItem from "../assigned_item/AssignedItem";
import RejectedItem from "../rejected_item/RejectedItem";
import {
  Hidden,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card,
  Grid,
  TablePagination,
} from "@mui/material";

export function isNotNull<T>(it: T): it is NonNullable<T> {
  return it != null;
}

const Appointments = (props: any): JSX.Element => {
  const classes = useStyles();
  const { user } = useSelector((state: RootState) => state.auth);
  const [searchQuery, setSearchQuery] = useState<SearchQuery>({
    searchText: "",
    status: "",
  });
  const [appointmentStatus, setAppointmentStatus] = useState<string>("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const role = user?.role;
  let { customerId } = props.match.params;

  const { data: appointmentRefetch, fetchMore, refetch } = useQuery(
    APPOINTMENT_BUSINESS_ADMIN_REFETCH,
    {
      variables: {
        ...(searchQuery.status != " " && { status: searchQuery?.status }),
        ...(searchQuery.searchText != " " && {
          search: searchQuery?.searchText,
        }),
        count: rowsPerPage,
        ...(customerId && { customerId: customerId }),
      },
    }
  );

  useEffect(() => {
    fetchMore({
      variables: {
        ...(searchQuery.status != " " && { status: searchQuery?.status }),
        ...(searchQuery.searchText != " " && {
          search: searchQuery?.searchText,
        }),
        count: rowsPerPage,
        ...(customerId && { customerId: customerId }),
      },
    });
  }, [searchQuery, rowsPerPage]);

  useEffect(() => {
    document.title = "Appointments";
  }, []);

  const appointmentList = () => {
    const { appointments } = appointmentRefetch || {};
    return appointments;
  };

  const appointments = appointmentList();

  const totalCount = appointments?.totalCount || 0;

  const list = useMemo(() => {
    return appointments && appointments.edges
      ? appointments.edges
        .filter(isNotNull)
        .map((edge: any) => edge.node)
        .filter(isNotNull)
      : [];
  }, [appointments]);

  const refetchWithCursor = (change: string) => {
    change === "forward" && refetch({
      ...(searchQuery.status != " " && { status: searchQuery?.status }),
      ...(customerId && { customerId: customerId }),
      ...(searchQuery.searchText != " " && {
        search: searchQuery?.searchText,
        count: rowsPerPage,
        endcursor: appointments.pageInfo?.endCursor,
        reversecount: undefined,
        startcursor: "",
      }),
    });

    change === "backward" && refetch({
      ...(searchQuery.status != " " && { status: searchQuery?.status }),
      ...(customerId && { customerId: customerId }),
      ...(searchQuery.searchText != " " && {
        search: searchQuery?.searchText,
        count: undefined,
        endcursor: null,
        reversecount: rowsPerPage,
        startcursor: appointments.pageInfo?.startCursor,
      }),
    });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    if (newPage > page) {
      refetchWithCursor("forward");
    } else {
      refetchWithCursor("backward");
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Page className={classes.root} title="Appointments">
      <Container maxWidth={false}>
        <Toolbar title="Appointments" subTitle={searchQuery.status} />
        <Searchbar
          onSearch={setSearchQuery}
          role={role}
          appointmentStatus={appointmentStatus}
        />

        <Card sx={{ mt: 3 }}>
          <Table>
            <Hidden smDown>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Reason</TableCell>
                  <TableCell>Appointment Slot</TableCell>
                  <TableCell>Assigned To</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
            </Hidden>
            <TableBody>
              {list!.length ? (
                list!.map((node: any) => {
                  if (node == null) {
                    return null;
                  }
                  if (node?.status == AppointmentStatus.PENDING) {
                    return (
                      <PendingItem
                        request={node}
                        key={node.id}
                        getAssignedList={() => {
                          setAppointmentStatus(AppointmentStatus.ASSIGNED);
                        }}
                        getPendingList={() => {
                          setAppointmentStatus(AppointmentStatus.PENDING);
                        }}
                      />
                    );
                  }

                  if (node?.status == AppointmentStatus.ASSIGNED) {
                    return (
                      <AssignedItem
                        assigned={node}
                        key={node.id}
                        getAssignedList={() => {
                          setAppointmentStatus(AppointmentStatus.ASSIGNED);
                        }}
                        getRejectedList={() => {
                          setAppointmentStatus(AppointmentStatus.REJECTED);
                        }}
                      />
                    );
                  }

                  if (node?.status == AppointmentStatus.REJECTED) {
                    return <RejectedItem request={node} key={node.id} />;
                  }

                  return <AppointmentItem appointment={node} key={node.id} />;
                })
              ) : (
                <Grid
                  className={classes.gridEmptyRecords}
                  container
                  direction="column"
                  item
                  xs={12}
                >
                  No appointments found
                </Grid>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
};

export default Appointments;
