import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { LinearProgress, Typography } from '@mui/material';
import AudioPlayer from 'material-ui-audio-player';
import { useLazyQuery } from "@apollo/client";
import { GET_TWILLIO_RECORDING } from 'adapters/queries/GetTwillioRecording';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
export interface Node {
  title: string;
  response: string;
  questions: Array<string>;
  status: boolean,
  mainmenu: boolean
}

export interface EditNode extends Partial<Node> {
  id: string;
}

interface AudioDialogProps {
  open: boolean;
  onClose: (event?: any, reason?: "backdropClick" | "escapeKeyDown") => void;
  node: any;
}




const AudioDialog = (props: AudioDialogProps) => {
  const { open, onClose } = props
  // const classes = useStyles()
  const [loading, setLoading] = useState(false)

  const [getNote, { data }] = useLazyQuery(GET_TWILLIO_RECORDING, {
    variables: {
      input: {
        // callSid: 'CAcfad96bce5452de02fef1b030f617eb9',
        callSid: props.node.twilioCallSid,
      }
    },
  });

  useEffect(() => {
    if (open && props.node.twilioCallSid) {
      // if (open) {
      setLoading(true)
      getNote({
        variables: {
          input: {
            callSid: props.node.twilioCallSid,
            // callSid: 'CAcfad96bce5452de02fef1b030f617eb9',
          }
        },
        onCompleted: () => {
          setLoading(false)
        },
        onError: () => {
          setLoading(false)
        }
      })
    }
  }, [open])


  return (

    <BootstrapDialog
      maxWidth='md'
      fullWidth
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      scroll='paper'
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
        Call Recording
      </BootstrapDialogTitle>

      <DialogContent dividers style={{ padding: 50 }}>
        {props.node.twilioCallSid == null && <Typography textAlign={'center'}>No Recording found</Typography>}
        {loading == false && (data && data.getCallRecording ? <AudioPlayer
          width='100px'
          variation="primary"
          download={false}
          autoplay={false}
          loop={false}
          src={data?.getCallRecording?.url}
        /> : <Typography textAlign={'center'}>No Recording found</Typography>)}
      </DialogContent>
      {(loading) && <LinearProgress />}
      <DialogActions>
        <Button autoFocus onClick={onClose} disabled={loading}>
          Close
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}

export default AudioDialog