import clsx from "clsx";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CardHeader from "@material-ui/core/CardHeader";

import ThumbsUpDownIcon from "@material-ui/icons/ThumbsUpDown";
import { useStyles } from "./style";

interface IProps {
  className?: string;
}

const Toolbar = ({ className, ...rest }: IProps) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <CardHeader
        title="Businesses"
        className={classes.cardHeader}
        titleTypographyProps={{
          className: classes.cardTitle,
        }}
        action={
          <>
            <Button
              color="primary"
              variant="contained"
              size="small"
              startIcon={<ThumbsUpDownIcon />}
              component={Link}
              to="/admin/businesses/onboard"
            >
              OnBoard
            </Button>
            &nbsp;
          </>
        }
      />
    </div>
  );
};

export default Toolbar;
