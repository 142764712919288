import { useEffect } from "react";

import Page from "../../components/page/Page";
import Calls from "../../components/calls_graph/Calls";
import { Container } from "@mui/material";

function Dashboard() {
  useEffect(() => {
    document.title = "Dashboard";
  }, []);

  return (
    <Page title="Dashboard">
      <Container maxWidth={"xl"}>
        <Calls />
      </Container>
    </Page>
  );
}

export default Dashboard;
