import { gql } from "@apollo/client";
import { LIST_ADMIN_PAYMENTS } from "../fragments/ListAdminPayments";

export const PAYMENT_ADMIN_REFETCH = gql`
    ${LIST_ADMIN_PAYMENTS}
    query PaymentAdminFragmentRefetchQuery(
        $count: Int
        $reversecount: Int
        $startcursor: String
        $endcursor: String
        $companyId: String
    ) {
        ...ListAdmin_payments_3J7gr0
    }
`;