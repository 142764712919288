import { useEffect } from "react";
import Container from "@material-ui/core/Container";
import Page from "../../../components/page/Page";
import Calls from "../../../components/calls_graph/Calls";

function Dashboard() {
  useEffect(() => {
    document.title = "Dashboard";
  }, []);

  return (
    <Page title="Dashboard">
      <Container maxWidth={false}>
        <Calls />
      </Container>
    </Page>
  );
}

export default Dashboard;
