import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import BookIcon from '@material-ui/icons/Book';
import { useStyles } from './style';

interface IProps {
  className?: string;
}

const Toolbar = ({ className, ...rest }: IProps) => {
  const classes = useStyles();
  const history = useHistory();

  const handleClick = () => {
    history.push('/authlogs');
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <CardHeader
        title="Audit Log"
        className={classes.cardHeader}
        titleTypographyProps={{
          className: classes.cardTitle,
        }}
        action={
          <Tooltip title="Auth Log">
            <IconButton aria-label="Authlogs" onClick={handleClick}>
              <BookIcon />
            </IconButton>
          </Tooltip>
        }
      />
    </div>
  );
};

export default Toolbar;
