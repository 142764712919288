// @ts-nocheck
import { useEffect } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Page from "../../../components/page/Page";
import Payments from "./Payments";
import { DASHBOARD_PAYMENTS_ADMIN } from "../../../adapters/queries/DashboardPaymentsAdmin";
import { useQuery } from "@apollo/client";
import PieReport from "./PieReport";
import Accounts from "./Accounts";
import Calls from "../../../components/calls_graph/Calls";
import { useStyles } from "./style";
import Budget from "./Budget";
import { Typography } from "@material-ui/core";

function Dashboard() {
  useEffect(() => {
    document.title = "Dashboard";
  }, []);
  const { loading, error, data } = useQuery(DASHBOARD_PAYMENTS_ADMIN);

  const classes = useStyles();

  if (loading) return <Typography>Loading...</Typography>;
  //if (error) return <Redirect to="/login" />;
  if (error) console.log(`Error! ${error.message}`);

  const paymentStatus = data;
  const dailyPayments = paymentStatus?.newPaymentDailyStats?.data!;
  const monthlyPayments = paymentStatus?.paymentMonthlyStats?.data!;

  const dailyAccounts = paymentStatus?.companyDailyStats?.data!;
  const monthlyAccounts = paymentStatus?.companyMonthlyStats?.data!;

  const dailyCalls = paymentStatus?.sessionDailyStats?.data!;
  const monthlyCalls = paymentStatus?.sessionMonthlyStats?.data!;

  return (
    <Page className={classes.root} title="Dashboard">
      <Container className={classes.container} maxWidth={false}>
        <Grid container spacing={2}>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <Budget />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <Budget />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <Budget />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <Budget />
          </Grid>
        </Grid>
        <Grid container className={classes.root} spacing={2}>
          <Grid item md={8} sm={12}>
            <Payments payments={dailyPayments} />
          </Grid>
          <Grid item md={4} sm={12}>
            <PieReport title="Payments" items={monthlyPayments} />
          </Grid>
        </Grid>
        <Grid container className={classes.root} spacing={2}>
          <Grid item md={8} sm={12}>
            <Accounts accounts={dailyAccounts} />
          </Grid>
          <Grid item md={4} sm={12}>
            <PieReport title="Businesses" items={monthlyAccounts} />
          </Grid>
        </Grid>
        <Grid container className={classes.root} spacing={2}>
          <Grid item md={8} sm={12}>
            <Calls calls={dailyCalls} />
          </Grid>
          <Grid item md={4} sm={12}>
            <PieReport title="Calls" items={monthlyCalls} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

export default Dashboard;
