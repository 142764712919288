import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
// import Typography from '@mui/material/Typography';
import FormInput from "../../form/form_input/FormInput";
import * as yup from "yup";
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, CircularProgress, LinearProgress, Stack, Typography } from '@mui/material';
import { useStyles } from './style';
import { PlusOneOutlined } from '@mui/icons-material';
import { Chip as FAQChip, TranslatedText } from '../faq_nodes'
import { ApolloError, useQuery } from '@apollo/client';
import { GET_TRANSLATED_FAQ } from 'adapters/queries/TranslatedFAQNode';


export interface TranslatedNode {
    displayName: string;
    responses: string;
    questions: Array<string>;
    translationCode: string
}

export interface TranslatedEditNode extends Partial<TranslatedNode> {
    id: string;
}

interface AddTranslatedNodeDialogProps {
    open: boolean;
    onClose: (event?: any, reason?: "backdropClick" | "escapeKeyDown") => void;
    onSave: (node: TranslatedNode) => void;
    edit: FAQChip | null;
    onSaveEdit?: (node: TranslatedEditNode) => void;
    getTranslation: (languageCode: string) => Promise<TranslatedText>;
    translationCode: string;
    loading?: boolean;
    error?: ApolloError;
}


const schema = yup.object().shape({
    displayName: yup.string().required(),
    questions: yup.array().of(yup.string().required()),
    responses: yup.array().of(yup.string().required()),
});



const AddTranslatedNodeDialog = (props: AddTranslatedNodeDialogProps) => {
    const { onSave, onSaveEdit, translationCode } = props
    const [isLoading, setIsLoading] = useState(true)
    const [defaultValues, setDefaultValue] = useState({
        displayName: props?.edit?.displayName ? props?.edit?.displayName : '',
        questions: props?.edit?.questions ? JSON.parse(props?.edit.questions) : [],
        responses: props?.edit?.responses ? JSON.parse(props?.edit.responses) : [],
    })
    const classes = useStyles()


    const { data: faq, error: errorFAQ, loading: loadingFAQ } = useQuery(GET_TRANSLATED_FAQ, {
        variables: {
            "input": {
                "id": props?.edit?.id,
                "translationCode": translationCode
            }
        },
        fetchPolicy: 'network-only'
    });


    useEffect(() => {
        const { translatedFaqNodeByRefrenceId } = faq || {};
        if (translatedFaqNodeByRefrenceId) {
            setDefaultValue({
                displayName: translatedFaqNodeByRefrenceId.displayName ? translatedFaqNodeByRefrenceId.displayName : "",
                questions: translatedFaqNodeByRefrenceId.questions ? JSON.parse(translatedFaqNodeByRefrenceId.questions) : [""],
                responses: translatedFaqNodeByRefrenceId.responses ? JSON.parse(translatedFaqNodeByRefrenceId.responses) : [""],
            })
            setIsLoading(false)
            setValue('displayName', translatedFaqNodeByRefrenceId.displayName ? translatedFaqNodeByRefrenceId.displayName : "")
            reset({
                ...getValues(),
                questions: translatedFaqNodeByRefrenceId.questions ? JSON.parse(translatedFaqNodeByRefrenceId.questions) : [""],
                responses: translatedFaqNodeByRefrenceId.responses ? JSON.parse(translatedFaqNodeByRefrenceId.responses) : [""]
            })
        }

        if (errorFAQ) {
            setIsLoading(false)
        }
    }, [faq, errorFAQ]);

    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        reset,
        getValues
    } = useForm({
        defaultValues,
        resolver: yupResolver(schema),
        shouldUnregister: false,
    });

    const onSubmit = ({
        displayName,
        questions,
        responses,
    }: TranslatedNode) => {
        console.log(displayName, questions, responses);

        if (props.edit) {
            onSaveEdit && onSaveEdit({ id: props.edit.id, displayName, responses: JSON.stringify(responses), questions, translationCode })
        } else {
            onSave({ displayName, responses: JSON.stringify(responses), questions, translationCode })
        }
    };

    //to handle the multiple question in form
    const { fields, append, remove, insert } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        //@ts-ignore
        name: 'questions', // unique name for your Field Array,
    });

    //to handle the multiple responses in form
    const { fields: responsesFields, append: appendResponse, remove: removeResponse, update: insertResponse, } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        //@ts-ignore
        name: 'responses', // unique name for your Field Array,
    });

    const addQuestion = () => {
        append("")
    }

    const addResponse = () => {
        appendResponse("")
    }

    useEffect(() => {
        if (!props.edit && fields.length == 0) {
            insert(0, "")
        }
        if (!props.edit && responsesFields.length == 0) {
            insertResponse(0, "")
            console.log("insertResponse");

        }
    }, [])

    const removeQuestion = (index: number) => {
        if (fields.length != 1) {
            remove(index)
        }
    }

    const removeOneResponse = (index: number) => {
        if (responsesFields.length != 1) {
            removeResponse(index)
        }
    }

    if (isLoading || loadingFAQ) {
        return <Stack direction={'column'} justifyContent={'center'} alignItems={'center'} style={{
            minHeight: 450,

        }}>
            <CircularProgress />
            <br />
            <Typography>Loading....</Typography>
        </Stack>
    }

    // if (errorFAQ) {
    //     return <Stack direction={'column'} justifyContent={'center'} alignItems={'center'} style={{
    //         minHeight: 450,

    //     }}>
    //         <Typography color={'red'}>{errorFAQ.message}</Typography>
    //     </Stack>
    // }

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
        }}>
            {<DialogContent dividers>
                <Stack spacing={4} paddingLeft={10} paddingRight={10}>

                    <FormInput
                        control={control}
                        defaultValue={defaultValues.displayName}
                        type="text"
                        name="displayName"
                        label="Display Name"
                        error={errors && errors.displayName}
                    />

                    <Stack spacing={2}>
                        <Typography variant='h6'>Questions</Typography>

                        {fields.map((item: any, index: number) =>

                            <Stack display={'flex'} flexDirection={'row'} alignItems={'center'} key={item.id}>

                                <FormInput
                                    vertical={true}
                                    className={classes.textField}
                                    defaultValue={defaultValues.questions[index]}
                                    control={control}
                                    type="text"
                                    name={`questions[${index}]`}
                                    label={`Question ${index + 1}`}
                                    error={errors && errors.questions && errors.questions[index]}
                                />

                                {fields.length > 1 && <IconButton color='primary' onClick={() => removeQuestion(index)}>
                                    <CloseIcon />
                                </IconButton>}

                            </Stack>)
                        }

                        <Box className={classes.buttonContainer}>
                            <Button variant="contained" startIcon={<PlusOneOutlined />} onClick={() => {
                                addQuestion()
                            }}>
                                Add
                            </Button>
                        </Box>

                    </Stack>

                    <Stack spacing={2}>
                        <Typography variant='h6'>Responses</Typography>
                        <Typography variant='body2'>Note: Add multiple responses to send it as separate messages to end user.</Typography>

                        {responsesFields.map((item: any, index: number) =>

                            <Stack display={'flex'} flexDirection={'row'} alignItems={'center'} key={item.id}>

                                <FormInput
                                    vertical={true}
                                    className={classes.textField}
                                    defaultValue={defaultValues.responses[index]}
                                    control={control}
                                    type="text"
                                    name={`responses[${index}]`}
                                    label={`Response ${index + 1}`}
                                    error={errors && errors.responses && errors.responses[index]}
                                />

                                {responsesFields.length > 1 && <IconButton color='primary' onClick={() => removeOneResponse(index)}>
                                    <CloseIcon />
                                </IconButton>}

                            </Stack>)
                        }

                        <Box className={classes.buttonContainer}>
                            <Button variant="contained" startIcon={<PlusOneOutlined />} onClick={() => {
                                addResponse()
                            }}>
                                Add
                            </Button>
                        </Box>

                    </Stack>

                    {props.error && <Typography variant='subtitle2' color={'red'}>{props.error.message}</Typography>}

                </Stack>
            </DialogContent>}
            {props.loading && <LinearProgress />}
            <DialogActions>
                {props.edit && <Button autoFocus color='error' onClick={async () => {
                    setIsLoading(true)
                    const translatedText: TranslatedText = await props.getTranslation(translationCode)
                    console.log("translatedText", translatedText);

                    if (translatedText.error) {
                        console.log("error", translatedText.error);
                        setIsLoading(false)
                    } else {
                        setDefaultValue({
                            displayName: translatedText.title ? translatedText.title : "",
                            questions: translatedText.questions ? translatedText.questions : [""],
                            responses: translatedText.responses ? translatedText.responses : [""],
                        })
                        setIsLoading(false)
                        setValue('displayName', translatedText.title ? translatedText.title : "")
                        reset({
                            ...getValues(),
                            questions: translatedText.questions ? translatedText.questions : [""],
                            responses: translatedText.responses ? translatedText.responses : [""]
                        })
                    }
                }} disabled={props.loading}>
                    Get Translations
                </Button>}
                <Button autoFocus type='submit' disabled={props.loading}>
                    Save changes
                </Button>
            </DialogActions>
        </form>

    );
}

export default AddTranslatedNodeDialog