import React, { useContext, useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import {
  ArrowDropDownIcon,
  DateCalendar,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import Day from "./DatePicker";
import { useLazyQuery, useQuery } from "@apollo/client";
import { LIST_AGENTS } from "adapters/queries/ListAgents";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";
import { AGENT_ACTIVITY } from "adapters/queries/AgentActivity";
import { SnackbarContext } from "components/common_snackbar/CommonSnackBar";

// Define the structure for agent availability
interface AvailabilityPeriod {
  startHour: number;
  endHour: number;
  status: "Available" | "Unavailable" | "OnCall";
}

interface DayAvailability {
  day: string;
  date: string;
  periods: AvailabilityPeriod[];
}

const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const getColorForStatus = (status: string) => {
  switch (status) {
    case "Available":
      return "green";
    case "OnCall":
      return "red";
    case "Unavailable":
      return "lightgrey";
    default:
      return "lightgrey"; // Fallback color
  }
};

const AgentActivityChart: React.FC<{ className?: string }> = ({
  className,
  ...rest
}) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          type: "category",
          labels: daysOfWeek,
          gridLines: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            reverse: true,
            // fontColor: '#114B5F',
            // fontSize: 14,
          },
        },
      ],
      xAxes: [
        {
          type: "linear",
          position: "bottom",
          ticks: {
            stepSize: 1,
            min: 0,
            max: 23,
          },
          gridLines: {
            display: false,
            drawBorder: false,
          },
          scaleLabel: {
            display: true,
            labelString: 'Hour of the Day (UTC)',
          },
        },
      ],
    },
    // tooltips: {
    //   mode: 'index',
    //   intersect: false,
    //   callbacks: {
    //     label: function(tooltipItem: any, data: any) {
    //       const dataset = data.datasets[tooltipItem.datasetIndex];
    //       const status = dataset.label.split(' ')[1]; // Assuming status is part of the label
    //       const startHour = tooltipItem.xLabel;
    //       const endHour = dataset.data[tooltipItem.index + 1] ? dataset.data[tooltipItem.index + 1].x : 'End';
    //       return `${status}: ${startHour} - ${endHour}:00`;
    //     },
    //   },
    // },
    legend: {
      display: false,
    },
    hover: {
      mode: "nearest",
      intersect: false,
    },
    elements: {
      line: {
        tension: 0.3,
        borderColor: (context: any) => getColorForStatus(context.dataset.status), // Dynamically set line color
        borderWidth: 4,
      },
      point: {
        radius: 5,
        backgroundColor: (context: any) => getColorForStatus(context.dataset.status),
        borderColor: '#fff',
        borderWidth: 2,
        hoverRadius: 6,
      },
    },
    backgroundColor: "transparent",
  };

  const { user: cUser } = useSelector((state: RootState) => state.auth);
  const [chartData, setChartData] = useState<any>({});
  const [selectedAgent, setSelectedAgent] = useState("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [hoveredDay, setHoveredDay] = useState<Dayjs | null>(null);
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dayjs());

  const { data: agentsList, loading: loadingAgents } = useQuery(LIST_AGENTS, {
    variables: { role: "agent", companyId: cUser?.companyId },
  });
  
  const [getAgentActivity, { data: activityData }] = useLazyQuery(AGENT_ACTIVITY);

  useEffect(() => {
    if (activityData && activityData.agentActivity) {
      const formattedData = activityData.agentActivity.map((dayActivity: DayAvailability) => {
        const dayIndex = daysOfWeek.indexOf(dayActivity.day); // Get day of week index based on date
        console.log(dayActivity);
        console.log(dayIndex);
        const dataSets = dayActivity.periods.map(period => ({
          label: `${dayActivity.day} (${period.status})`,
          borderColor: getColorForStatus(period.status),
          backgroundColor: getColorForStatus(period.status),
          fill: false,
          data: [{x: period.startHour, y: dayIndex}, {x: period.endHour, y: dayIndex}],
          lineTension: 0,
          pointRadius: 0,
        }));

        return dataSets;
      }).flat();

      setChartData({
        datasets: formattedData,
      });
    }
  }, [activityData]);

  // ********************* Select Agent ****************************

  const handleAgentChange = (event: SelectChangeEvent) => {
    setSelectedAgent(event.target.value);
  };
  // ********************* Date Picker *****************************

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    // Method to close the popup
    setAnchorEl(null);
  };

  const { setSnack } = useContext(SnackbarContext);

  function showCallsSummary() {
    if (selectedAgent && selectedDate) {
      getAgentActivity({
        variables: {
          agentId: selectedAgent,
          selectedDate: selectedDate,
        },
      });
    } else {
      setSnack({
        message: "Please select an agent and a date",
        severity: "error",
        open: true,
      });
    }
    handleClose();
  }

  return (
    <Card className={className} style={{ marginTop: "10px" }} {...rest}>
      <Menu
        anchorEl={anchorEl}
        id="date-picker"
        open={open}
        onClose={handleClose}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateCalendar
            maxDate={dayjs()}
            value={selectedDate}
            onChange={(newValue) => setSelectedDate(newValue)}
            showDaysOutsideCurrentMonth
            slots={{ day: Day }}
            slotProps={{
              day: (ownerState) =>
                ({
                  selectedDay: selectedDate,
                  hoveredDay,
                  onPointerEnter: () => setHoveredDay(ownerState.day),
                  onPointerLeave: () => setHoveredDay(null),
                } as any),
            }}
          />
        </LocalizationProvider>
        <MenuItem>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={showCallsSummary}
          >
            Show
          </Button>
        </MenuItem>
      </Menu>
      <CardHeader
        action={
          <div>
            <FormControl sx={{ m: 1, minWidth: 150 }}>
              <InputLabel id="agent-select-label">Select an Agent</InputLabel>
              <Select
                labelId="agent-select-label"
                id="agent-select"
                value={selectedAgent}
                label="Select an Agent"
                onChange={handleAgentChange}
                disabled={loadingAgents}
              >
                {agentsList?.users.edges.map(({ node }: any) => (
                  <MenuItem key={node.id} value={node.id}>
                    {node.firstName} {node.lastName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 150 }}>
              <Button
                onClick={handleClick}
                endIcon={<ArrowDropDownIcon />}
                size="large"
                variant="outlined"
                aria-controls={open ? "date-picker" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                Select a week
              </Button>
            </FormControl>
          </div>
        }
        title="Agent Activity Overview"
      />
      <Divider />
      <CardContent>
        <Box height={400} position="relative">
          <Line data={chartData} options={options} />
        </Box>
      </CardContent>
    </Card>
  );
};

export default AgentActivityChart;
