import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import { TicketFormData } from "../../../../../src/utils/interfaces";
import TicketForm from './TicketForm'; 
import { SnackbarContext } from "components/common_snackbar/CommonSnackBar";
import { UPDATE_TICKET_MUTATION } from "../../../../adapters/mutations/updateTicketMutation";
import { GET_TICKET_BY_ID } from "../../../../adapters/queries/retrieveTicket";

const TicketEdits: React.FC = () => {
  const { setSnack } = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);
  const { id } = useParams<{ id: string }>();
  console.log("this is ticket id :", id)

  // Fetch the ticket by its ID
  const { loading: ticketLoading, data: ticketData } = useQuery(GET_TICKET_BY_ID, {
    variables: { ticket: { id } },
  });

  useEffect(() => {
  }, []);

  const [updateTicket] = useMutation(UPDATE_TICKET_MUTATION, {
    onCompleted: () => {
      setSnack({
        message: "Ticket updated successfully",
        severity: "success",
        open: true,
      });
      setLoading(false);
    },
    onError: (error) => {
      console.error(error);
      setSnack({
        message: "Update ticket failed",
        severity: "error",
        open: true,
      });
      setLoading(false);
    },
  });

  const onSubmit = async (data: TicketFormData) => {
    setLoading(true);
    try {
      await updateTicket({
        variables: { ticket: data },
      });
    } catch (error) {
      console.error(error);
    }
  };

  if (ticketLoading) {
    return <p>Loading...</p>;
  }

  if (!ticketData || !ticketData.retrieveTicket) {
    return <p>Ticket not found</p>;
  }

  const { retrieveTicket: ticket } = ticketData;

  return (
    <Container maxWidth="md">
      <Card>
        <CardHeader title="Edit Ticket" />
        <Divider />
        {/* Pass ticket data as initialValues to the TicketForm component */}
        <TicketForm onSubmit={onSubmit} loading={loading} initialValues={ticket} />
      </Card>
    </Container>
  );
}

export default TicketEdits;