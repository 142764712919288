import AuthReducer from './AuthReducer';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import JitsiReducer from './JitsiReducer';
import AddOnCartReducer from './AddOnCartReducer';
import AgentSessionReducer from './AgentSessionReducer';


const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth', 'jitsi', 'addOnCart', 'agentSession']
};

const rootReducer = combineReducers({
    auth: AuthReducer,
    jitsi: JitsiReducer,
    addOnCart: AddOnCartReducer,
    agentSession: AgentSessionReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default persistReducer(persistConfig, rootReducer);

