import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DragEvent, useEffect, useMemo, useState } from "react";
import { useStyles } from "./style";
import { ExpandMoreOutlined, Add, Search, Settings, AutoAwesome } from "@mui/icons-material";
import AddNodeDialog, { EditNode, Node } from "../modal";
import { useMutation, useQuery } from "@apollo/client";
import { CREATE_FAQ_NODE } from "adapters/mutations/CreateFAQNode";
import { GET_FAQS } from "adapters/queries/FAQNodes";
import { UPDATE_FAQ_NODE } from "adapters/mutations/EditFAQNode";
import { DELETE_FAQ_NODE } from "adapters/mutations/DeleteFAQNode";
import { GET_BOT_FRAMEWORK } from "adapters/queries/GetBotFramework";
import { TranslatedEditNode, TranslatedNode } from "../translationForm";
import { CREATE_TRANSLATED_FAQ_NODE } from "adapters/mutations/CreateTranslatedFAQNode";
import { UPDATE_TRANSLATED_FAQ_NODE } from "adapters/mutations/EditTranslatedFAQNode";

export interface Chip {
  botProfileId: string;
  companyId: string;
  id: string;
  questions: string;
  responses: string;
  status: "active" | "inactive";
  mainmenu: "active" | "inactive";
  feedbackRequired: "active" | "inactive";
  title: string;
  displayName: string;
}

interface FAQNodesProps {
  companyId: string;
  botProfileId: string;
}

export interface TranslatedText {
  title?: string;
  questions?: string[];
  responses?: string[];
  error?: string;
}

export function isNotNull<T>(it: T): it is NonNullable<T> {
  return it != null;
}

const FAQNodes = (props: FAQNodesProps) => {
  const classes = useStyles();
  const [showDialog, setshowDialog] = useState(false);
  const [setshowMore, setSetshowMore] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [search, setSearch] = useState("");

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearchQuery(search);
    }, 1500);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);
  const [editChip, setEditChip] = useState<Chip | null>(null);
  const [editIndex, setEditIndex] = useState<number | null>(null);

  const [mutate, { loading: createFAQLoading, error: createFAQError }] =
    useMutation(CREATE_FAQ_NODE, {
      onCompleted: (res) => {
        setshowDialog(false);
        console.log(res);
      },
      onError: () => {
        console.log(createFAQError);
      },
    });

  const [
    saveTranslatedNode,
    { loading: createTranslatedFAQLoading, error: createTranslatedFAQError },
  ] = useMutation(CREATE_TRANSLATED_FAQ_NODE, {
    onCompleted: (res) => {
      setshowDialog(false);
      console.log(res);
    },
    onError: () => {
      console.log(createTranslatedFAQError);
    },
  });

  const [editFaq, { loading: editFAQLoading, error: editFAQError }] =
    useMutation(UPDATE_FAQ_NODE, {
      onCompleted: () => {
        setshowDialog(false);
        setEditIndex(null);
        setEditChip(null);
      },
      onError: () => {
        console.log(editFAQError);
      },
    });

  const [
    editTranslatedFaq,
    { loading: editTranslatedFAQLoading, error: editTranslatedFAQError },
  ] = useMutation(UPDATE_TRANSLATED_FAQ_NODE, {
    onCompleted: () => {
      setshowDialog(false);
      setEditIndex(null);
      setEditChip(null);
    },
    onError: () => {
      console.log(editTranslatedFAQError);
    },
  });

  const [deleteFaq, { loading: deleteFAQLoading, error: deleteFAQError }] =
    useMutation(DELETE_FAQ_NODE, {
      onCompleted: (res) => {
        console.log(res);
        setshowDialog(false);
        setEditIndex(null);
        setEditChip(null);
      },
      onError: () => {
        console.log(deleteFAQError);
      },
    });

  const getTranslation = async (translationCode: string) => {
    try {
      const myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");

      const raw = {
        translation_item: {
          title: editChip?.displayName ? editChip?.displayName : "",
          questions: editChip?.questions ? JSON.parse(editChip?.questions) : [],
          responses: editChip?.responses ? JSON.parse(editChip?.responses) : [],
        },
        target_language: translationCode,
      };

      const requestOptions: RequestInit = {
        method: "POST",
        mode: "cors",
        headers: myHeaders,
        body: JSON.stringify(raw),
      };

      const response = await fetch(
        `${process.env.REACT_APP_LLM_TRANSLATION_URL}/translate`,
        requestOptions
      );
      if (response.status == 200) {
        const JsonText = await response.text();
        return JSON.parse(JsonText);
      }
      return {
        error: "Failed to Translate",
      };
    } catch (error) {
      console.log(error);
    }
  };

  const {
    data: faqs,
    error: errorFAQ,
    loading: loadingFAQ,
  } = useQuery(GET_FAQS, {
    variables: {
      companyId: props.companyId,
      botProfileId: props.botProfileId,
      search: searchQuery,
    },
  });

  const { faqNodes } = faqs || {};

  const faqChips: Array<any> = useMemo(() => {
    return faqNodes && faqNodes.edges
      ? faqNodes.edges
        .filter(isNotNull)
        .map((edge: any) => edge.node)
        .filter(isNotNull)
      : [];
  }, [faqNodes]);

  const onDragStart = (
    event: DragEvent,
    nodeType: string,
    displayName: string,
    faqId: string
  ) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.setData("application/reactflow/faqId", faqId);
    event.dataTransfer.setData(
      "application/reactflow/displayName",
      displayName
    );
    event.dataTransfer.effectAllowed = "move";
  };

  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreOutlined />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography variant="h6">Knowledge Base (FAQ)</Typography>
          {createFAQLoading &&
            editFAQLoading &&
            createTranslatedFAQLoading &&
            editTranslatedFAQLoading && <div>Loading...</div>}
        </AccordionSummary>
        {loadingFAQ && <LinearProgress />}
        <AccordionDetails>
          <Box className={classes.searchContainer}>
            <TextField
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              variant="outlined"
              InputProps={{
                startAdornment: <Search />,
              }}
              type="search"
              id="search"
              placeholder="Search by topic"
              fullWidth
            />
          </Box>

          {errorFAQ ? (
            <div>{JSON.stringify(errorFAQ)}</div>
          ) : (
            <Stack
              direction="row"
              alignItems="center"
              flexWrap={"wrap"}
              spacing={2}
            >
              {faqChips.length == 0 && (
                <Typography variant="body2" margin={4} textAlign={"center"}>
                  Add nodes to train your bot model
                </Typography>
              )}

              {faqChips
                .slice(0, faqChips.length >= 4 ? 4 : faqChips.length)
                .map((item, index) => (
                  <Chip
                    label={item.title}
                    variant="filled"
                    key={item.id}
                    icon={item.aiGenerated ? <AutoAwesome /> : <></>}
                    color={item.status == "active" ? item.aiGenerated ? "info" : "secondary" : "default"}
                    onDragStart={(event: DragEvent) =>
                      onDragStart(event, item.title, item.displayName, item.id)
                    }
                    draggable={item.status == "active"}
                    onDelete={() => {
                      setEditChip(item);
                      setEditIndex(index);
                      setshowDialog(true);
                    }}
                    deleteIcon={<Settings />}
                    style={{ margin: 4, cursor: "move" }}
                    className={classes.chip}
                  />
                ))}
              {setshowMore ? (
                faqChips.slice(4).map((item, index) => (
                  <Chip
                    label={item.title}
                    variant="filled"
                    key={item.id}
                    icon={item.aiGenerated ? <AutoAwesome /> : <></>}
                    color={item.status == "active" ? item.aiGenerated ? "info" : "secondary" : "default"}
                    onDragStart={(event: DragEvent) =>
                      onDragStart(event, item.title, item.displayName, item.id)
                    }
                    draggable={item.status == "active"}
                    onDelete={() => {
                      setEditChip(item);
                      setEditIndex(index);
                      setshowDialog(true);
                    }}
                    deleteIcon={<Settings />}
                    style={{ margin: 4, cursor: "move" }}
                    className={classes.chip}
                  />
                ))
              ) : faqChips.length > 4 ? (
                <Button
                  variant="text"
                  onClick={() => {
                    setSetshowMore(true);
                  }}
                >
                  Show More
                </Button>
              ) : null}

              {setshowMore && (
                <Button
                  variant="text"
                  onClick={() => {
                    setSetshowMore(false);
                  }}
                >
                  Show Less
                </Button>
              )}
            </Stack>
          )}

          <Box className={classes.buttonContainer}>
            <Button
              variant="contained"
              startIcon={<Add />}
              onClick={() => {
                setshowDialog(true);
              }}
            >
              Topic
            </Button>
          </Box>
        </AccordionDetails>
      </Accordion>

      {showDialog && (
        <AddNodeDialog
          open={showDialog}
          onClose={() => {
            setshowDialog(false);
            setEditIndex(null);
            setEditChip(null);
          }}
          botProfileId={props.botProfileId}
          edit={editChip}
          loading={
            createFAQLoading ||
            editFAQLoading ||
            deleteFAQLoading ||
            createTranslatedFAQLoading ||
            editTranslatedFAQLoading
          }
          error={
            createFAQError ||
            editFAQError ||
            deleteFAQError ||
            createTranslatedFAQError ||
            editTranslatedFAQError
          }
          onSaveEdit={(data: EditNode) => {
            if (editIndex != null) {
              editFaq({
                variables: {
                  input: {
                    id: data.id,
                    questions: JSON.stringify(data.questions),
                    responses: data.responses,
                    status: data.status ? "active" : "inactive",
                    feedbackRequired: data.feedbackRequired
                      ? "active"
                      : "inactive",
                    mainmenu: data.mainmenu ? "active" : "inactive",
                    title: data.title,
                    botProfileId: props.botProfileId,
                    companyId: props.companyId,
                    displayName: data.displayName,
                  },
                },
                refetchQueries: [
                  {
                    query: GET_FAQS,
                    variables: {
                      companyId: props.companyId,
                      botProfileId: props.botProfileId,
                      search: searchQuery,
                    },
                  },
                  {
                    query: GET_BOT_FRAMEWORK,
                    variables: {
                      botFrameworkByBotProfileIdId: props.botProfileId,
                    },
                  },
                ],
              });
            }
          }}
          onDelete={(id) => {
            deleteFaq({
              variables: {
                input: {
                  id,
                },
              },
              refetchQueries: [
                {
                  query: GET_FAQS,
                  variables: {
                    companyId: props.companyId,
                    botProfileId: props.botProfileId,
                    search: searchQuery,
                  },
                },
              ],
            });
          }}
          onSave={(data: Node) => {
            mutate({
              variables: {
                input: {
                  questions: JSON.stringify(data.questions),
                  responses: data.responses,
                  status: data.status ? "active" : "inactive",
                  mainmenu: data.mainmenu ? "active" : "inactive",
                  feedbackRequired: data.feedbackRequired
                    ? "active"
                    : "inactive",
                  title: data.title,
                  botProfileId: props.botProfileId,
                  companyId: props.companyId,
                  displayName: data.displayName,
                },
              },
              refetchQueries: [
                {
                  query: GET_FAQS,
                  variables: {
                    companyId: props.companyId,
                    botProfileId: props.botProfileId,
                    search: searchQuery,
                  },
                },
              ],
            });
          }}
          onSaveTranslatedNode={(data: TranslatedNode) => {
            saveTranslatedNode({
              variables: {
                input: {
                  questions: JSON.stringify(data.questions),
                  responses: data.responses,
                  botProfileId: props.botProfileId,
                  companyId: props.companyId,
                  displayName: data.displayName,
                  refrenceNodeId: editChip?.id,
                  translationCode: data.translationCode,
                },
              },
              refetchQueries: [
                {
                  query: GET_FAQS,
                  variables: {
                    companyId: props.companyId,
                    botProfileId: props.botProfileId,
                    search: searchQuery,
                  },
                },
              ],
            });
          }}
          onSaveEditTranslatedNode={(data: TranslatedEditNode) => {
            editTranslatedFaq({
              variables: {
                input: {
                  questions: JSON.stringify(data.questions),
                  responses: data.responses,
                  botProfileId: props.botProfileId,
                  companyId: props.companyId,
                  displayName: data.displayName,
                  refrenceNodeId: editChip?.id,
                  translationCode: data.translationCode,
                },
              },
              refetchQueries: [
                {
                  query: GET_FAQS,
                  variables: {
                    companyId: props.companyId,
                    botProfileId: props.botProfileId,
                    search: searchQuery,
                  },
                },
              ],
            });
          }}
          getTranslation={getTranslation}
        />
      )}
    </>
  );
};

export default FAQNodes;
