import { gql } from "@apollo/client";

export const USERS = gql`
query Users($role: String, $managerId: String, $companyId: String) {
  users(role: $role, managerId: $managerId, companyId: $companyId) {
    edges {
      node {
        firstName
        lastName
        company {
          name
        }
        id
      }
    }
  }
}`