import clsx from "clsx";
import { useStyles } from "./style";
import FormInput from "components/form/form_input_search/FormInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormButton from "@mui/material/Button";
import { Clear, Search } from "@mui/icons-material";
import { Card, Grid, IconButton, InputAdornment, MenuItem, Stack } from "@mui/material";

export interface SearchQuery {
  searchText: string;
  searchBy: string;
}

interface IProps {
  className?: string;
  onSearch: (query: SearchQuery) => void;
}

const schema = yup.object().shape({});

const Searchbar = ({ className, onSearch }: IProps) => {
  const classes = useStyles();

  const defaultValues = {
    searchText: "",
    searchBy: "company", 
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onSubmit = ({ searchText, searchBy }: SearchQuery) => {
    onSearch({ searchText, searchBy });
  };

  const onClear = () => {
    reset({ ...getValues(), searchText: '' }, {
      keepDefaultValues: true,
      keepValues: false
    });
    handleSubmit(onSubmit)();
  };

  return (
    <div className={clsx(classes.root, className)}>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <Card sx={{ p: 2 }}>
          <Grid container item direction="row" alignItems="center" columnSpacing={2} rowSpacing={2}>
            <Grid item md={5} sm={6} xs={12}> 
              <FormInput
                control={control}
                type="text"
                name="searchText"
                label="Search by company or code"
                error={errors && errors.searchText}
                InputProps={{
                  endAdornment: (getValues().searchText ? 
                    <InputAdornment position="end">
                      <IconButton onClick={onClear} size="small">
                        <Clear color="action" />
                      </IconButton>
                    </InputAdornment>
                  : null)
                }}
              />
            </Grid>
            <Grid item md={3} sm={4} xs={6}> 
              <FormInput
                control={control}
                type="text"
                name="searchBy"
                label="Filter by"
                select="select"
                defaultValue={defaultValues.searchBy}
              >
                <MenuItem value="company">Company</MenuItem>
                <MenuItem value="code">Code</MenuItem>
              </FormInput>
            </Grid>
            <Grid item md={4} sm={2} xs={6}> 
              <Stack direction="row" spacing={2} justifyContent="flex-end">
                <FormButton
                  type="submit"
                  color="primary"
                  variant="contained"
                  size="large"
                  startIcon={<Search />}
                >
                  Search
                </FormButton>
              </Stack>
            </Grid>
          </Grid>
        </Card>
      </form>
    </div>
  );
};

export default Searchbar;
