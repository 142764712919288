import React, { useState, useContext } from "react";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormButton from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import CircularProgress from "../../../../components/facebook_progress/FacebookProgress";
import { useStyles } from "./style";
import FormInput from "../../../../components/form/form_input/FormInput";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FormFeedback from "../../../../components/form_feedback/FormFeedback";
import { SnackbarContext } from "components/common_snackbar/CommonSnackBar";
import { CREATE_ADDON } from "../../../../adapters/mutations/AddOnAdminCreate";
import { ADD_ON_ADMIN_REFETCH } from "../../../../adapters/queries/AddOnAdminRefetch";
import { useMutation } from "@apollo/client";

interface AddonFormData {
  name: string;
  description: string;
  allowanceType: string;
  allowanceUnits: number;
  price: number;
  active: boolean;
}

const AddAddon = () => {
  const classes = useStyles();
  const { setSnack } = useContext(SnackbarContext);
  const [status, setStatus] = useState(true);

  const [mutate, { loading, error }] = useMutation(CREATE_ADDON, {
    onCompleted: () => {
      setSnack({
        message: "Addon created successfully",
        severity: "success",
        open: true,
      });
      resetForm(); // Reset the form after successful creation
    },
    onError: (error) => {
      console.error(error);
      setSnack({ message: "Create addon failed", severity: "error", open: true });
    },
    refetchQueries: [{ query: ADD_ON_ADMIN_REFETCH }],
  });

  const schema = yup.object().shape({
    name: yup.string().required("Name is required"),
    description: yup.string().required("Description is required"),
    allowanceType: yup.string().required("Allowance Type is required"),
    allowanceUnits: yup.number().positive("Allowance Units must be positive").required("Allowance Units is required"),
    price: yup.number().positive("Price must be positive").required("Price is required"),
  });

  const { handleSubmit, control, reset: resetForm, formState: { errors } } = useForm<AddonFormData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: AddonFormData) => {
    mutate({
      variables: {
        addOn: {
          ...data,
          active: status,
        },
      },
    });
  };

  return (
    <React.Fragment>
      <Container maxWidth={"xs"}>
        <Card>
          <CardHeader title="Create Addon" />
          <Divider />
          <form onSubmit={handleSubmit(onSubmit)}>
            <CardContent>
              <FormInput
                control={control}
                type="text"
                name="name"
                label="Name*"
                error={errors?.name}
                style={{ marginBottom: '16px' }} 
              />
              <FormInput
                control={control}
                type="text"
                name="description"
                label="Description*"
                error={errors?.description}
                style={{ marginBottom: '16px' }} 
              />
              <FormInput
                control={control}
                type="text"
                name="allowanceType"
                label="Allowance Type*"
                error={errors?.allowanceType}
                style={{ marginBottom: '16px' }} 
              />
              <FormInput
                control={control}
                type="number"
                name="allowanceUnits"
                label="Allowance Units*"
                error={errors?.allowanceUnits}
                style={{ marginBottom: '16px' }} 
              />
              <FormInput
                control={control}
                type="number"
                name="price"
                label="Price*"
                error={errors?.price}
                style={{ marginBottom: '16px' }} 
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={status}
                    onChange={(event) => setStatus(event.target.checked)}
                    color="primary"
                    name="active"
                    inputProps={{
                      "aria-label": "primary checkbox",
                    }}
                  />
                }
                label="Active"
                labelPlacement="start"
              />
              {error && (
                <FormFeedback className={classes.feedback} error>
                  {error.message}
                </FormFeedback>
              )}
            </CardContent>
            <Divider />
            <CardActions>
              <div className={classes.buttonWrapper}>
                <FormButton
                  className={classes.button}
                  type="submit"
                  color="secondary"
                  variant="contained"
                  fullWidth
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={24} /> : "Create"}
                </FormButton>
              </div>
            </CardActions>
          </form>
        </Card>
      </Container>
    </React.Fragment>
  );
}

export default AddAddon;