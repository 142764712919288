import { useMemo } from "react";
import { useEffect } from "react";
import { useStyles } from "./style";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { Hidden } from "@mui/material";
import ListItem from "@material-ui/core/ListItem";
import ListSubheader from "@material-ui/core/ListSubheader";
import Page from "../../../../components/page/Page";
import AuthLogToolbar from "../toolbar/Toolbar";
import { useQuery } from "@apollo/client";
import AuthLogItem from "../list_item/ListItem";
import { AUTH_LOG_AGENT_REFETCH } from "../../../../adapters/queries/AuthLogAgentRefetch";
import Chip from "@material-ui/core/Chip";

export function isNotNull<T>(it: T): it is NonNullable<T> {
  return it != null;
}

const AuthLog = (): JSX.Element => {
  const classes = useStyles();
  const { data, fetchMore } = useQuery(AUTH_LOG_AGENT_REFETCH);

  useEffect(() => {
    document.title = "Auth Log";
  }, []);

  const { authLogs } = data || {};

  const list = useMemo(() => {
    return authLogs && authLogs.edges
      ? authLogs.edges
        .filter(isNotNull)
        .map((edge: any) => edge.node)
        .filter(isNotNull)
      : [];
  }, [authLogs]);

  const LoadMore = () => (
    <div className={classes.rootButton}>
      {authLogs?.pageInfo?.hasNextPage && (
        <Chip
          onClick={() =>
            fetchMore({
              variables: {
                cursor: authLogs?.pageInfo?.endCursor,
              },
            })
          }
          label="Load More"
          className={classes.chip}
          variant="outlined"
        />
      )}
    </div>
  );

  return (
    <Page title="Auth Logs">
      <Container maxWidth={false}>
        <AuthLogToolbar />
        <Grid className={classes.gridList} container direction="row">
          <Hidden smDown>
            <ListItem
              className={classes.gridHeading}
              key={1}
              style={{ width: "100%" }}
            >
              <Grid item md={2}>
                <ListSubheader disableGutters component="div">
                  User
                </ListSubheader>
              </Grid>

              <Grid item md={2}>
                <ListSubheader disableGutters component="div">
                  Action
                </ListSubheader>
              </Grid>

              <Grid item md={2}>
                <ListSubheader disableGutters component="div">
                  Result
                </ListSubheader>
              </Grid>

              <Grid item md={2}>
                <ListSubheader disableGutters component="div">
                  IP Address
                </ListSubheader>
              </Grid>

              <Grid item md={2}>
                <ListSubheader disableGutters component="div">
                  Date
                </ListSubheader>
              </Grid>

              <Grid item xs={1}>
                <ListSubheader disableGutters component="div"></ListSubheader>
              </Grid>
            </ListItem>
          </Hidden>
          {list!.length ? (
            list!.map((node: any) => {
              if (node == null) {
                return null;
              }
              return <AuthLogItem authLog={node} key={node.id} />;
            })
          ) : (
            <Grid className={classes.gridEmptyRecords} item xs={12}>
              No auth logs found
            </Grid>
          )}
        </Grid>
        <LoadMore />
      </Container>
    </Page>
  );
};

export default AuthLog;
