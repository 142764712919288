import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import RateReviewIcon from '@mui/icons-material/RateReview';
// import Typography from '@mui/material/Typography';
import FormInput from "../../form/form_input/FormInput";
import * as yup from "yup";
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Checkbox, FormControlLabel, LinearProgress, MenuItem, OutlinedInput, Select, Stack, Typography } from '@mui/material';
import { useStyles } from './style';
import { PlusOneOutlined } from '@mui/icons-material';
import { Chip as FAQChip, TranslatedText } from '../faq_nodes'
import { ApolloError, useLazyQuery, useQuery } from '@apollo/client';
import AddTranslatedNodeDialog, { TranslatedEditNode, TranslatedNode } from '../translationForm';
import { CALL_FOR_HELP_BUSINESS_ADMIN_EDIT } from 'adapters/queries/CallForHelpBusinessAdminEdit';
import { GET_FEEDBACK_COUNT } from 'adapters/queries/GetFeedbackCount';
import FeedbackList from '../FeedbackList';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

interface Language {
    title: string;
    value: string;
    primary: boolean;
}

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
    onLanguageChange: (data: Language) => void;
    mode: string;
    setMode: (mode: "faq" | "feedback") => void;
    botProfileId: string;
    editMode: boolean;
    nodeId?: string;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, onLanguageChange, mode, setMode, editMode, nodeId, ...other } = props;

    const { data, loading } = useQuery(CALL_FOR_HELP_BUSINESS_ADMIN_EDIT, {
        variables: { id: props.botProfileId },
    });

    const [feedbackCount, setFeedbackCount] = useState({
        negativeCount: 50,
        positiveCount: 50,
        totalCount: 0
    })

    const [runQuery] = useLazyQuery(GET_FEEDBACK_COUNT, {
        onCompleted: (data) => {
            console.log(data?.getFeedbackByNodeId)
            if (data?.getFeedbackByNodeId && data?.getFeedbackByNodeId?.totalCount) {
                const {
                    negativeCount, positiveCount, totalCount
                } = data.getFeedbackByNodeId
                setFeedbackCount({
                    positiveCount: positiveCount > 0 ? ((positiveCount / totalCount) * 100) : 0,
                    negativeCount: negativeCount > 0 ? ((negativeCount / totalCount) * 100) : 0,
                    totalCount: totalCount

                })
            }
        },
    });

    const botProfile = data?.botprofile;

    const [language, setLanguage] = useState("en")
    const [languages, setLanguages] = useState([{ title: "English", value: "en", primary: true }])

    useEffect(() => {
        if (botProfile) {
            const botLanguages: [{ title: string, value: string, primary: boolean }] = botProfile?.languages ?
                JSON.parse(botProfile?.languages).map((lang: { title: string, value: string }) =>
                    ({ ...lang, primary: lang.value == botProfile?.primaryLanguage }))
                : [{ title: "English", value: "en", primary: true }]
            setLanguages(botLanguages)
            const selectedLang = botLanguages.find(lang => lang.value == botProfile?.primaryLanguage)
            setLanguage(botProfile?.primaryLanguage)
            onLanguageChange(selectedLang ? selectedLang : languages[0])

        }
    }, [botProfile])

    useEffect(() => {
        if (nodeId) {

            runQuery({
                variables: {
                    "input": {
                        "id": nodeId
                    }
                }
            })

        }
    }, [nodeId])

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}{editMode ? null : <Typography variant='body2'>({languages.find(lan => lan.value == language)?.title})</Typography>}
            {onClose ? (
                <Stack direction={'column'} style={{ alignItems: 'center' }}>
                    {editMode && <Stack direction={'row'} style={{ cursor: 'pointer' }}
                        onClick={() => { setMode(mode == "faq" ? "feedback" : "faq") }}>
                        <ThumbUpIcon color='primary' />
                        <Stack direction={'column'} style={{ alignItems: 'center' }}>
                            <LinearProgress variant="determinate" value={feedbackCount.positiveCount} style={{ width: 200, marginRight: 20, marginLeft: 20 }}
                                onClick={() => { setMode(mode == "faq" ? "feedback" : "faq") }} />
                            <Stack direction={'row'} style={{ alignItems: 'center', marginTop: 4 }}>
                                <RateReviewIcon color='disabled' style={{
                                    marginRight: 5, marginTop: 2
                                }} />
                                <Typography>{feedbackCount.totalCount}</Typography>
                            </Stack>
                        </Stack>
                        <ThumbDownIcon style={{ color: 'rgb(195, 196, 249)' }} />
                    </Stack>}

                    {mode == "faq" && editMode && loading == false && <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        value={language}
                        onChange={(code) => {
                            const selectedLang = languages.find(lang => lang.value == code.target.value)
                            setLanguage(code.target.value)
                            onLanguageChange(selectedLang ? selectedLang : languages[0])
                        }}
                        input={<OutlinedInput />}
                        sx={{
                            position: 'absolute',
                            right: 50,
                            top: 8,
                            height: 40
                        }}
                    >
                        {languages.map((lang) => (
                            <MenuItem
                                key={lang.value}
                                value={lang.value}
                            >
                                {lang.title}
                            </MenuItem>
                        ))}
                    </Select>}
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Stack>
            ) : null}
        </DialogTitle>
    );
}
export interface Node {
    title: string;
    displayName: string;
    responses: string;
    questions: Array<string>;
    status: boolean,
    mainmenu: boolean,
    feedbackRequired: boolean,
}

export interface EditNode extends Partial<Node> {
    id: string;
}

interface AddNodeDialogProps {
    open: boolean;
    onClose: (event?: any, reason?: "backdropClick" | "escapeKeyDown") => void;
    onSave: (node: Node) => void;
    onSaveTranslatedNode: (node: TranslatedNode) => void;
    edit: FAQChip | null;
    onSaveEdit?: (node: EditNode) => void;
    onSaveEditTranslatedNode?: (node: TranslatedEditNode) => void;
    onDelete: (id?: string) => void;
    getTranslation: (translationCode: string) => Promise<TranslatedText>;
    loading?: boolean;
    error?: ApolloError;
    botProfileId: string;
}


const schema = yup.object().shape({
    title: yup.string().required(),
    displayName: yup.string().required(),
    questions: yup.array().of(yup.string().required()),
    responses: yup.array().of(yup.string().required()),
});



const AddNodeDialog = (props: AddNodeDialogProps) => {
    const { open, onClose, onSave, onSaveEdit } = props
    const [selectedLanguage, setSelectedLanguage] = useState({ title: "English", value: "en", primary: true })
    const [mode, setMode] = useState<"faq" | "feedback">("faq")
    const classes = useStyles()

    const defaultValues = {
        title: props.edit ? props.edit?.title : '',
        displayName: props.edit ? props.edit?.displayName : '',
        questions: props.edit ? JSON.parse(props.edit?.questions) : [],
        responses: props.edit ? JSON.parse(props.edit?.responses) : [],
        status: props.edit ? props.edit?.status == 'active' : false,
        mainmenu: props.edit ? props.edit?.mainmenu == 'active' : false,
        feedbackRequired: props.edit ? props.edit?.feedbackRequired == 'active' : false
    }


    const {
        handleSubmit,
        control,
        formState: { errors }
    } = useForm({
        defaultValues,
        resolver: yupResolver(schema),
        shouldUnregister: false,
    });

    const onSubmit = ({
        title,
        displayName,
        questions,
        responses,
        status,
        mainmenu,
        feedbackRequired
    }: Node) => {
        if (props.edit) {
            onSaveEdit && onSaveEdit({ id: props.edit.id, title, displayName, responses: JSON.stringify(responses), questions, status, mainmenu, feedbackRequired })
        } else {
            onSave({ title, displayName, responses: JSON.stringify(responses), questions, status, mainmenu, feedbackRequired })
        }
    };

    //to handle the multiple question in form
    const { fields, append, remove, insert } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        //@ts-ignore
        name: 'questions', // unique name for your Field Array,
    });

    //to handle the multiple responses in form
    const { fields: responsesFields, append: appendResponse, remove: removeResponse, update: insertResponse, } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        //@ts-ignore
        name: 'responses', // unique name for your Field Array,
    });

    const addQuestion = () => {
        append("")
    }

    const addResponse = () => {
        appendResponse("")
    }

    useEffect(() => {
        if (!props.edit && fields.length == 0) {
            insert(0, "")
        }
        if (!props.edit && responsesFields.length == 0) {
            insertResponse(0, "")
            console.log("insertResponse");
        }
    }, [])

    const removeQuestion = (index: number) => {
        if (fields.length != 1) {
            remove(index)
        }
    }

    const removeOneResponse = (index: number) => {
        if (responsesFields.length != 1) {
            removeResponse(index)
        }
    }

    return (
        <BootstrapDialog
            maxWidth='md'
            fullWidth
            onClose={onClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            scroll='paper'
        >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}
                onLanguageChange={(data) => setSelectedLanguage(data)}
                mode={mode}
                editMode={props.edit ? true : false}
                nodeId={props.edit?.id}
                botProfileId={props.botProfileId}
                setMode={setMode}>
                {mode == 'feedback' && <IconButton
                    aria-label="close"
                    sx={{
                        height: 25,
                    }}
                    onClick={() => { setMode("faq") }}
                >
                    <ArrowBackIcon />
                </IconButton>}
                {mode == 'faq' ? "Add Topic" : "Feedbacks"}
            </BootstrapDialogTitle>
            {mode == "faq" ? (selectedLanguage.primary ? <form onSubmit={handleSubmit(onSubmit)} style={{
                overflowY: 'auto',
                display: 'flex',
                flexDirection: 'column',
            }}>
                {<DialogContent dividers>
                    <Stack spacing={4} paddingLeft={10} paddingRight={10}>
                        <FormInput
                            control={control}
                            defaultValue={defaultValues.title}
                            type="text"
                            name="title"
                            label="Topic Name"
                            error={errors && errors.title}
                        />
                        <FormInput
                            control={control}
                            defaultValue={defaultValues.displayName}
                            type="text"
                            name="displayName"
                            label="Display Name"
                            error={errors && errors.displayName}
                        />

                        <Stack spacing={2}>
                            <Typography variant='h6'>Questions</Typography>

                            {fields.map((item: any, index: number) =>

                                <Stack display={'flex'} flexDirection={'row'} alignItems={'center'} key={item.id + index}>

                                    <FormInput
                                        vertical={true}
                                        className={classes.textField}
                                        defaultValue={defaultValues.questions[index]}
                                        control={control}
                                        type="text"
                                        name={`questions[${index}]`}
                                        label={`Question ${index + 1}`}
                                        error={errors && errors.questions && errors.questions[index]}
                                    />

                                    {fields.length > 1 && <IconButton color='primary' onClick={() => removeQuestion(index)}>
                                        <CloseIcon />
                                    </IconButton>}

                                </Stack>)
                            }

                            <Box className={classes.buttonContainer}>
                                <Button variant="contained" startIcon={<PlusOneOutlined />} onClick={() => {
                                    addQuestion()
                                }}>
                                    Add
                                </Button>
                            </Box>

                        </Stack>

                        <Stack spacing={2}>
                            <Typography variant='h6'>Responses</Typography>
                            <Typography variant='body2'>Note: Add multiple responses to send it as separate messages to end user.</Typography>

                            {responsesFields.map((item: any, index: number) =>

                                <Stack display={'flex'} flexDirection={'row'} alignItems={'center'} key={item.id + index}>

                                    <FormInput
                                        vertical={true}
                                        className={classes.textField}
                                        defaultValue={defaultValues.responses[index]}
                                        control={control}
                                        type="text"
                                        name={`responses[${index}]`}
                                        label={`Response ${index + 1}`}
                                        error={errors && errors.responses && errors.responses[index]}
                                    />

                                    {responsesFields.length > 1 && <IconButton color='primary' onClick={() => removeOneResponse(index)}>
                                        <CloseIcon />
                                    </IconButton>}

                                </Stack>)
                            }

                            <Box className={classes.buttonContainer}>
                                <Button variant="contained" startIcon={<PlusOneOutlined />} onClick={() => {
                                    addResponse()
                                }}>
                                    Add
                                </Button>
                            </Box>

                        </Stack>

                        <FormControlLabel
                            control={
                                <Controller
                                    name={'mainmenu'}
                                    control={control}
                                    defaultValue={defaultValues.mainmenu}
                                    render={({ field: props }) => (
                                        <Checkbox
                                            {...props}
                                            checked={props.value}
                                            onChange={(e) => props.onChange(e.target.checked)}
                                        />
                                    )}
                                />
                            }
                            label={" Show main menu option in response."}
                        />

                        <FormControlLabel
                            control={
                                <Controller
                                    name={'feedbackRequired'}
                                    control={control}
                                    defaultValue={defaultValues.feedbackRequired}
                                    render={({ field: props }) => (
                                        <Checkbox
                                            {...props}
                                            checked={props.value}
                                            onChange={(e) => props.onChange(e.target.checked)}
                                        />
                                    )}
                                />
                            }
                            label={" Ask feedback after message."}
                        />

                        <FormControlLabel
                            control={
                                <Controller
                                    name={'status'}
                                    control={control}
                                    defaultValue={defaultValues.status}
                                    render={({ field: props }) => (
                                        <Checkbox
                                            {...props}
                                            checked={props.value}
                                            onChange={(e) => props.onChange(e.target.checked)}
                                        />
                                    )}
                                />
                            }
                            label={" Use this node data to train bot model."}
                        />

                        {props.error && <Typography variant='subtitle2' color={'red'}>{props?.error?.message}</Typography>}

                    </Stack>
                </DialogContent>}
                {props.loading && <LinearProgress />}
                <DialogActions>
                    {props.edit && <Button autoFocus color='error' onClick={() => props.onDelete(props.edit?.id)} disabled={props.loading}>
                        Delete Topic
                    </Button>}
                    <Button autoFocus type='submit' disabled={props.loading}>
                        Save changes
                    </Button>
                </DialogActions>
            </form> : <AddTranslatedNodeDialog
                edit={props.edit}
                onClose={props.onClose}
                getTranslation={props.getTranslation}
                onSave={props.onSaveTranslatedNode}
                onSaveEdit={props.onSaveEditTranslatedNode}
                open={props.open}
                translationCode={selectedLanguage.value}
                error={props.error}
                loading={props.loading}
            />) : <FeedbackList nodeId={props.edit?.id} />}
        </BootstrapDialog>
    );
}

export default AddNodeDialog