import { gql } from "@apollo/client";

export const LIST_AGENT_AUTH_LOGS = gql`
    fragment ListAgent_authLogs_1G22uz on Query {
        authLogs(first: $count, after: $cursor) {
            edges {
                node {
                    id
                    action
                    result
                    user {
                        firstName
                        id
                    }
                    requestIp
                    createdAt
                    __typename
                }
                cursor
            }
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
            totalCount
        }
    }
`;
