import FormFeedback from "../../form_feedback/FormFeedback";
import FormInput from "../../form/form_input_search/FormInput";
import FormButton from "@mui/material/Button";
import { useStyles } from "./style";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { RESET_PASSWORD_ADMIN_USER } from "../../../adapters/mutations/ResetPasswordAdminUser";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Page from "components/page/Page";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";
import { useContext } from "react";
import { SnackbarContext } from "components/common_snackbar/CommonSnackBar";
import {
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Grid,
  Stack,
} from "@mui/material";

export default function ResetPassword(props: any) {
  const classes = useStyles();
  const history = useHistory();
  const { user: cUser } = useSelector((state: RootState) => state.auth);
  const isMyProfile = cUser?.id === props.user.id;

  const { setSnack } = useContext(SnackbarContext);
  var id = "";
  if (props.user) {
    id = props.user.id;
  } else {
    id = props.match.params.id;
  }

  const [mutate, { loading, error }] = useMutation(RESET_PASSWORD_ADMIN_USER, {
    onCompleted: () => {
      setSnack({
        message: "Password changed successfully",
        severity: "success",
        open: true,
      });
      if (isMyProfile) {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } else {
        history.push(cUser?.role == "admin" ? "/admin/users" : "/users");
      }
    },
    onError: () => {
      console.log(error);
      setSnack({
        message: "Failed to change the password",
        severity: "error",
        open: true,
      });
    },
  });

  const formSchema = yup.object().shape({
    newPassword: yup
      .string()
      .required("Password is required")
      .min(8, "Password length should be at least 8 characters")
      .max(12, "Password cannot exceed more than 12 characters")
      .matches(
        /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.{8,})/,
        "Must Contain character and numbers"
      ),
    confirmPassword: yup
      .string()
      .required("Confirm Password is required")
      .min(8, "Password length should be at least 8 characters")
      .max(12, "Password cannot exceed more than 12 characters")
      .oneOf([yup.ref("newPassword")], "Passwords do not match"),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      newPassword: "",
      confirmPassword: "",
    },
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: yupResolver(formSchema),
  });

  const onSubmit = ({ confirmPassword, newPassword }: any) => {
    mutate({
      variables: {
        input: {
          id: id,
          newPassword: newPassword,
          confirmPassword: confirmPassword,
        },
      },
    });
  };

  const Form = () => (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CardHeader title="Change Password" />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FormInput
              control={control}
              type="password"
              name="newPassword"
              label="New Password*"
              error={errors && errors.newPassword}
            />
          </Grid>
          <br />
          <br />
          <Grid item xs={12} md={6}>
            <FormInput
              control={control}
              type="password"
              name="confirmPassword"
              label="Confirm Password*"
              error={errors && errors.confirmPassword}
            />
          </Grid>
          <br />
          <br />
          {error ? (
            <FormFeedback className={classes.feedback} error>
              {error.message}
            </FormFeedback>
          ) : null}
        </Grid>
      </CardContent>
      <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ paddingX: "1rem" }}>
        <CardActions>
          <div className={classes.buttonWrapper}>
            <FormButton
              className={classes.button}
              type="submit"
              color="secondary"
              variant="contained"
              fullWidth
            >
              Save Password
            </FormButton>
            {loading && <CircularProgress className={classes.buttonProgress} />}
          </div>
        </CardActions>
      </Stack>
    </form>
  );

  return (
    <div>
      <Page className={classes.root} title="Change Password">
        {props.user ? (
          <Form />
        ) : (
          <Container maxWidth={"xs"}>
            <Form />
          </Container>
        )}
      </Page>
    </div>
  );
}
