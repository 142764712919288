import { useMemo } from "react";
import { useEffect } from "react";
import { useStyles } from "./style";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { Hidden } from "@mui/material";
import ListItem from "@material-ui/core/ListItem";
import ListSubheader from "@material-ui/core/ListSubheader";
import Page from "../../../../components/page/Page";
import AuditToolbar from "../toolbar/Toolbar";
import { useQuery } from "@apollo/client";
import { AGENT_AUDITS } from "../../../../adapters/queries/AuditAgent";
import AuditItem from "../list_item/List_Item";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";
import Chip from "@material-ui/core/Chip";

export function isNotNull<T>(it: T): it is NonNullable<T> {
  return it != null;
}

const AuditList = (): JSX.Element => {
  const classes = useStyles();
  const { user } = useSelector((state: RootState) => state.auth);
  const { data, fetchMore } = useQuery(AGENT_AUDITS, {
    variables: {
      userId: user?.id,
    },
  });

  useEffect(() => {
    document.title = "Audit Log";
  }, []);

  const { audits } = data || {};

  const list = useMemo(() => {
    return audits && audits.edges
      ? audits.edges
        .filter(isNotNull)
        .map((edge: any) => edge.node)
        .filter(isNotNull)
      : [];
  }, [audits]);

  const LoadMore = () => (
    <div className={classes.rootButton}>
      {audits?.pageInfo?.hasNextPage && (
        <Chip
          onClick={() =>
            fetchMore({
              variables: {
                after: audits?.pageInfo?.endCursor,
              },
            })
          }
          label="Load More"
          className={classes.chip}
          variant="outlined"
        />
      )}
    </div>
  );

  return (
    <Page className={classes.root} title="Audits">
      <Container maxWidth={false}>
        <AuditToolbar />
        <Grid className={classes.gridList} container direction="row">
          <Hidden smDown>
            <ListItem
              className={classes.gridHeading}
              key={1}
              style={{ width: "100%" }}
            >
              <Grid item md={2}>
                <ListSubheader disableGutters component="div">
                  Entity
                </ListSubheader>
              </Grid>

              <Grid item md={2}>
                <ListSubheader disableGutters component="div">
                  Action
                </ListSubheader>
              </Grid>

              <Grid item md={2}>
                <ListSubheader disableGutters component="div">
                  User
                </ListSubheader>
              </Grid>

              <Grid item md={2}>
                <ListSubheader disableGutters component="div">
                  Customer Id
                </ListSubheader>
              </Grid>

              <Grid item md={3}>
                <ListSubheader disableGutters component="div">
                  Date
                </ListSubheader>
              </Grid>
            </ListItem>
          </Hidden>

          {list!.length ? (
            list!.map((node: any) => {
              if (node == null) {
                return null;
              }
              return <AuditItem audit={node} key={node.id} />;
            })
          ) : (
            <Grid
              className={classes.gridEmptyRecords}
              container
              direction="column"
              item
              xs={12}
            >
              No audits found
            </Grid>
          )}
        </Grid>
        <LoadMore />
      </Container>
    </Page>
  );
};

export default AuditList;
