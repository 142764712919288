import { useState, useCallback } from 'react';
import { useQuery } from '@apollo/client';

const usePaginationQuery = (query: any, initialVariables: any, dataKey: any) => {
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(10);
  const [searchQuery, setSearchQuery] = useState<any>({ searchText: "" });

  // Merging initial variables with pagination and search parameters
  const variables = {
    ...initialVariables,
    count,
    search: searchQuery.searchText,
  };

  const { data, loading, refetch } = useQuery(query, { variables });

  const getRefetchVariables = useCallback((change: any) => {
    const pageInfo = data[dataKey]?.pageInfo;
    if (change === "forward") {
      return {
        ...variables,
        startcursor: null,
        endcursor: pageInfo?.endCursor || null,
      };
    } else {
      return {
        ...variables,
        count: undefined,
        reversecount: count,
        startcursor: pageInfo?.startCursor || null,
        endcursor: null,
      };
    }
  }, [data, dataKey, count, variables]);

  const onPageChange = useCallback((event: any, newPage: any) => {
    const direction = newPage > page ? 'forward' : 'backward';
    refetch(getRefetchVariables(direction));
    setPage(newPage);
  }, [page, getRefetchVariables, refetch]);

  const onRowsPerPageChange = useCallback((event: any) => {
    setCount(parseInt(event.target.value, 10));
    setPage(0);  // Reset to the first page with new rows per page
    refetch({
      ...variables,
      count: parseInt(event.target.value, 10),
      startcursor: null,  // Optional: reset pagination on rows per page change
    });
  }, [setCount, refetch, variables]);

  return { data, loading, onPageChange, onRowsPerPageChange, setSearchQuery, setPage, page, count, refetch };
};

export default usePaginationQuery;
