import { useMemo, useState } from "react";
import { useEffect } from "react";
import { useStyles } from "./style";
import Container from "@material-ui/core/Container";
import { useMutation, useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";

import Page from "../../page/Page";
import Toolbar from "../toolbar/Toolbar";
import Searchbar, { CallbackStatus, SearchQuery } from "../searchbar/Searchbar";
import CallbackItem from "../list_item/ListItem";
import PendingItem from "../pendingItem/PendingItem";
import AssignedItem from "../assigned_item/AssignedItem";
import RejectedItem from "../rejected_item/RejectedItem";
import { CALLBACK_BUSINESS_ADMIN_REFETCH } from "adapters/queries/CallbackstBusinessAdminRefetch";
import CompletedItem from "../completed_item/CompletedItem";
import {
  Hidden,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card,
  Grid,
  TablePagination,
} from "@mui/material";
import NoteDialog from "../notes";
import AudioDialog from "../audio_modal/Modal";
import { GENERATE_JAAS_TOKEN } from "adapters/mutations/GenerateToken";


export function isNotNull<T>(it: T): it is NonNullable<T> {
  return it != null;
}

const Callbacks = (props: any): JSX.Element => {
  const classes = useStyles();
  const { user } = useSelector((state: RootState) => state.auth);
  const [showNote, setShowNote] = useState(false)
  const [noteNode, setNoteNode] = useState(false)
  const [showAudio, setShowAudio] = useState(false)
  const [audioNode, setAudioNode] = useState(false)
  const [noteCallbackId, setNoteCallbackId] = useState("")
  const [searchQuery, setSearchQuery] = useState<SearchQuery>({
    searchText: "",
    status: "",
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [callbackStatus, setCallbackStatus] = useState<string>("");
  const role = user?.role;
  let { customerId } = props.match.params;

  const [mutate, { error, data }] = useMutation(GENERATE_JAAS_TOKEN, {
    onCompleted: () => { },
    onError: () => {
      console.log(error?.message);
    },
  });

  useEffect(() => {
    mutate({
      variables: {
        input: {
          id: user?.id,
          name: user?.firstName + " " + user?.lastName,
          email: user?.email,
          moderator: "true",
          nbf: Math.round(
            new Date().setHours(
              new Date().getHours() - 12
            ) / 1000
          ),
          exp: Math.round(
            new Date().setHours(
              new Date().getHours() + 12
            ) / 1000
          ),
        },
      },
    });
  }, []);

  const { data: callbackRefetch, fetchMore, refetch } = useQuery(
    CALLBACK_BUSINESS_ADMIN_REFETCH,
    {
      variables: {
        ...(searchQuery.status != " " && { status: searchQuery?.status }),
        ...(searchQuery.searchText != " " && {
          search: searchQuery?.searchText,
        }),
        count: rowsPerPage,
        ...(customerId && { customerId: customerId }),
      },
    }
  );
  console.log("this is the data", callbackRefetch)

  useEffect(() => {
    fetchMore({
      variables: {
        ...(searchQuery.status != " " && { status: searchQuery?.status }),
        ...(searchQuery.searchText != " " && {
          search: searchQuery?.searchText,
        }),
        count: rowsPerPage,
        ...(customerId && { customerId: customerId }),
      },
    });
  }, [searchQuery, rowsPerPage]);

  useEffect(() => {
    document.title = "Callbacks";
  }, []);

  const callbackList = () => {
    const { callbacks } = callbackRefetch || {};
    return callbacks;
  };
  const callbacks = callbackList();
  const totalCount = callbacks?.totalCount || 0;
  const list = useMemo(() => {
    return callbacks && callbacks.edges
      ? callbacks.edges
        .filter(isNotNull)
        .map((edge: any) => edge.node)
        .filter(isNotNull)
      : [];
  }, [callbacks]);



  const refetchWithCursor = (change: string) => {
    change === "forward" && refetch({
      ...(searchQuery.status != " " && { status: searchQuery?.status }),
      ...(customerId && { customerId: customerId }),
      ...(searchQuery.searchText != " " && {
        search: searchQuery?.searchText,
        count: rowsPerPage,
        endcursor: callbacks.pageInfo?.endCursor,
        reversecount: undefined,
        startcursor: "",
      }),
    });

    change === "backward" && refetch({
      ...(searchQuery.status != " " && { status: searchQuery?.status }),
      ...(customerId && { customerId: customerId }),
      ...(searchQuery.searchText != " " && {
        search: searchQuery?.searchText,
        count: undefined,
        endcursor: null,
        reversecount: rowsPerPage,
        startcursor: callbacks.pageInfo?.startCursor,
      }),
    });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    if (newPage > page) {
      refetchWithCursor("forward");
    } else {
      refetchWithCursor("backward");
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Page className={classes.root} title="Callbacks">
      <Container maxWidth={false}>
        <Toolbar title="Callbacks" subTitle={searchQuery.status} />
        <Searchbar
          onSearch={setSearchQuery}
          role={role}
          callbackStatus={callbackStatus}
        />
        <Card sx={{ mt: 3 }}>
          <Table>
            <Hidden smDown>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Reason</TableCell>
                  <TableCell>Request Time</TableCell>
                  <TableCell>Assigned To</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
            </Hidden>

            <TableBody>
              {list!.length ? (
                list!.map((node: any) => {
                  if (node == null) {
                    return null;
                  }
                  if (node?.status == CallbackStatus.REQUEST) {
                    return (
                      <PendingItem
                        request={node}
                        key={node.id}
                        getAssignedList={() => {
                          setCallbackStatus(CallbackStatus.ASSIGNED);
                        }}
                        getPendingList={() => {
                          setCallbackStatus(CallbackStatus.REQUEST);
                        }}
                      />
                    );
                  }

                  if (node?.status == CallbackStatus.ASSIGNED) {
                    return (
                      <AssignedItem
                        assigned={node}
                        key={node.id}
                        getAssignedList={() => {
                          setCallbackStatus(CallbackStatus.ASSIGNED);
                        }}
                        getRejectedList={() => {
                          setCallbackStatus(CallbackStatus.REJECTED);
                        }}
                      />
                    );
                  }

                  if (node?.status == CallbackStatus.REJECTED) {
                    return <RejectedItem request={node} key={node.id} />;
                  }

                  if (node?.status == CallbackStatus.COMPLETE) {
                    return (
                      <CompletedItem
                        showIcon={
                          searchQuery?.status !== CallbackStatus.COMPLETE
                        }
                        callback={node}
                        key={node.id}
                        openNote={(id: string) => {
                          setNoteCallbackId(id)
                          setNoteNode(node)
                          setShowNote(true)
                        }}
                        openRecording={(node: any) => {
                          setAudioNode(node)
                          setShowAudio(true)
                        }}
                      />
                    );
                  }

                  return (
                    <CallbackItem
                      getCompletedList={() => {
                        setCallbackStatus(CallbackStatus.COMPLETE);
                      }}
                      callback={node}
                      key={node.id}
                    />
                  );
                })
              ) : (
                <Grid
                  className={classes.gridEmptyRecords}
                  container
                  direction="column"
                  item
                  xs={12}
                >
                  No callbacks found
                  {JSON.stringify(data)}
                </Grid>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
        {showNote && <NoteDialog open={showNote} onClose={() => { setShowNote(false) }} callbackId={noteCallbackId} node={noteNode} />}
        {showAudio && <AudioDialog open={showAudio} onClose={() => { setShowAudio(false) }} node={audioNode} />}
      </Container>
    </Page>
  );
};

export default Callbacks;
