import { Theme, createStyles, makeStyles } from "@material-ui/core";

const drawerWidth = 240;

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        avatar: {
            width: 60,
            height: 60
        },
        editIcon: {
            paddingLeft: theme.spacing(0.9)
        },
        appBar: {
            backgroundColor: "#7EC8E3",
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(
                ["width", "margin"],
                {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen
                }
            )
        },
        appBarShift: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(
                ["width", "margin"],
                {
                    easing: theme.transitions.easing.sharp,
                    duration:
                        theme.transitions.duration.enteringScreen
                }
            )
        },
        menuButton: {
            marginRight: theme.spacing(2)
        },
        hide: {
            display: "none"
        },
        title: {
            flexGrow: 1,
            fontWeight: 600,
            color: "#fff"
        }
    })
);
