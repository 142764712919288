import * as yup from "yup";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "./style";
import logo from "../../assets/images/guidizy-logo.png";
import { SIGNUP_USER } from "../../adapters/mutations/SignupUser";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import FormInput from "../../components/form/form_input/FormInput";
import { yupResolver } from "@hookform/resolvers/yup";
import CircularProgress from "../../components/facebook_progress/FacebookProgress";
import FormFeedback from "../../components/form_feedback/FormFeedback";
import { useContext, useEffect, useState } from "react";
import { SnackbarContext } from "components/common_snackbar/CommonSnackBar";
import PhoneInput from "components/form/phone_input/PhoneInput";
import Page from "components/page/Page";
import { Box, Card, CardContent, CardHeader, Container, Divider, Grid } from "@material-ui/core";
import CardActions from "@material-ui/core/CardActions";
import FormButton from "@material-ui/core/Button";
import LeftColumnCard from './LeftColumnCard';
import { useParams } from "react-router-dom"; 
import { useMediaQuery } from "@material-ui/core";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const nameRegex = /^(?=.*[A-Za-z])[A-Za-z0-9]+$/

const schema = yup.object().shape({
  firstName: yup.string().matches(nameRegex, 'Please enter valid first name')
    .max(40).required(),
  lastName: yup.string().matches(nameRegex, 'Please enter valid last name')
    .max(40).required(),
  email: yup.string().min(4).email().required(),
  phone: yup.string().required().matches(phoneRegExp, 'Phone number is not valid'),
  companyName: yup.string().required(),
  password: yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters')
    .max(15, 'Password must be at most 15 characters')
    .matches(/[A-Za-z]/, 'Password must contain at least one letter')
    .matches(/[0-9]/, 'Password must contain at least one number')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter'),
  confirmPassword: yup.string()
    .required("Confirm Password is required")
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .min(8, 'Password must be at least 8 characters')
    .max(15, 'Password must be at most 15 characters')
    .matches(/[A-Za-z]/, 'Password must contain at least one letter')
    .matches(/[0-9]/, 'Password must contain at least one number')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter'),
});

interface SignUpInput {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  password: string;
  companyName: string;
}

export default function SignUp() {
  const classes = useStyles();
  const { setSnack } = useContext(SnackbarContext);
  const [isSuccess, setIsSuccess] = useState(false)
  const [plan, setPlan] = useState("basic")
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  // Fetch plan from URL query parameter
  const { plan: urlPlan } = useParams<{ plan: string }>();

  useEffect(() => {
    if (urlPlan) {
      setPlan(urlPlan);
    } else {
      // Fallback to basic plan if no plan is provided in the URL
      setPlan("basic");
    }
  }, [urlPlan]);
  const isLargeScreen = useMediaQuery('(min-width:960px) and (min-height:760px)');

  const [mutate, { loading, error }] = useMutation(SIGNUP_USER, {
    onCompleted: ({ signUp }: any) => {
      console.log("signup", signUp);
      if (signUp && signUp.success) {
        setIsSuccess(true)
      }
    },
    onError: (error) => {
      console.log(error);
      setSnack({ message: "Signup failed", severity: 'error', open: true });
    },
  });

  const onSubmit = ({
    firstName,
    lastName,
    email,
    phone,
    password,
    companyName,
  }: SignUpInput) => {
    mutate({
      variables: {
        input: {
          email: email,
          firstName: firstName,
          lastName: lastName,
          companyName: companyName,
          phone: phone,
          password: password,
          pgwSubscriptionPlanId: plan
        },
      },
    });
  };

  if (isSuccess)
    return (
      <Page title="Message">
        <Container maxWidth="xs" className={classes.formContainer}>
          <Box p={1} m={1} justifyContent="center" textAlign="center">
            <img src={logo} alt="Guidizy logo" className={classes.logo} />
          </Box>
          <Card raised>
            <CardHeader title="Success" />
            <Divider />
            <CardContent>
              <Typography component="div" variant="body2">
                {" "}
                Your account has been created successfully. Please verify your email address to continue.
              </Typography>
            </CardContent>
          </Card>
        </Container>
      </Page>
    );

  return (
    <Box 
      style={{
        backgroundImage: `url('https://source.unsplash.com/random?wallpapers')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      
      <Grid container spacing={0} justify="center">
        <Grid item xs={12} md={6} style={{
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          minHeight: isLargeScreen ? '100vh' : 'auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative', 
          width: '100%', 
        }}>
          <LeftColumnCard plan={plan} />
        </Grid>


        <Grid item xs={12} md={6}>
          <Container maxWidth="xs" className={classes.formContainer}>
            <Card raised>
              <CardHeader title="Sign Up" className={classes.centerTitle} />
              <Divider />
              <form onSubmit={handleSubmit(onSubmit)}>
                <CardContent>
                  <FormInput
                    control={control}
                    type="text"
                    name="firstName"
                    label="First Name*"
                    error={errors?.firstName}
                    style={{ marginBottom: "12px" }} 
                  />
                  <FormInput
                    control={control}
                    type="text"
                    name="lastName"
                    label="Last Name*"
                    error={errors?.lastName}
                    style={{ marginBottom: "12px" }} 
                  />
                  <FormInput
                    control={control}
                    type="email"
                    name="email"
                    label="Email*"
                    error={errors?.email}
                    style={{ marginBottom: "12px" }} 
                  />
                  <FormInput
                    control={control}
                    type="text"
                    name="companyName"
                    label="Company Name*"
                    error={errors?.companyName}
                    style={{ marginBottom: "12px" }} 
                  />
                  <div className={classes.formInput}>
                    <PhoneInput
                      control={control}
                      name="phone"
                      error={errors && errors.phone}
                    />
                  </div>
                  <FormInput
                    control={control}
                    type="password"
                    name="password"
                    label="Password*"
                    error={errors?.password}
                    style={{ marginBottom: "12px" }} 
                  />
                  <FormInput
                    control={control}
                    type="password"
                    name="confirmPassword"
                    label="Confirm Password*"
                    error={errors?.confirmPassword}
                    style={{ marginBottom: "12px" }} 
                  />
                  {error && (
                    <FormFeedback className={classes.feedback} error>
                      {error.message}
                    </FormFeedback>
                  )}
                </CardContent>
                <Divider />
                <CardActions className={classes.centerButton}>
                  <div className={classes.buttonWrapper}>
                    <FormButton
                      className={`${classes.button} ${classes.input}`} 
                      type="submit"
                      color="secondary"
                      variant="contained"
                      fullWidth
                      disabled={loading}
                      style={{ width: '100%' }} 
                    >
                      {loading ? <CircularProgress size={24} /> : "Sign Up"}
                    </FormButton>
                  </div>
                </CardActions>
              </form>
            </Card>
          </Container>
        </Grid>
      </Grid>
    </Box>
  );
}