import { DialogContent, Stack, Typography } from '@mui/material'
import { useStyles } from './style';
import { useLazyQuery } from '@apollo/client';
import { GET_FEEDBACKS } from 'adapters/queries/GetFeedbacks';
import { useEffect, useState } from 'react';

interface FeedbackListProps {
    nodeId?: string
}

interface Feedback {
    type: string;
    customerId: string;
    description: string;
}

export default function FeedbackList(props: FeedbackListProps) {

    const classes = useStyles()
    const [feedbacks, setFeedbacks] = useState<Array<Feedback>>([])

    const [runQuery, { loading }] = useLazyQuery(GET_FEEDBACKS, {
        onCompleted: (data) => {
            console.log(data?.listByNodeId)
            if (data?.listByNodeId) {
                setFeedbacks(data?.listByNodeId)
            }

        },
    });

    useEffect(() => {
        if (props?.nodeId) {

            runQuery({
                variables: {
                    "input": {
                        "id": props.nodeId
                    }
                }
            })

        }
    }, [props?.nodeId])



    if (loading) {
        <div className={classes.emptyContainer}>Loading Feedbacks...</div>
    }


    return (
        <DialogContent dividers>
            {props.nodeId && feedbacks.length > 0 ? <div className={classes.container}>
                {feedbacks.filter(fback => fback.type == 'negative').map((feedback, index) => <div key={feedback.customerId + index} style={{
                    width: "100%", marginBottom: 50,
                    paddingLeft: 30, paddingRight: 30, padding: 20,
                    borderRadius: 10,
                    backgroundColor: 'rgba(255,0,0,0.2)'
                }}>
                    <Stack direction={'column'}>
                        <Typography variant='h6' >Customer Id: <Typography style={{ fontWeight: 'normal' }}>{feedback.customerId}</Typography></Typography>
                        <Typography variant='body2' color={'red'}>{feedback.description}</Typography>
                    </Stack>
                </div>)}
            </div> : <div className={classes.emptyContainer}>No Feedbacks Found</div>}
        </DialogContent>
    )
}
