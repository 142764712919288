import {
  CardActions,
  CardContent,
  CircularProgress,
  Divider, Button as FormButton
} from "@mui/material";

import FormFeedback from "../../../../components/form_feedback/FormFeedback";
import FormInput from "../../../../components/form/form_input/FormInput";
import { useStyles } from "./style";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { USER_BUSINESS_ADMIN_REFETCH } from "adapters/queries/UserBusinessRefetch";
import { EDIT_BUSINESS_ADMIN_COMPANY } from "adapters/mutations/EditBusinessAdminCompany";
import { useDispatch } from "react-redux";
import { useContext } from "react";
import { SnackbarContext } from "components/common_snackbar/CommonSnackBar";
import { updateUserCompany } from "redux/actions/AuthActions";
import { Address } from "utils/interfaces";

export default function EditAddressForm({ company }: any) {
  const classes = useStyles();
  const dispatch = useDispatch()
  const { setSnack } = useContext(SnackbarContext);
  const defaultValues = {
    ...company,
  };

  const [mutate, { loading, error }] = useMutation(
    EDIT_BUSINESS_ADMIN_COMPANY,
    {
      onCompleted: (res) => {
        dispatch(updateUserCompany(res.updateCompany.companyEdge.node))
        setSnack({ message: "Record Updated Successfully", severity: 'success', open: true });
      },
      onError: () => {
        setSnack({ message: "Editing company failed", severity: 'error', open: true });
      },
    }
  );

  const {
    handleSubmit,
    control,
  } = useForm({
    defaultValues,
  });

  const onSubmit = ({ correspondanceAddress, correspondanceCity, correspondanceCountry, correspondanceState }: Address) => {

    mutate({
      refetchQueries: [{ query: USER_BUSINESS_ADMIN_REFETCH }],
      variables: {
        input: {
          id: company?.id,
          address: {
            correspondanceAddress: correspondanceAddress || company.address.correspondanceAddress,
            correspondanceCity: correspondanceCity || company.address.correspondanceCity,
            correspondanceCountry: correspondanceCountry || company.address.correspondanceCountry,
            correspondanceState: correspondanceState || company.address.correspondanceState
          }
        },
      },
    });
  };


  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <CardContent>
          <FormInput
            required
            control={control}
            type="text"
            name="correspondanceAddress"
            label="Correspondance Address"
            fullWidth
            defaultValue={company?.address.correspondanceAddress}
          />
          <br />
          <br />
          <FormInput
            required
            control={control}
            type="text"
            name="correspondanceCity"
            label="Correspondance City"
            fullWidth
            defaultValue={company?.address?.correspondanceCity}
          />

          <br />
          <br />
          <FormInput
            required
            control={control}
            type="text"
            name="correspondanceCountry"
            label="Correspondance Country"
            fullWidth
            defaultValue={company?.address?.correspondanceCountry}
          />
          <br />
          <br />
          <FormInput
            required
            control={control}
            type="text"
            name="correspondanceState"
            label="Correspondance State"
            fullWidth
            defaultValue={company?.address?.correspondanceState}
          />
          <br />
          <br />
          {error ? (
            <FormFeedback className={classes.feedback} error>
              {error.message}
            </FormFeedback>
          ) : null}
        </CardContent>
        <Divider />
        <CardActions className={classes.buttonContainer}>
          <div className={classes.buttonWrapper}>
            <FormButton
              className={classes.button}
              type="submit"
              color="secondary"
              variant="contained"
              fullWidth
            >
              Update Record
            </FormButton>
            {loading && <CircularProgress className={classes.buttonProgress} />}
          </div>
        </CardActions>
      </form>
    </div>
  );
}
