import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Bar } from "react-chartjs-2";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { colors } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { useTheme } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Menu from "@mui/material/Menu";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Day from "./DatePicker";
import { Grid, MenuItem } from "@mui/material";
import { useLazyQuery } from "@apollo/client";
import { SESSION_WEEKLY_STATS } from "adapters/queries/DashboardBusinessAdmin";
import moment from "moment";
import { RootState } from "redux/reducers/rootReducer";
import { useSelector } from "react-redux";
import AgentActivityChart from "./AgentActivity";

interface Dataset {
  barThickness: number;
  maxBarThickness: number;
  barPercentage: number;
  categoryPercentage: number;
  backgroundColor: string;
  data: number[];
  label: string;
}

interface ChartState {
  datasets: Dataset[];
  labels: string[];
}

const formatDuration = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}m ${remainingSeconds}s`;
};

export default function Calls({ className, ...rest }: Record<string, any>) {
  const theme = useTheme();
  const { user } = useSelector((state: RootState) => state.auth);

  const [format, setFormat] = useState<ChartState>({
    datasets: [
      {
        barThickness: 12,
        maxBarThickness: 10,
        barPercentage: 0.5,
        categoryPercentage: 0.5,
        backgroundColor: colors.cyan[500],
        data: [],
        label: "Calls Answered",
      },
      {
        barThickness: 12,
        maxBarThickness: 10,
        barPercentage: 0.5,
        categoryPercentage: 0.5,
        backgroundColor: colors.red[200],
        data: [],
        label: "Calls Missed",
      },
    ],
    labels: [],
  });
  const [durations, setDurations] = useState<number[]>([]);
  const options = {
    animation: false,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      xAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary,
          },
          gridLines: {
            display: false,
            drawBorder: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0,
            stepSize: 5,
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider,
          },
        },
      ],
    },
    tooltips: {
      callbacks: {
        label: function(tooltipItem: any, data: any) {
          const datasetLabel = data.datasets[tooltipItem.datasetIndex].label || '';
          const count = tooltipItem.yLabel;
          if (datasetLabel === 'Calls Answered') {
            const duration = durations[tooltipItem.index];
            const formattedDuration = formatDuration(duration);
            return `${datasetLabel}: ${count} (${formattedDuration})`;
          }
          return `${datasetLabel}: ${count}`;
        }
      },
      

      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: "index",
      titleFontColor: theme.palette.text.primary,
    },
  };

  // Date Picker Code
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    // Method to close the popup
    setAnchorEl(null);
  };

  const [hoveredDay, setHoveredDay] = useState<Dayjs | null>(null);
  const [value, setValue] = useState<Dayjs | null>(dayjs());

  //query to get all company customers
  const [getWeeklyStats] = useLazyQuery(SESSION_WEEKLY_STATS, {
    onCompleted: (data) => {
      const items = data.sessionWeeklyStats.data;

      const counts: number[] = [];
      const durations: number[] = [];
      const labels: string[] = [];
      const missed: number[] = [];

      items.forEach((item: Record<string, any>) => {
        labels.push(moment(item.date).format("MMM Do"));
        counts.push(item.count);
        durations.push(item.duration);
        missed.push(item.missedCount);
      });

      // Update the state
      setFormat((prevFormat) => {
        const updatedDatasets = [
          { ...prevFormat.datasets[0], data: counts },
          { ...prevFormat.datasets[1], data: missed },
          ...prevFormat.datasets.slice(2), // Keep the other datasets unchanged
        ];

        return {
          ...prevFormat,
          datasets: updatedDatasets,
          labels: labels,
        };
      });
      setDurations(durations);
    },
  });

  // Use useEffect to trigger the query when the component mounts
  useEffect(() => {
    // Fetch the data when the component mounts
    getWeeklyStats({
      variables: {
        companyId: user?.company?.id,
        selectedDate: value,
        ...(user?.role === "agent" ? { agentId: user?.id } : {}),
      },
    });
  }, []);

  function showCallsSummary() {
    handleClose();
    getWeeklyStats({
      variables: {
        companyId: user?.company?.id,
        selectedDate: value,
        ...(user?.role === "agent" ? { agentId: user?.id } : {}),
      },
    });
  }

  return (
    <Grid container>
      <Grid item md={12} xl={11} lg={11}>
        <Card className={clsx(className)} {...rest}>
          <Menu
            anchorEl={anchorEl}
            id="date-picker"
            open={open}
            onClose={handleClose}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateCalendar
                maxDate={dayjs()}
                value={value}
                onChange={(
                  newValue: React.SetStateAction<dayjs.Dayjs | null>
                ) => setValue(newValue)}
                showDaysOutsideCurrentMonth
                slots={{ day: Day }}
                slotProps={{
                  day: (ownerState: {
                    day: React.SetStateAction<dayjs.Dayjs | null>;
                  }) =>
                    ({
                      selectedDay: value,
                      hoveredDay,
                      onPointerEnter: () => setHoveredDay(ownerState.day),
                      onPointerLeave: () => setHoveredDay(null),
                    } as any),
                }}
              />
            </LocalizationProvider>
            <MenuItem>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={showCallsSummary}
              >
                Show
              </Button>
            </MenuItem>
          </Menu>
          <CardHeader
            action={
              <Button
                onClick={handleClick}
                endIcon={<ArrowDropDownIcon />}
                size="small"
                variant="text"
                aria-controls={open ? "date-picker" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                Select a week
              </Button>
            }
            title="Calls Summary"
          />
          <Divider />
          <CardContent>
            <Box height={400} position="relative">
              <Bar data={format} options={options} height={400} />
            </Box>
          </CardContent>
        </Card>

        {/* Agent activity */}
        {user?.role === "manager" || user?.role === "owner" ? (
          <AgentActivityChart className={clsx(className)} {...rest} />
        ) : (
          ""
        )}
      </Grid>
    </Grid>
  );
}
