import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_TICKET_BY_ID } from 'adapters/queries/retrieveTicket';
import CustomerTicketDetails from './form';

const EditTickets: React.FC = () => {
  // const { setSnack } = useContext(SnackbarContext);
  // const [loading, setLoading] = useState(false);
  const { id } = useParams<{ id: string }>();
  console.log("this is ticket id :", id)

  // Fetch the ticket by its ID
  const { loading: ticketLoading, data: ticketData } = useQuery(GET_TICKET_BY_ID, {
    variables: { ticket: { id } },
  });

  useEffect(() => {
  }, []);


  if (ticketLoading) {
    return <p>Loading...</p>;
  }

  if (!ticketData || !ticketData.retrieveTicket) {
    return <p>Ticket not found</p>;
  }

  const { retrieveTicket: ticket } = ticketData;

  return (
    <CustomerTicketDetails ticket={ticket} />
  );
}

export default EditTickets;