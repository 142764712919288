import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import { useEffect } from 'react';
import { useStyles } from './style';
import { useDispatch } from 'react-redux';
import { updateSession } from '../../redux/actions/AgentSessionActions';

export interface CallAlertProps {
    open: boolean;
    onReject: () => void;
    message?: string;
}

function CallAlert(props: CallAlertProps) {
    const { onReject, open, message } = props;
    const ringtone = new Audio("https://codeskulptor-demos.commondatastorage.googleapis.com/GalaxyInvaders/theme_01.mp3")
    const roomId = localStorage.getItem("chatRoomId")
    const token = localStorage.getItem("jitsi_token");
    const dispatch = useDispatch()

    const classes = useStyles();

    useEffect(() => {
        if (open) {
            ringtone.play()
        }
        else {
            ringtone.pause()
        }


        return () => {
            ringtone.pause()
        }
    }, [open]);

    return (
        <Dialog open={open} >
            <DialogTitle>
                <Typography>Incoming Call</Typography>
            </DialogTitle>
            <DialogContent dividers>
                <Box component={'div'} className={classes.contentContainer}>
                    <AccountCircle style={{ fontSize: 100 }} color='disabled' />
                    {message ? <Typography className={classes.message}>{message}</Typography> : null}
                </Box>
            </DialogContent>
            <DialogActions style={{ justifyContent: "space-around" }}>
                <Button variant='contained' color='primary'
                    onClick={() => {
                        window.open((roomId && token) ? `/meeting/${roomId.replace("==", "").toLowerCase()}/${token}` : '/dashboard', "_blank");
                        dispatch(updateSession({ agentStatus: 'busy', isCallOn: true }))
                        onReject()
                    }}>
                    Accept
                </Button>
                <Button variant='contained' color='inherit' onClick={() => {
                    onReject()
                }}>
                    Reject
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default CallAlert