import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import Page from "components/page/Page";
import DoneIcon from "@material-ui/icons/Done";
import PlanButton from "./PlanButton";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";
import { useStyles } from "./style";
import { useMutation, useQuery } from "@apollo/client";
import { COMPANY_BUSINESS_ADMIN_VIEW } from "adapters/queries/CompanyBusinessAdminView";
import { useHistory } from "react-router-dom"
import { CANCEL_SUBSCRIPTION_COMPANY } from "adapters/mutations/CancelSubscriptionCompany";
import { JSXElementConstructor, Key, ReactElement, ReactNode, ReactPortal, useContext, useState } from "react";
import { SnackbarContext } from "components/common_snackbar/CommonSnackBar";
import Modal from "components/modal/Modal";
import { GET_ALL_SUBSCRIPTIONS } from "adapters/queries/GetAllSubscriptions";

const basic = [
  "2000 Guidizy Minutes",
  "Screen sharing",
  "Call Forwarding",
  "Business hours",
  "Customer support",
];

const plus = [
  "5000 Guidizy Minutes",
  "Screen sharing",
  "Call Forwarding",
  "Business hours",
  "Customer support",
  "Call Recording",
];

const premium = [
  "15000 Guidizy Minutes",
  "Screen sharing",
  "Call Forwarding",
  "Business hours",
  "Customer support",
  "Call Recording",
  "Custom branding",
];

const descriptions = {
  basic,
  plus,
  premium
}

function ChangeSubscription() {
  const classes = useStyles();
  const { user } = useSelector((state: RootState) => state.auth);
  const history = useHistory()
  const { setSnack } = useContext(SnackbarContext);
  const { data: company } = useQuery(COMPANY_BUSINESS_ADMIN_VIEW, {
    variables: {
      id: user?.companyId
    }
  })

  const { data: subscriptions } = useQuery(GET_ALL_SUBSCRIPTIONS, {})

  let currentPlan = company?.company?.pgwSubscriptionPlanId

  const handleClick = (name: string) => {
    history.push(`/pay/${name}`);
  };

  const [cancelSubscription] = useMutation(CANCEL_SUBSCRIPTION_COMPANY, {
    onCompleted: () => {
      setSnack({ message: "Subscription successfully cancelled", severity: 'success', open: true });
    },
    onError: () => {
      setSnack({ message: "Could not cancel subscription. Please try again later", severity: 'error', open: true });
    },
  });

  const handleCancelSubscription = () => {
    cancelSubscription()
    setIsOpen(false);
  }

  const [isOpen, setIsOpen] = useState(false);

  const handleDialogOpen = () => {
    setIsOpen(true);
  };
  const handleDiaglogClose = () => {
    setIsOpen(false);
  };

  return (
    <Page title="Change Plan">
      <div className={classes.root}>
        <Grid container spacing={5}>
          {subscriptions?.getSubscriptions && subscriptions?.getSubscriptions.map((item: any) =>
            <Grid item xs key={item.id}>
              <Paper className={classes.paper}>
                <Typography variant="h5" className={classes.planName}>
                  {item.name}
                </Typography>
                <Typography variant="h5" className={classes.charge}>
                  £{item.price}
                </Typography>
                <PlanButton
                  name={
                    currentPlan == item.name ? "Current plan" : "Choose this plan"
                  }
                  isCurrent={currentPlan == item.name}
                  handleClick={() => handleClick(item?.name)}
                />
                {
                  currentPlan === item.name ?
                    <>
                      <PlanButton
                        name="Cancel"
                        handleClick={handleDialogOpen}
                      />
                    </> : <></>
                }
                {//@ts-ignore
                  descriptions[`${item.name}`].map((item: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined, index: Key | null | undefined) => (
                    <div key={index} className={classes.featureWrapper}>
                      <DoneIcon className={classes.doneIcon} />
                      <Typography className={classes.features}>{item}</Typography>
                    </div>
                  ))}
              </Paper>
            </Grid>)}


        </Grid>
        <Modal
          isOpen={isOpen}
          handleClose={handleDiaglogClose}
          handleConfirm={handleCancelSubscription}
          title="Cancel subscription"
          content="Are you sure you want to cancel subscription? Your subscription will be canceled at the end of the biling cycle and you will not be charged there after" />
      </div>
    </Page>
  );
}

export default ChangeSubscription