import { gql } from "@apollo/client";

export const LIST_BOTPROFILES = gql`
    fragment List_botprofiles on Query {
        botprofiles(first: $count, after: $cursor) {
            edges {
                node {
                    id
                    name
                    company {
                        name
                        id
                    }
                    createdAt
                    __typename
                }
                cursor
            }
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
            totalCount
        }
    }
`;
