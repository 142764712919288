import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import { BOTPROFILE_ADMIN_VIEW } from "../../../../adapters/queries/BotProfileAdminView";
import Page from "../../../../components/page/Page";
import { useQuery } from "@apollo/client";

export default function ViewBotProfile(props: any) {
  const { id } = props.match.params;
  const { data } = useQuery(BOTPROFILE_ADMIN_VIEW, {
    variables: { id },
  });

  const botprofile = data?.botprofile.company;

  if (!botprofile) return null;

  const priorities = ["Low", "Medium", "High"];

  return (
    <Page title="BotProfile View">
      <Container maxWidth={false}>
        <Card>
          <CardHeader subheader={botprofile?.name!} title="BotProfile View" />
          <Divider />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                {" "}
                Company:{" "}
              </Grid>
              <Grid item xs={8}>
                {" "}
                {botprofile?.company?.name!}{" "}
              </Grid>
              <Grid item xs={4}>
                {" "}
                Profile Name:{" "}
              </Grid>
              <Grid item xs={8}>
                {" "}
                {botprofile.name}{" "}
              </Grid>
              <Grid item xs={4}>
                {" "}
                Priority:{" "}
              </Grid>
              <Grid item xs={8}>
                {" "}
                {botprofile.priority
                  ? priorities[botprofile.priority]
                  : "Low"}{" "}
              </Grid>
              <Grid item xs={4}>
                {" "}
                Video Call:{" "}
              </Grid>
              <Grid item xs={8}>
                {" "}
                {botprofile?.isWebRTCVideoRequired
                  ? "Available"
                  : "Not Available"}{" "}
              </Grid>
              <Grid item xs={4}>
                {" "}
                Screen Sharing:{" "}
              </Grid>
              <Grid item xs={8}>
                {" "}
                {botprofile.isScreenSharingRequired
                  ? "Available"
                  : "Not Available"}{" "}
              </Grid>
              <Grid item xs={4}>
                {" "}
                Call Recording:{" "}
              </Grid>
              <Grid item xs={8}>
                {" "}
                {botprofile.callRecording ? "Available" : "Not Available"}{" "}
              </Grid>
              <Grid item xs={4}>
                {" "}
                Forward to Landline:{" "}
              </Grid>
              <Grid item xs={8}>
                {" "}
                {botprofile.isForwardToPhoneRequired
                  ? "Available"
                  : "Not Available"}{" "}
              </Grid>
              <Grid item xs={4}>
                {" "}
                Description:{" "}
              </Grid>
              <Grid item xs={8}>
                {" "}
                {botprofile.body}{" "}
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
        </Card>
      </Container>
    </Page>
  );
}
