//import { useState } from "react";
import { format } from "ts-date/esm/locale/en";
//import { useStyles } from './style';
// import ListItem from '@material-ui/core/ListItem';
import { CallbacksPlaceHolders } from "../searchbar/Searchbar";
import { CheckCircle, NoteAdd, RecordVoiceOver } from '@mui/icons-material';
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

//import { IconButton } from '@material-ui/core';
import IconButton from "@mui/material/IconButton";
import { TableRow, TableCell, Hidden, Grid, Typography } from "@mui/material";

export default function CompletedItem(props: any) {
  //const classes = useStyles();
  //const [checked, setChecked] = useState([0]);

  // const handleToggle = (value: number) => () => {
  //   const currentIndex = checked.indexOf(value);
  //   const newChecked = [...checked];

  //   if (currentIndex === -1) {
  //     newChecked.push(value);
  //   } else {
  //     newChecked.splice(currentIndex, 1);
  //   }

  //   setChecked(newChecked);
  // };

  const dateStringRequest = new Date(props?.callback?.createdAt!).toISOString();
  const dateStringComplete = new Date(
    props?.callback?.callbackDate!
  ).toISOString();
  let requestedDate: any;
  requestedDate = "";
  if (dateStringRequest) {
    requestedDate = format(
      new Date(dateStringRequest.substr(0, 19)),
      "DD MMM, YYYY - hh:mm A"
    );
  }
  let completedDate: any;
  completedDate = "";
  if (dateStringComplete) {
    completedDate = format(
      new Date(dateStringComplete.substr(0, 19)),
      "DD MMM, YYYY - hh:mm A"
    );
  }

  return (
    <TableRow hover style={{ flexDirection: 'row', alignItems: 'center' }}>
      <Hidden smDown>
        <TableCell>
          {props?.callback?.customer?.firstName! +
            " " +
            props?.callback?.customer?.lastName!}
          <br />
          {props?.callback?.customer?.email!}
          <br />
          {props?.callback?.customer?.phone!}
        </TableCell>
        <TableCell>{CallbacksPlaceHolders(props?.callback?.status)}</TableCell>
        <TableCell>{props?.callback?.reason}</TableCell>
        <TableCell>{requestedDate}</TableCell>
        <TableCell>
          {props?.callback?.assignee?.firstName! +
            " " +
            props?.callback?.assignee?.lastName!}
        </TableCell>
        <TableCell>
          {props?.showIcon ? (
            // <ListItemSecondaryAction>
            <>
              <IconButton
                // style={{ backgroundColor: 'red' }}
                disabled={true}
                onClick={() => { }}
                aria-label="assign"
                aria-haspopup="true"
              >
                <CheckCircle htmlColor="green" />
              </IconButton>
              <IconButton
                onClick={() => { props.openNote(props?.callback?.id) }}
                aria-label="assign"
                aria-haspopup="true"
              >
                <NoteAdd color="primary" />
              </IconButton>
              <IconButton
                onClick={() => { props.openRecording(props?.callback) }}
                aria-label="assign"
                aria-haspopup="true"
              >
                <RecordVoiceOver color="primary" />
              </IconButton>
            </>
            // </ListItemSecondaryAction>
          ) : (
            completedDate
          )}
        </TableCell>
      </Hidden>

      <Hidden smUp>
        <Grid container spacing={0} style={{ position: "relative", padding: "16px" }}>
          <Grid item xs={12}>
            <Typography variant="body2">
              {props?.callback?.customer?.firstName! +
                " " +
                props?.callback?.customer?.lastName!}
              <br />
              {props?.callback?.customer?.email!}
              <br />
              {props?.callback?.customer?.phone!}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              {CallbacksPlaceHolders(props?.callback?.status)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">{props?.callback?.reason}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">{requestedDate}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              {props?.callback?.assignee?.firstName! +
                " " +
                props?.callback?.assignee?.lastName!}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            container
            justifyContent="space-between"
            alignItems="center"
          >
            {props?.showIcon ? (
              <ListItemSecondaryAction>
                <IconButton
                  disabled={true}
                  onClick={() => { }}
                  aria-label="assign"
                  aria-haspopup="true"
                >
                  <CheckCircle htmlColor="green" />
                </IconButton>
                <IconButton
                  onClick={() => { props.openNote(props?.callback) }}
                  aria-label="assign"
                  aria-haspopup="true"
                >
                  <NoteAdd color="primary" />
                </IconButton>
              </ListItemSecondaryAction>
            ) : (
              <Typography>{completedDate}</Typography>
            )}
          </Grid>
        </Grid>
      </Hidden>
    </TableRow>
  );
}
