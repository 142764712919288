import {
    Avatar,
    Box,
    Card,
    CardContent,
    Grid,
    Typography
} from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import MoneyIcon from "@material-ui/icons/Money";
import { useStyles } from "./style";

const Budget = (props: any) => {
    const classes = useStyles();
    return (
        <Card sx={{ height: "100%" }} {...props}>
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item>
                        <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                            className={classes.text}
                        >
                            BUDGET
                        </Typography>
                        <Typography
                            color="textPrimary"
                            variant="h3"
                            className={classes.text}
                        >
                            $24,000
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Avatar>
                            <MoneyIcon />
                        </Avatar>
                    </Grid>
                </Grid>
                <Box
                    sx={{
                        pt: 2,
                        display: "flex",
                        alignItems: "center"
                    }}
                >
                    <ArrowDownwardIcon />
                    <Typography
                        variant="body2"
                        className={classes.text}
                    >
                        12%
                    </Typography>
                    <Typography
                        color="textSecondary"
                        variant="caption"
                        className={classes.text}
                    >
                        Since last month
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    );
};

export default Budget;
