import { AuthActionTypes, LOGIN_FAIL, LOGIN_START, LOGOUT, LOGIN_SUCCESS, User, USER_STATUS_CHANGE, UPDATE_USER_COMPANY, UPDATE_USER, UPDATE_USER_STATUS } from '../actions/AuthActions';


interface IAuthState {
    user: User | null,
    isAuthenticated: boolean,
    token: null | string,

}

const initialState: IAuthState = {
    isAuthenticated: false,
    user: null,
    token: null,
}


export const AuthReducer = (state = initialState, action: AuthActionTypes): IAuthState => {
    switch (action.type) {
        case LOGIN_START:
            return {
                ...state,
                isAuthenticated: false,
                user: null,
                token: null,
            }
        case LOGIN_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload,
                token: action.payload.token,
            }
        case UPDATE_USER_COMPANY:
            return {
                ...state,
                user: {
                    //@ts-ignore
                    ...state.user, company: {
                        ...state.user?.company,
                        name: action.payload.name,
                        subdomain: action.payload.subdomain
                    }
                }
            }
        case UPDATE_USER:
            return {
                ...state,
                //@ts-ignore
                user: {
                    ...state.user,
                    firstName: action.payload.firstName,
                    lastName: action.payload.lastName,
                    role: action.payload.role
                }

            }
        case UPDATE_USER_STATUS:
            return {
                ...state,
                //@ts-ignore
                user: {
                    ...state.user,
                    ...(action?.payload?.status && { status: action.payload.status }),
                    ...(action?.payload?.role && { status: action.payload.status })
                }

            }
        case USER_STATUS_CHANGE:
            if (state.user != null) {
                return {
                    ...state,
                    user: {
                        ...state.user,
                        status: 1
                    }
                }
            } else {
                return state
            }
        case LOGIN_FAIL:
            return {
                ...state,
                isAuthenticated: false,
                user: null,
                token: null,
            }
        case LOGOUT:
            return {
                ...state,
                isAuthenticated: false,
                user: null,
                token: null,
            }
        default:
            return state;
    }
}

export default AuthReducer;