import { gql } from "@apollo/client";
import { LIST_BOTPROFILES } from "../fragments/ListBotProfiles";

export const BOTPROFILE_REFETCH = gql`
    ${LIST_BOTPROFILES}
    query BotProfileFragmentRefetchQuery(
        $count: Int = 10
        $cursor: String
    ) {
        ...List_botprofiles
    }
`;
