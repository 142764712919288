import { useState } from "react";
import * as React from "react";
import { format } from "ts-date/esm/locale/en";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Menu from "@material-ui/core/Menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "./style";
import { Link } from "react-router-dom";
import { MenuItem } from "@material-ui/core";

interface IProps {
  botprofile?: any;
}

export default function BotProfileItem(props: IProps) {
  const classes = useStyles();
  const [checked, setChecked] = useState([0]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ListItem
      className={classes.listItem}
      key={props.botprofile.id}
      button
      onClick={handleToggle(props.botprofile.id)}
    >
      <Grid container item direction="row">
        <Grid item md={4} sm={5} xs={11}>
          <Typography>{props.botprofile.name}</Typography>
        </Grid>

        <Grid item md={3} sm={5} xs={11}>
          <Typography>
            {props.botprofile!.company
              ? props.botprofile?.company?.name!
              : " Not available"}
          </Typography>
        </Grid>

        <Grid item md={4} sm={5} xs={11}>
          <Typography>
            {format(
              new Date(props?.botprofile?.createdAt!),
              "DD MMM, YYYY hh:mm a"
            )}
          </Typography>
        </Grid>

        <Grid item md={1} sm={2} xs={1}>
          <ListItemSecondaryAction>
            <IconButton
              aria-label="Actions"
              aria-controls="actions-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>

            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={handleClose}
                component={Link}
                to={`/admin/bot-profile/view/${props.botprofile.id}`}
              >
                View
              </MenuItem>
            </Menu>
          </ListItemSecondaryAction>
        </Grid>
      </Grid>
    </ListItem>
  );
}
