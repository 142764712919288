import { gql } from "@apollo/client";

export const GET_USER_DETAILS = gql`
query User($userId: String!) {
  user(id: $userId) {
    companyId
    email
    firstName
    id
    lastName
    role
    status
    company {
      id
      name
      type
      subdomain
      pgwSubscriptionPlanId
      subscriptionStatus
      address
      maxAllowedAgents
      consumedActiveAgents
      consumedMinutes
      maxAllowedMinutes
      maxAllowedProfiles
      consumedProfiles
      vat
      plan {
          id
          name
          priceId
      }
    }
  }
}   `

