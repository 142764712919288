import * as React from "react";
import Spinner from "../../facebook_progress/FacebookProgress";
import TopBar from "./topbar/TopBar";
import NavBar from "./navbar";
import { useStyles } from "./style";
import CommonAppBarAlert from "components/common_appbar_alert/CommonAppBarAlert";
import CheckSubscription from "components/common_appbar_alert/CheckSubscription";

interface IProps {
  data?: any | null;
  error?: any | null;
  children?: React.ReactNode | null;
}

export default function BusinessAdminLayout(props: IProps) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [isMobileNavOpen, setMobileNavOpen] = React.useState(false);

  React.useEffect(() => {
    console.log(isMobileNavOpen, "mobile nav status");
  }, [isMobileNavOpen]);

  return (
    <div className={classes.root}>
      <TopBar
        onMobileNavOpen={() => setMobileNavOpen(true)}
        setOpen={setOpen}
        isOpen={open}
      />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
        setOpen={setOpen}
        isOpen={open}
      />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <CommonAppBarAlert>
          <CheckSubscription>
            <React.Suspense fallback={<Spinner />}>
              {props.children}
            </React.Suspense>
          </CheckSubscription>
        </CommonAppBarAlert>
      </main>
    </div>
  );
}
