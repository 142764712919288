import React from "react";
import { TableCell, TableRow } from "@mui/material";
import { useHistory } from "react-router-dom";

interface Ticket {
  id: string;
  reporterId: string | null;
  reporterType: string;
  location: string;
  subject: string;
  company: string;
  description: string;
  priority: string;
  code: string | null;
  
}

interface TicketItemProps {
  ticket: Ticket;
}

const TicketItem: React.FC<TicketItemProps> = ({ ticket }) => {
  const { id, location, subject, company, description, priority, code } = ticket;
  const history = useHistory();

  return (
    <TableRow style={{ cursor: 'pointer' }} hover key={id} onClick={() => history.push(`/admin/ticket_edit/${id}`)}>
      <TableCell>{location}</TableCell>
      <TableCell>{subject}</TableCell>
      <TableCell>{company}</TableCell>
      <TableCell>{description}</TableCell>
      <TableCell>{priority}</TableCell>
      <TableCell>{code}</TableCell>
    </TableRow>
  );
};

export default TicketItem;
