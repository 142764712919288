import clsx from "clsx";
import CardHeader from "@material-ui/core/CardHeader";

import { useStyles } from "./style";

interface IProps {
  className?: string;
}

const Toolbar = ({ className, ...rest }: IProps) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <CardHeader
        title="Event Log"
        className={classes.cardHeader}
        titleTypographyProps={{
          className: classes.cardTitle,
        }}
      />
    </div>
  );
};

export default Toolbar;
