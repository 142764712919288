import clsx from "clsx";
// import { Link } from "react-router-dom";
// import Button from "@material-ui/core/Button";
// import CardHeader from "@material-ui/core/CardHeader";

// import AddIcon from "@material-ui/icons/Add";
import { useStyles } from "./style";
// import { IconButton } from "@material-ui/core";
// import Warning from "@material-ui/icons/Warning";
import { Button, CardHeader, IconButton, Stack } from "@mui/material";
import { Add, Warning } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";


interface IProps {
  className?: string;
  showWarning?: boolean;
  maxUsersReached?: boolean;
  showAlert?: () => void;
}

const Toolbar = ({ className, showWarning, maxUsersReached , showAlert, ...rest }: IProps) => {
  const classes = useStyles();
  const { user } = useSelector((state: RootState) => state.auth);


  return (
    <Stack
      sx={{pr : 2}}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader
        title={
          <div>
            Tickets
            {showWarning && (
              <IconButton onClick={showAlert}>
                <Warning color="error" />
              </IconButton>
            )}
          </div>
        }
        className={classes.cardHeader}
        titleTypographyProps={{
          className: classes.cardTitle,
        }}
      />
      <div>
        {maxUsersReached ? (
          <Button
            className={classes.addButton}
            color="primary"
            variant="contained"
            size="large"
            startIcon={<Add />}
            onClick={showAlert}
          >
            Add
          </Button>
        ) : (
          <Button
            className={classes.addButton}
            color="primary"
            variant="contained"
            size="large"
            startIcon={<Add />}
            component={Link}
            to={user?.role == "admin" ? "/admin/tickets/create" : "/tickets/add"}
          >
            Add
          </Button>
        )}
      </div>
    </Stack>
  );
};

export default Toolbar;
