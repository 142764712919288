import { useState } from "react";
import { format } from "ts-date/esm/locale/en";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "./style";

export default function AuditItem(props: any) {
  const classes = useStyles();
  const [checked, setChecked] = useState([0]);

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return (
    <ListItem
      className={classes.listItem}
      key={props.audit.id}
      button
      onClick={handleToggle(props.audit.id)}
    >
      <Grid container item direction="row">
        <Grid item md={2} sm={6} xs={12}>
          <Typography>{props.audit.entity}</Typography>
        </Grid>

        <Grid item md={2} sm={6} xs={12}>
          <Typography>{props.audit.action}</Typography>
        </Grid>

        <Grid item md={2} sm={6} xs={12}>
          <Typography>{props.audit?.user?.firstName!}</Typography>
        </Grid>

        <Grid item md={2} sm={6} xs={12}>
          <Typography>{props.audit?.customerId!}</Typography>
        </Grid>

        <Grid item md={2} sm={6} xs={12}>
          <Typography>
            {format(new Date(props?.audit?.createdAt!), "DD MMM, YYYY hh:mm a")}
          </Typography>
        </Grid>
      </Grid>
    </ListItem>
  );
}
