import { Accordion, AccordionDetails, AccordionSummary, Box, Button, LinearProgress, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useStyles } from './style';
import { ExpandMoreOutlined } from '@mui/icons-material';
import { useMutation, useQuery } from '@apollo/client';
import { UPDATE_BOT_FRAMEWORK } from 'adapters/mutations/EditBotFramework';
import { BOT_PROFILE_LANG } from 'adapters/queries/BotProfileFetch';
import { BOT_FRAME_WORK_TRANS } from 'adapters/queries/BotFrameworkTrans';

interface WelcomeMessageProps {
    companyId: string;
    botProfileId: string;
    welcomeMessage?: string;
    frmeworkId?: string;
}

export function isNotNull<T>(it: T): it is NonNullable<T> {
    return it != null;
}

const WelcomeMessage = (props: WelcomeMessageProps) => {
    const classes = useStyles();
    console.log("welcomeMessage", props.welcomeMessage);

    const [welcomeMessage, setWelcomeMessage] = useState<string>(props.welcomeMessage ? props.welcomeMessage : '');
    const [translatedMessages, setTranslatedMessages] = useState<{ [key: string]: string }>({});
    const [error, setError] = useState<string | undefined>('');

    const { data: LangData } = useQuery(BOT_PROFILE_LANG, {
        variables: {
            botprofileId: props.botProfileId
        }
    });

    useQuery(BOT_FRAME_WORK_TRANS, {
        variables: {
            botFrameworkByBotProfileIdId: props.botProfileId
        },
        onCompleted: (data) => {
            const translations = JSON.parse(data.botFrameworkByBotProfileId.welcomeMessageTranslation || '{}');
            setTranslatedMessages(translations);
        }
    });

    console.log("LangData", LangData);
    console.log("botProfileId", props.botProfileId);

    useEffect(() => {
        setWelcomeMessage(props.welcomeMessage ? props.welcomeMessage : '');
    }, [props.welcomeMessage]);

    const [editFaq, { loading: editFrameworkLoading, error: editFrameworkError }] = useMutation(UPDATE_BOT_FRAMEWORK, {
        onCompleted: (res) => {
            console.log(res);
        },
        onError: () => {
            console.log(editFrameworkError);
            setError(editFrameworkError?.message);
        },
    });

    const primaryLanguage = LangData?.botprofile.primaryLanguage;
    const languages = JSON.parse(LangData?.botprofile.languages || '[]');

    const handleTranslate = (language: string) => {
        const translatedMessage = `Translated`;
        setTranslatedMessages((prevMessages) => ({
            ...prevMessages,
            [language]: translatedMessage,
        }));
    };

    const handlePrimaryLanguageChange = (text: string) => {
        setWelcomeMessage(text);
        setTranslatedMessages((prevMessages) => {
            const updatedMessages = { ...prevMessages };
            languages.forEach((lang: { title: string, value: string }) => {
                if (lang.value !== primaryLanguage) {
                    updatedMessages[lang.value] = text;
                }
            });
            return updatedMessages;
        });
    };

    return (
        <>
            <Accordion>
                <AccordionSummary
                    className={classes.accordianHeader}
                    expandIcon={<ExpandMoreOutlined />}
                    id="default-nodes">
                    <Typography variant='h6'>Welcome Message</Typography>
                </AccordionSummary>
                {(editFrameworkLoading) && <LinearProgress />}
                <AccordionDetails>
                    <Box>
                        {languages.map((lang: { title: string, value: string }) => (
                            <Box key={lang.value} display="flex" alignItems="center" mb={2}>
                                <TextField
                                    fullWidth
                                    label={`Message(${lang.title})`}
                                    onChange={(e) => {
                                        const newValue = e.target.value;
                                        if (lang.value === primaryLanguage) {
                                            handlePrimaryLanguageChange(newValue);
                                        } else {
                                            setTranslatedMessages((prevMessages) => ({
                                                ...prevMessages,
                                                [lang.value]: newValue,
                                            }));
                                        }
                                    }}
                                    value={lang.value === primaryLanguage ? welcomeMessage : translatedMessages[lang.value] || ''}
                                    sx={{ '& .MuiInputBase-root': { height: '60px' } }}
                                />
                                {primaryLanguage !== lang.value && (
                                    <Button
                                        variant="contained"
                                        onClick={() => handleTranslate(lang.value)}
                                        sx={{ marginLeft: '10px', height: '40px', minWidth: '50px' }}
                                    >
                                        Transl
                                    </Button>
                                )}
                            </Box>
                        ))}
                    </Box>
                    {error ? (
                        <>
                            <br />
                            <Typography variant='body1'>{error}</Typography>
                            <br />
                        </>
                    ) : null}

                    <Box className={classes.buttonContainer}>
                        <Button
                            variant="contained"
                            onClick={() => {
                                if (welcomeMessage.trim() === '') {
                                    setError("Please add fallback message");
                                } else if (props.frmeworkId) {
                                    setError('');
                                    editFaq({
                                        variables: {
                                            input: {
                                                welcomeMessage,
                                                welcomeMessageTranslation: JSON.stringify(translatedMessages),
                                                id: props.frmeworkId,
                                                botProfileId: props.botProfileId,
                                                companyId: props.companyId,
                                            },
                                        },
                                    });
                                }
                            }}
                            sx={{ height: '40px' }}
                        >
                            Save
                        </Button>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </>
    );
}

export default WelcomeMessage;
