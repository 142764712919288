import { gql } from "@apollo/client";

export const AGENT_ACTIVITY = gql`
  query AgentActivity($agentId: String!, $selectedDate: Timestamp) {
    agentActivity(agentId: $agentId, selectedDate: $selectedDate) {
      periods {
        startHour,
        endHour,
        status
      }
      day
    }
  }
`;
