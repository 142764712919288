import { useContext, useEffect, useState, useMemo } from "react";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import FormButton from "@material-ui/core/Button";
import { useMutation, useQuery} from "@apollo/client";
import Page from "../../../../components/page/Page";
import CircularProgress from "../../../../components/facebook_progress/FacebookProgress";
import MenuItem from "@material-ui/core/MenuItem";
import Switch from "@material-ui/core/Switch";
import { useHistory } from "react-router-dom";
import { ONBOARD_ADMIN_COMPANY } from "../../../../adapters/mutations/OnboardAdminCompany";
import { useStyles } from "./style";
import FormInput from "../../../../components/form/form_input/FormInput";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { COMPANY_REFETCH } from "../../../../adapters/queries/CompanyRefetch";
// import FormFeedback from "../../../../components/form_feedback/FormFeedback";
import { SnackbarContext } from "components/common_snackbar/CommonSnackBar";
import { FormControlLabel, Grid } from "@mui/material";
import { GET_ALL_SUBSCRIPTIONS } from "adapters/queries/GetAllSubscriptions";


const types = [
  {
    value: "1",
    label: "General",
  },
  {
    value: "2",
    label: "Real Estate",
  },
  {
    value: "3",
    label: "Accounting",
  },
  {
    value: "4",
    label: "Law Firm",
  },
  {
    value: "5",
    label: "Health Care",
  },
  {
    value: "6",
    label: "Others",
  },
];

interface User {
  name: string;
  type: string;
  firstName: string;
  lastName: string;
  email: string;
  plan: string;
  status: number;
}

const schema = yup.object().shape({
  name: yup.string().min(3).required(),
  firstName: yup.string().min(2).required(),
  lastName: yup.string().min(2).required(),
  email: yup.string().min(4).required(),
});

export default function OnBoard() {
  const classes = useStyles();
  const history = useHistory();
  const [status, setStatus] = useState(1);
  const [selectedPlan, setSelectedPlan] = useState<string | null>(null); 
  const { setSnack } = useContext(SnackbarContext);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  

  const handlePlanSelect = (value: string) => {
    setSelectedPlan(value);
  };

  const { data: subscriptionss } = useQuery(GET_ALL_SUBSCRIPTIONS, {})

  const types1 = useMemo(() => {
    if (subscriptionss && subscriptionss.getSubscriptions) {
      
      const subscriptions = subscriptionss.getSubscriptions.map((subscription: any) => ({
        value: subscription.name.toLowerCase(), 
        label: subscription.name,
      }));
      subscriptions.push({ value: "custom", label: "Custom" }); 
      return subscriptions;
    }
    return [];
  }, [subscriptionss]);

  
  
  const [mutate, { loading, error }] = useMutation(ONBOARD_ADMIN_COMPANY, {
    onCompleted: () => {
      history.push("/admin/businesses");
    },
    onError: () => {
      setSnack({ message: "Onboarding failed", severity: 'error', open: true });
      console.log(error);
    },
  });



  useEffect(() => {
    document.title = "OnBoard";
  }, []);

  const onSubmit = ({
    name,
    type,
    firstName,
    lastName,
    email,
    plan,
  }: User) => {
    mutate({
      refetchQueries: [{ query: COMPANY_REFETCH }],
      variables: {
        input: {
          name: name,
          type: type,
          firstName: firstName,
          lastName: lastName,
          email: email,
          plan: plan,
          status: status,
        },
      },
    });
  };  
  return (
    <Page title="OnBoard">
      <Container maxWidth={"xs"}>
        <Card>
          <CardHeader subheader="OnBoard" title="Company" />
          <Divider />
          <form onSubmit={handleSubmit(onSubmit)}>
            <CardContent>
              <FormInput
                control={control}
                type="text"
                name="name"
                label="Business Name"
                error={errors && errors.name}
              />
              <br />
              <br />
              <FormInput
                control={control}
                type="text"
                name="type"
                label="Business Type"
                select="select"
              >
                {types.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </FormInput>
              <br />
              <br />
              <FormInput
                control={control}
                type="text"
                name="firstName"
                label="First Name"
                error={errors && errors.firstName}
              />
              <br />
              <br />
              <FormInput
                control={control}
                type="text"
                name="lastName"
                label="Last Name"
                error={errors && errors.lastName}
              />
              <br />
              <br />
              <FormInput
                control={control}
                type="email"
                name="email"
                label="Email"
                error={errors && errors.email}
              />
              <br />
              <br />
              
                <FormInput
                  control={control}
                  name="plan"
                  label="Plan"
                  select="select"
                  
                >
                  {types1.map((item: { value: string; label: string }) => (
                       <MenuItem key={item.value} value={item.value} onClick={() => handlePlanSelect(item.value)}>
                         {item.label}
                         </MenuItem>
                  ))}
                </FormInput>
                
              <br />
              <br />

              {subscriptionss?.getSubscriptions && subscriptionss?.getSubscriptions
                  .filter((item: { name: any; }) => item.name === selectedPlan) 
                  .map((item: any) =>
              <Grid item xs key={item.id}>
              {/* <Paper > */}
              <>
                <FormInput
                 control={control}
                 type="text"
                 name="price"
                 label="Plan Price"
                 value={"£"+ item.price}
                 disabled
                 />  
                 <br />
                 <br />
                 <FormInput
                 control={control}
                 type="text"
                 name="agents"
                 label="Number of Agents"
                 value={item.agents}
                 disabled
                 />  
                 <br />
                 <br />
                 <FormInput
                 control={control}
                 type="text"
                 name="botProfiles"
                 label="Number of botprofiles"
                 value={item.botProfiles}
                 disabled
                 />  
                 <br />
                 <br />
                         
              </>
              {/* </Paper> */}
              </Grid>
            )
            }
            {selectedPlan === "custom" && (
                <>
                  <FormInput
                  control={control}
                  type="text"
                  name="price"
                  label="Price of the plan"
                   />
                  <br />
                  <br />
                  <FormInput
                    control={control}
                    type="text"
                    name="agents"
                    label="No. of agents alloted" />
                    <br />
                    <br />
                  <FormInput
                    control={control}
                    type="text"
                    name="botProfiles"
                    label="No. of bot profiles alloted" />
                </>                          
              )}
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={(event: any) => {
                          if (event?.target?.checked!) {
                            setStatus(2);
                          } else {
                            setStatus(1);
                          }
                        }}
                        color="primary"
                        name="status1"
                        inputProps={{
                          "aria-label": "primary checkbox",
                        }}
                      />
                    } 
                    label="Status"
                    labelPlacement="start"
                  />
                </Grid> 
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <div className={classes.buttonWrapper}>
                <FormButton
                  className={classes.button}
                  type="submit"
                  color="secondary"
                  variant="contained"
                  fullWidth
                >
                  Create
                </FormButton>
                {loading && (
                  <CircularProgress className={classes.buttonProgress} />
                )}
              </div>
            </CardActions>
          </form>
        </Card>
      </Container>
    </Page>
  );
}
