import { StrictMode } from "react";
import { createRoot } from 'react-dom/client';
// import "./index.css";
import App from "./App";
// import WidgetApp from "./domain/business_customer/widget/App";
// import App from "./domain/business_customer/widget/App";
import reportWebVitals from "./reportWebVitals";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { SnackbarProvider } from "notistack";
import { BrowserRouter as Router } from "react-router-dom";
import store, { persistor } from "./redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { relayStylePagination } from "@apollo/client/utilities";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists

  const state = store.getState();

  const token = state.auth.token;
  // return the headers to the context so httpLink can read them
  //xconst token = GetToken();
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          audits: relayStylePagination(),
          users: relayStylePagination(),
          getAllSessions: relayStylePagination(),
          authLogs: relayStylePagination(),
          appointments: relayStylePagination(),
          rejected: relayStylePagination(),
          requests: relayStylePagination(),
          assigned: relayStylePagination(),
          payments: relayStylePagination(),
          companys: relayStylePagination(),
          fetchMore: relayStylePagination(),
        },
      },
    },
  }),
});

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || "");

// After

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<StrictMode>
  <Router>
    <PersistGate persistor={persistor}>
      <Provider store={store}>
        <ApolloProvider client={client}>
          <SnackbarProvider maxSnack={1}>
            <Elements stripe={stripePromise}>
              <App />
            </Elements>
          </SnackbarProvider>
        </ApolloProvider>
      </Provider>
    </PersistGate>
  </Router>
</StrictMode>);

// ReactDOM.render(
//   ,
//   document.getElementById("root")
// );

// const widgets = document.querySelectorAll(".guidizy-widget");

// // Inject our React App into each class
// if (widgets && widgets.length > 0) {
//   const widget = widgets.item(widgets.length - 1) || 0;
//   ReactDOM.render(
//     <StrictMode>
//       <ApolloProvider client={client}>
//         <WidgetApp widgets={widgets} />
//       </ApolloProvider>
//     </StrictMode>,
//     widget
//   );
// }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
