import { useState } from "react";
import * as React from "react";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  AccountCircle as AccountCircleIcon,
  Menu as MenuIcon,
  NotificationsOutlined as NotificationsIcon,
} from "@mui/icons-material";
import DomainLink from "@mui/material/Link";
import { useStyles } from "./style";
import { logout } from "../../../../redux/actions/AuthActions";
import { RootState } from "redux/reducers/rootReducer";
import IOSSwitch from "./IOSSwitch";
import { updateSession } from "../../../../redux/actions/AgentSessionActions";

interface IProps {
  data?: any | null;
  error?: any | null;
  className?: string;
  isOpen: boolean;
  onMobileNavOpen: () => void;
  setOpen: any;
}

const TopBar = ({ onMobileNavOpen, isOpen, setOpen, ...rest }: IProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [notifications] = useState([]);

  const { user } = useSelector((state: RootState) => state.auth);
  const { agentStatus } = useSelector((state: RootState) => state.agentSession);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const history = useHistory();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const onLogIn = (event: any) => {
    if (user) {
      event.preventDefault();
      dispatch(logout());
    }

    history.push("/login");
  };

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: isOpen,
        })}
        elevation={0}
        {...rest}
      >
        <Toolbar>
          <Hidden mdDown>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: isOpen,
              })}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          <Typography className={classes.title} variant="h6" noWrap>
            {user?.company?.name!}

            <Typography className={classes.title} variant="body2" noWrap>
              <DomainLink
                href={`https://${user?.company?.subdomain!}`}
                color="inherit"
              >
                {user?.company?.subdomain!}
              </DomainLink>
            </Typography>
          </Typography>

          <Box flexGrow={1} />

          <Hidden mdDown>
            {/* Toggle button to change agent status */}
            <IOSSwitch
              disabled={agentStatus == "busy"}
              onChange={() =>
                dispatch(
                  updateSession({
                    agentStatus:
                      agentStatus === "online" ? "offline" : "online",
                    isCallOn: true,
                  })
                )
              }
              checked={agentStatus == "online"}
            />

            <IconButton color="inherit">
              <Badge
                badgeContent={notifications.length}
                color="primary"
                variant="dot"
              >
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <>
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircleIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={handleClose}
                  component={Link}
                  to={`/users/edit/${user?.id}`}
                >
                  {" "}
                  My account
                </MenuItem>
                <MenuItem onClick={onLogIn}> Logout</MenuItem>
              </Menu>
            </>
          </Hidden>
          <Hidden lgUp>
            <IconButton color="inherit" onClick={onMobileNavOpen}>
              <MenuIcon />
            </IconButton>
          </Hidden>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default TopBar;
