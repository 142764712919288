import { useState } from "react";
import * as React from "react";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import AppBar from "@mui/material/AppBar";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import { Hidden } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DomainLink from "@mui/material/Link";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EditIcon from "@mui/icons-material/Edit";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/NotificationsOutlined";
import { Link, useHistory } from "react-router-dom";
import { useStyles } from "./style";
import { logout } from "../../../../redux/actions/AuthActions";
import { RootState } from "redux/reducers/rootReducer";

interface IProps {
  data?: any | null;
  error?: any | null;
  className?: string;
  isOpen: boolean;
  onMobileNavOpen: () => void;
  setOpen: any;
}

const TopBar = ({ onMobileNavOpen, isOpen, setOpen, ...rest }: IProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [notifications] = useState([]);
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { user } = useSelector((state: RootState) => state.auth);
  const open = Boolean(anchorEl);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const onLogIn = (event: any) => {
    if (user) {
      event.preventDefault();
    }
    dispatch(logout());
  };

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const navigateAccount = () => {
    history.push("/account/edit", { state: true });
  }
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: isOpen,
      })}
      elevation={0}
      {...rest}
    >
      <Toolbar>
        <Hidden mdDown>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: isOpen,
            })}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>

        <Typography className={classes.titleName} variant="h6" noWrap >
          {user?.company?.name!}

          <IconButton
            color="inherit"
            size="small"
            className={classes.editIcon}
            onClick={navigateAccount}
          >
            <EditIcon />
          </IconButton>

          {user?.company?.subdomain! ? (
            <Typography className={classes.title} variant="body2" noWrap>
              <DomainLink
                href={`https://${user?.company?.subdomain!}`}
                color="inherit"
              >
                {user?.company?.subdomain!}
              </DomainLink>
            </Typography>
          ) : null}
        </Typography>

        <Box flexGrow={1} />
        <Hidden mdDown>
          <IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircleIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem
                onClick={handleClose}
                component={Link}
                to={`/users/edit/${user?.id}`}
              >
                My account
              </MenuItem>
              <MenuItem onClick={onLogIn}>Logout</MenuItem>
            </Menu>
          </>
        </Hidden>

        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
