import { useState, useContext } from "react";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import TicketForm from './TicketForm'; 
import { CREATE_TICKET } from "../../../../adapters/mutations/TicketAdminCreate";
import { useMutation } from "@apollo/client";
import { SnackbarContext } from "components/common_snackbar/CommonSnackBar";
import { TicketFormData } from "../../../../../src/utils/interfaces";

const CreateTicket = () => {
  const { setSnack } = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);

  const [mutate] = useMutation(CREATE_TICKET, {
    onCompleted: () => {
      setSnack({
        message: "Ticket created successfully",
        severity: "success",
        open: true,
      });
      setLoading(false);
    },
    onError: (error) => {
      console.error(error);
      setSnack({
        message: "Create ticket failed",
        severity: "error",
        open: true,
      });
      setLoading(false);
    },
  });

  const onSubmit = async (data: TicketFormData) => {
    setLoading(true);
    try {
      await mutate({
        variables: {
          ticket: data,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container maxWidth="md">
      <Card>
        <CardHeader title="Create Ticket" />
        <Divider />
        <TicketForm onSubmit={onSubmit} loading={loading} />
      </Card>
    </Container>
  );
}

export default CreateTicket;