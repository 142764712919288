

export const UPDATE_SESSION = "UPDATE_SESSION"

export interface UpdateSessionAction {
    type: typeof UPDATE_SESSION,
    payload: {
        isCallOn: boolean,
        agentStatus: "online" | "offline" | "busy"
    }
}

export type AgentSessionActionTypes = UpdateSessionAction

export const updateSession = (message: {
    isCallOn: boolean,
    agentStatus: "online" | "offline" | "busy"
}): UpdateSessionAction => ({
    type: UPDATE_SESSION,
    payload: message
})
