import React, { useEffect, useMemo, useState } from "react";
import {
  Card,
  Container,
  Grid,
  LinearProgress,
  TablePagination,
} from "@mui/material";
import Page from "../../page/Page";
import { useStyles } from "./style";
import UserToolbar from "../toolbar/Toolbar";
import { useQuery } from "@apollo/client";
import UserItem from "../list_item/ListItemTable";
import { USER_BUSINESS_ADMIN_REFETCH } from "adapters/queries/UserBusinessRefetch";
import Searchbar, { SearchQuery } from "../searchbar/Searchbar";
import { COMPANY_BUSINESS_ADMIN_VIEW } from "adapters/queries/CompanyBusinessAdminView";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";
import { Snackbar } from "@material-ui/core";
import { Alert as MuiAlert, AlertProps } from "@mui/lab";
import { Company } from "utils/interfaces";
import {
  Hidden,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

export function isNotNull<T>(it: T): it is NonNullable<T> {
  return it != null;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function UserList(props: any) {
  //company id
  let { id } = props.match.params;
  const companyId = id;

  //user id
  const { user } = useSelector((state: RootState) => state.auth);
  const userId = user?.id;

  //search
  const [searchQuery, setSearchQuery] = useState<SearchQuery>({
    searchText: "",
    role: "",
  });

  // max user alert
  const [showAlert, setShowAlert] = useState(false);

  const [count, setCount] = useState(5);

  //pagination
  const [page, setPage] = useState(0);

  // style and title
  const classes = useStyles();
  useEffect(() => {
    document.title = "People";
  }, []);

  //query to get all company users
  const { data, fetchMore, refetch, loading } = useQuery(
    USER_BUSINESS_ADMIN_REFETCH,
    {
      variables: {
        companyId,
        ...(searchQuery.searchText != "" && {
          search: searchQuery?.searchText,
        }),
        ...(searchQuery?.role != " " && {
          role: searchQuery?.role,
          count: count,
        }),
      },
    }
  );

  const { users } = data || {};
  const totalCount = users?.totalCount || 0;
  const list = useMemo(() => {
    return users && users.edges
      ? users.edges
          .filter(isNotNull)
          .map((edge: any) => edge.node)
          .filter(isNotNull)
      : [];
  }, [users]);

  const refetchWithCursor = (change: string) => {
    change === "forward" &&
      refetch({
        companyId,
        ...(searchQuery.searchText != "" && {
          search: searchQuery?.searchText,
        }),
        ...(searchQuery?.role != " " && {
          role: searchQuery?.role,
          count: count,
          endcursor: users?.pageInfo?.endCursor,
          reversecount: undefined,
          startcursor: null,
        }),
      });

    change === "backward" &&
      refetch({
        companyId,
        ...(searchQuery.searchText != "" && {
          search: searchQuery?.searchText,
        }),
        ...(searchQuery?.role != " " && {
          role: searchQuery?.role,
          reversecount: count,
          count: undefined,
          startcursor: users?.pageInfo?.startCursor,
          endcursor: null,
        }),
      });
    console.log("refetching");
  };

  const onPageChange = (event: unknown, newPage: number) => {
    if (newPage > page) {
      refetchWithCursor("forward");
    } else {
      refetchWithCursor("backward");
    }
    setPage(newPage);
    console.log("page", newPage);
  };

  useEffect(() => {
    console.log(data);
  }, [data]);

  //query to get max agents
  const { data: companyData } = useQuery(COMPANY_BUSINESS_ADMIN_VIEW, {
    variables: { id: userId },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });
  const company: Company = useMemo(() => {
    return companyData?.company;
  }, [companyData]);

  // show hide max user alert
  const handleClose = () => {
    setShowAlert(false);
  };

  const showUsersAlert = () => {
    setShowAlert(true);
  };

  //search chnages
  useEffect(() => {
    fetchMore({
      variables: {
        ...(searchQuery.searchText != "" && {
          search: searchQuery?.searchText,
        }),
        ...(searchQuery?.role != undefined &&
          searchQuery?.role != " " && {
            role: encodeURIComponent(searchQuery?.role),
          }),
        count: count,
      },
    });
  }, [searchQuery, count]);

  //pagination
  // const LoadMore = () => (
  //   <div className={classes.rootButton}>
  //     {users?.pageInfo?.hasNextPage && (
  //       <Chip
  //         onClick={() =>
  //           fetchMore({
  //             variables: {
  //               cursor: users?.pageInfo?.endCursor,
  //               count: count,
  //               ...(searchQuery?.searchText.trim() != '' && { search: encodeURIComponent(searchQuery?.searchText) }),
  //               ...(searchQuery?.role != ' ' && { role: encodeURIComponent(searchQuery?.role) }),
  //             },
  //           })
  //         }
  //         label="Load More"
  //         className={classes.chip}
  //         variant="outlined"
  //       />
  //     )}
  //   </div>
  // );

  return (
    <Page className={classes.root} title="Users">
      <Container maxWidth={"xl"}>
        <UserToolbar
          showAlert={showUsersAlert}
          maxUsersReached={
            company?.consumedActiveAgents >= company?.maxAllowedAgents
          }
          showWarning={
            company?.consumedActiveAgents >= company?.maxAllowedAgents
          }
        />
        <Searchbar
          onSearch={setSearchQuery}
          showAlert={showUsersAlert}
          maxUsersReached={
            company?.consumedActiveAgents >= company?.maxAllowedAgents
          }
        />
        <Card sx={{ mt: 3 }}>
          <Table>
            <Hidden smDown>
              <TableHead>
                <TableRow sx={{ borderRadius: "10px" }}>
                  <TableCell>Name</TableCell>
                  {user?.role === "admin" && (
                    <TableCell>Company Name</TableCell>
                  )}
                  <TableCell>Email</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Date</TableCell>
                </TableRow>
              </TableHead>
            </Hidden>
            <TableBody>
              {totalCount ? (
                list!.map((node: any) => {
                  if (node == null) {
                    return null;
                  }
                  return (
                    // <Link key={node.id} to={user?.role == "admin" ? `/admin/users/edit/${node.id}` : `/users/edit/${node.id}`}>
                    <UserItem user={node} key={node.id} role={user?.role} />
                    // {/* </Link> */}
                  );
                })
              ) : (
                <Grid
                  className={classes.gridEmptyRecords}
                  container
                  direction="column"
                  xs={12}
                >
                  No users found
                </Grid>
              )}
            </TableBody>
          </Table>
          {loading && <LinearProgress />}
          {/* {(networkStatus === NetworkStatus.refetch)&& <LinearProgress />} */}
          <TablePagination
            component="div"
            count={totalCount}
            onPageChange={onPageChange}
            onRowsPerPageChange={(e) => setCount(parseInt(e.target.value))}
            page={page}
            rowsPerPage={count}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </Card>
        <Snackbar
          open={showAlert}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error">
            Maximum number of User Licences reached
          </Alert>
        </Snackbar>
      </Container>
    </Page>
  );
}
