import clsx from "clsx";
import { Link } from "react-router-dom";
import { useStyles } from "./style";
import {
  ButtonBase,
  ListItem,
  ListItemIcon,
  Box,
  Tooltip,
} from "@mui/material";

interface IProps {
  className?: string;
  href: string;
  icon: any;
  title: string;
}

const NavItem = ({ className, href, icon: Icon, title }: IProps) => {
  const classes = useStyles();
  const route = window.location.pathname;


  return (
    <ListItem
      key={title}
      className={clsx(classes.item, className)}
      disableGutters
    >
      <ButtonBase
        color="primary"
        sx={{
          alignItems: "center",
          borderRadius: 1,
          display: "flex",
          justifyContent: "flex-start",
          pl: "16px",
          pr: "16px",
          py: "6px",
          textAlign: "left",
          width: "100%",
          ...(route === href && {
            backgroundColor: "rgba(255, 255, 255, 0.1)",
          }),
        }}
        // className={classes.button}
        component={Link}
        to={href}
      >        {Icon && (
        <ListItemIcon>
          <Tooltip title={title}>
            <Icon className={classes.icon} sx={{
              color: "neutral.300",
              ...(route === href && {
                color: "common.white",
              }),
            }} />
          </Tooltip>
        </ListItemIcon>
      )}
        <Box
          component="span"
          sx={{
            flexGrow: 1,
            fontFamily: (theme) => theme.typography.fontFamily,
            fontSize: 14,
            fontWeight: 600,
            lineHeight: '24px',
            whiteSpace: 'nowrap',
            color: "neutral.300",
            ...(route === href && {
              color: "common.white",
            }),
          }}
        >
          {title}
        </Box>      </ButtonBase>
    </ListItem>
  );
};

export default NavItem;
