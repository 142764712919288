import { Theme, createStyles, makeStyles } from "@material-ui/core";

const drawerWidth = 240;

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        mobileDrawer: {
            width: drawerWidth
        },
        desktopDrawer: {
            width: drawerWidth
        },
        avatar: {
            cursor: "pointer",
            width: 64,
            height: 64
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: "nowrap"
        },
        drawerOpen: {
            width: drawerWidth,
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen
            })
        },
        drawerClose: {
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen
            }),
            overflowX: "hidden",
            width: theme.spacing(7) + 1,
            [theme.breakpoints.up("sm")]: {
                width: theme.spacing(9) + 1
            }
        },
        hide: {
            display: "none"
        },
        toolbar: {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar
        },
        item: {
            display: "flex",
            paddingTop: 0,
            paddingBottom: 0
        },
        button: {
            color: theme.palette.text.secondary,
            //fontWeight: theme.typography.fontWeightMedium,
            justifyContent: "flex-start",
            letterSpacing: 0,
            padding: "10px 20px",
            textTransform: "none",
            width: "100%"
        },
        icon: {
            marginRight: theme.spacing(1)
        },
        title: {
            marginRight: "auto"
        },
        active: {
            color: theme.palette.primary.main,
            "& $title": {
                fontWeight: theme.typography.fontWeightMedium
            },
            "& $icon": {
                color: theme.palette.primary.main
            }
        }
    })
);
