import clsx from "clsx";
import {Button , ListItem, ListItemIcon, Tooltip , Box} from "@mui/material";
import { useStyles } from "./style";

interface IProps {
  className?: string;
  icon: any;
  title: string;
  onClick: () => void;
}

const LogoutItem = ({ className, onClick, icon: Icon, title }: IProps) => {
  const classes = useStyles();

  return (
    <ListItem
      key={title}
      className={clsx(classes.item, className)}
      disableGutters
    >
      <Button className={classes.button} onClick={onClick}>
        {Icon && (
          <ListItemIcon>
            <Tooltip title={title}>
              <Icon
                className={classes.icon}
                sx={{
                  color: "neutral.300",
                }}
              />
            </Tooltip>
          </ListItemIcon>
        )}
        <Box
          component="span"
          sx={{
            color: "neutral.300",
            fontSize: "1rem",
            fontWeight: 500,
          }}
        >{title}</Box>
      </Button>
    </ListItem>
  );
};

export default LogoutItem;
