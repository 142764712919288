import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/actions/AuthActions";
import { useHistory } from "react-router-dom";

function Logout() {
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(logout());
    setTimeout(() => {
      history.push("/login");
    }, 10);
  }, [dispatch, history]);
  return <div>Logging out...</div>;
}

export default Logout;
