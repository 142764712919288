//@ts-nocheck
import { useContext, useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import { SocketEventContext } from "../../../context/SocketEventContext";
import Jitsi from "./JitsiConfig";
import { useMutation, useLazyQuery } from "@apollo/client";
import { UPDATE_SESSION } from "../../../adapters/mutations/UpdateSession";
import { GET_SESSION_BY_CHATROOM_ID } from "../../../adapters/queries/GetSessionByChatRoomId";
// import { setTimeout } from "timers";
import { callStatus } from "./../../../utils/constants";
import { useHistory } from "react-router-dom";
import { SnackbarContext } from "components/common_snackbar/CommonSnackBar";

const BAConsole = () => {
  const [startCall, setStartCall] = useState<boolean>(false);
  const history = useHistory()
  const [uploadingChat, setUploadingChat] = useState(false)
  const { socketEvent } = useContext(SocketEventContext);
  const { setSnack } = useContext(SnackbarContext);

  console.log("socketEvent", socketEvent);

  // const [mutate, { error }] = useMutation(CREATE_LOG_EVENT, {
  //   onCompleted: () => {
  //     console.log("CREATED");
  //   },
  //   onError: () => {
  //     console.log(error?.message);
  //   },
  // });

  const [getSession, { data }] = useLazyQuery(GET_SESSION_BY_CHATROOM_ID, {
    variables: {
      chatRoomId: socketEvent?.chatRoomId,
    },
    //skip: chatRoomId === "",
  });

  // if socketEvent has changed reload rerender component using useEffect
  useEffect(() => {
    if (socketEvent?.chatRoomId) {
      setTimeout(() => {
        getSession();
      }, 1000);
    }
  }, [socketEvent]);

  const [updateSession] = useMutation(UPDATE_SESSION, {
    onCompleted: () => {
      console.log("Session updated");
    },
    onError: (error) => {
      console.log("update session error", error);
    },
  });

  //As soon as Agent has JOINED the call, then  update the session record ( previously created with "MISSED" status as a result
  // of getting "customer-assigned" socket event) with "ANSWERED" status
  useEffect(() => {
    if (startCall) {
      updateSession({
        variables: {
          input: {
            id: data?.getSessionByChatRoomId?.id,
            status: callStatus.ANSWERED,
          },
        },
      });
    }
  }, [
    socketEvent?.chatRoomId,
    startCall,
    data?.getSessionByChatRoomId?.id,
    updateSession,
  ]);

  const handleClick = (event) => {
    event.preventDefault();

    // mutate({
    //   variables: {
    //     auditLogInput: {
    //       entity: "Agent",
    //       action: "Agent Joined Call",
    //       userId: socketEvent?.agentId,
    //       companyId: socketEvent?.companyId,
    //       customerId: socketEvent?.customerId,
    //     },
    //   },
    // });

    setStartCall(true);
  };

  const handleReadyToClose = (messages) => {
    if (startCall) {
      setUploadingChat(true)
      setStartCall(false)
      console.log(messages);

      setTimeout(() => {
        localStorage.removeItem("chatRoomId");
        history.push("/dashboard");
        // set timeout
        setTimeout(() => {
          // window.location.reload();
        }, 1000);
      }, 5000);
    }
  };

  const uploadRecordingLink = (payload) => {
    console.log("RecordingLink",payload);

  };

  return (
    <div>
      {socketEvent?.chatRoomId && !startCall && !uploadingChat && (
        <>
          <p>{socketEvent && socketEvent.message}</p>
          <Button variant="contained" onClick={handleClick}>
            Join call
          </Button>
        </>
      )}
      {startCall && socketEvent ? <Jitsi onParticipantLeft={() => {
        setSnack({ message: "Participant Left", severity: 'warning', open: true });
      }}
        handleReadyToClose={handleReadyToClose} uploadRecordingLink={uploadRecordingLink} /> : null}
      {uploadingChat && !startCall ? <div>Uploadin Chat.....</div> : null}
    </div>
  );
};

export default BAConsole;
