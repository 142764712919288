import { useEffect, useMemo, useState } from "react";
import { Card, Container, Grid, LinearProgress, TablePagination } from "@mui/material";
import Page from "../../page/Page";
import { useStyles } from "./style";
import CustomerToolbar from "../toolbar/Toolbar";
import { useQuery } from "@apollo/client";
import CustomerItem from "../list_item/ListItemTable";

import Searchbar, { SearchQuery } from "../searchbar/Searchbar";
import {
  Hidden,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { CUSTOMER_BUSINESS_ADMIN_REFETCH } from "adapters/queries/CustomerBusinessRefetch";

export function isNotNull<T>(it: T): it is NonNullable<T> {
  return it != null;
}

export default function CustomerList(props: any) {
  //company id
  let { id } = props.match.params;
  const companyId = id;

  //search
  const [searchQuery, setSearchQuery] = useState<SearchQuery>({
    searchText: "",
  });

  const [count, setCount] = useState(5);

  //pagination
  const [page, setPage] = useState(0);

  // style and title
  const classes = useStyles();
  useEffect(() => {
    document.title = "People";
  }, []);

  //query to get all company customers
  const { data, fetchMore, refetch, loading } = useQuery(CUSTOMER_BUSINESS_ADMIN_REFETCH, {
    variables: {
      companyId,
      ...(searchQuery.searchText != "" && { search: searchQuery?.searchText }),
      count: count,
    },
  });

  const { customers } = data || {};
  const totalCount = customers?.totalCount || 0;
  const list = useMemo(() => {
    return customers && customers.edges
      ? customers.edges
        .filter(isNotNull)
        .map((edge: any) => edge.node)
        .filter(isNotNull)
      : [];
  }, [customers]);

  const refetchWithCursor = (change: string) => {
    change === 'forward' &&
      refetch({
        companyId,
        count: count,
        //@ts-ignore
        endcursor: customers?.pageInfo?.endCursor,
        reversecount: undefined,
        startcursor: null,
        ...(searchQuery.searchText != "" && { search: searchQuery?.searchText }),
      });

    change === 'backward' &&
      refetch({
        companyId,
        //@ts-ignore
        reversecount: count,
        count: undefined,
        startcursor: customers?.pageInfo?.startCursor,
        endcursor: null,
        ...(searchQuery.searchText != "" && { search: searchQuery?.searchText }),
      });
    console.log("refetching");
  };

  const onPageChange = (event: unknown, newPage: number) => {
    if (newPage > page) {
      refetchWithCursor('forward');
    } else {
      refetchWithCursor('backward');
    }
    setPage(newPage);
    console.log("page", newPage);
  };

  useEffect(() => {
    console.log(data);
  }, [data]);

  //search chnages
  useEffect(() => {
    fetchMore({
      variables: {
        ...(searchQuery.searchText != "" && {
          search: searchQuery?.searchText,
        }),
        count: count,
      },
    });
  }, [searchQuery, count]);

  return (
    <Page className={classes.root} title="Customers">
      <Container maxWidth={"xl"}>
        <CustomerToolbar />
        <Searchbar
          onSearch={setSearchQuery}
        />
        <Card sx={{ mt: 3 }}>
          <Table>
            <Hidden smDown>
              <TableHead>
                <TableRow sx={{ borderRadius: "10px" }}>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Agent Sessions</TableCell>
                  <TableCell>Appointments</TableCell>
                  <TableCell>Callbacks</TableCell>
                </TableRow>
              </TableHead>
            </Hidden>
            <TableBody>
              {totalCount ? (
                list!.map((node: any) => {
                  if (node == null) {
                    return null;
                  }
                  return (
                    <CustomerItem customer={node} key={node.id} />
                  );
                })
              ) : (
                <Grid
                  className={classes.gridEmptyRecords}
                  container
                  direction="column"
                  xs={12}
                >
                  No customers found
                </Grid>
              )}
            </TableBody>
          </Table>
          {(loading) && <LinearProgress />}
          {/* {(networkStatus === NetworkStatus.refetch)&& <LinearProgress />} */}
          <TablePagination
            component="div"
            count={totalCount}
            onPageChange={onPageChange}
            onRowsPerPageChange={(e) => setCount(parseInt(e.target.value))}
            page={page}
            rowsPerPage={count}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </Card>
      </Container>
    </Page>
  );
}
