import { gql } from '@apollo/client';

export const UPDATE_ADDON_MUTATION = gql`
  mutation UpdateAddOn($addOn: AddOnUpdateInput!) {
    updateAddOn(addOn: $addOn) {
      id
      name
      description
      allowanceType
      allowanceUnits
      active
      createdAt
      price
      priceId
    }
  }
`;