import { useState } from "react";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "./style";

export default function AuthLogItem(props: any) {
    const classes = useStyles();
    const [checked, setChecked] = useState([0]);

    const handleToggle = (value: number) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    return (
        <ListItem
            className={classes.listItem}
            key={props.authLog.id}
            button
            onClick={handleToggle(props.authLog.id)}
        >
            <Grid container item direction="row">
                <Grid item md={2} sm={6} xs={12}>
                    <Typography>
                        {props.authLog?.user?.firstName!}
                        {props.authLog?.user?.lastName!}
                    </Typography>
                </Grid>

                <Grid item md={2} sm={6} xs={12}>
                    <Typography>{props.authLog.action}</Typography>
                </Grid>

                <Grid item md={2} sm={6} xs={12}>
                    <Typography>{props.authLog.result}</Typography>
                </Grid>

                <Grid item md={2} sm={6} xs={12}>
                    <Typography>{props.authLog.requestIp}</Typography>
                </Grid>

                <Grid item md={2} sm={6} xs={12}>
                    <Typography>
                        {
                            new Date(props?.authLog?.createdAt).toLocaleString()
                        }
                    </Typography>
                </Grid>
            </Grid>
        </ListItem>
    );
}
