// @ts-nocheck
import Jitsi from "../business_customer/JitsiConfigAppointment";

const AppointmentConference = (props: any) => {

  const chatRoomId = `${props?.match?.params?.appId}/${props?.match?.params?.appointmentId}`;

  return (
    <div style={{ height: '100vh', width: '100vw' }}>
      {chatRoomId && (
        <Jitsi chatRoomId={chatRoomId} token={props?.match?.params?.token} onMeetingEnded={() => {
          window.location.replace("https://guidizy.com/")
        }} />
      )}
    </div>
  );
};
export default AppointmentConference;
