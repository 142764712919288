import { gql } from "@apollo/client";
import { LIST_AGENT_AUTH_LOGS } from "../fragments/ListAgentAuthLogs";

export const AUTH_LOG_AGENT_REFETCH = gql`
    ${LIST_AGENT_AUTH_LOGS}
    query authLogs(
        $count: Int = 10
        $cursor: String
    ) {
        ...ListAgent_authLogs_1G22uz
    }
`;
