import { gql } from "@apollo/client";

export const DASHBOARD_PAYMENTS_DATA = gql`
    fragment DashboardPayments_data on Query {
        newPaymentDailyStats {
            label
            data {
                label
                total
                success
                failure
                successAmount
                failureAmount
                totalAmount
            }
        }
        paymentMonthlyStats {
            data {
                label
                amount
            }
        }
        companyDailyStats {
            data {
                label
                active
                inactive
                total
            }
        }
        companyMonthlyStats {
            data {
                label
                count
            }
        }

        sessionDailyStats(days: 7) {
            label
            data {
                date
                count
                duration
            }
        }

        sessionMonthlyStats {
            data {
                label
                count
            }
        }
    }
`;
