import { useForm, Controller } from "react-hook-form";
import { useStyles } from "./style";
import MenuItem from "@material-ui/core/MenuItem";
import {
  CardActions,
  CardContent,
  Button as FormButton,
  Checkbox as MuiCheckbox
} from "@mui/material";
import { SnackbarContext } from "components/common_snackbar/CommonSnackBar";
import { useContext } from "react";
import FormInput from "components/form/form_input/FormInput";
import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { CRM_DATA } from "adapters/mutations/CRMdata";

export function isNotNull<T>(it: T): it is NonNullable<T> {
  return it != null;
}

const types = [
  {
    value: "Zaphier",
    label: "Zaphier",
  },
  {
    value: "Freshdesk",
    label: "Freshdesk",
  },
];

export default function EditCRMsync() {
  interface FormData {
    type: string;
    secretKey: string;
    domain: string;
    isChecked: boolean;
  }




  const {
    control,
    formState: { errors },
    watch,
    handleSubmit
  } = useForm({});
  const classes = useStyles();
  const { setSnack } = useContext(SnackbarContext);
  const [showZaphierDropdown, setZaphierDropdown] = useState(false);
  const [showFreshdeskDropdown, setFreshdeskDropdown] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const typeChange: Array<string> = watch(["type"]);

  const [crm_data] = useMutation(CRM_DATA, {
    onCompleted: ({ createCRMsync }) => {
      console.log("Mutation is successfull", createCRMsync.companyId)
      setSnack({ message: "Record Updated Successfully", severity: 'success', open: true });
    },
    onError: () => {
      console.log("Mutation is failed")
      setSnack({ message: "Editing CRMsync failed", severity: 'error', open: true });
    },
  });


  useEffect(() => {
    if (typeChange[0] === "Zaphier") {
      setZaphierDropdown(true);
      setFreshdeskDropdown(false);
    } else if (typeChange[0] === "Freshdesk") {
      setZaphierDropdown(false);
      setFreshdeskDropdown(true);
    } else {
      setZaphierDropdown(false);
      setFreshdeskDropdown(false);
    }
  }, [typeChange]);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(isChecked)
    setIsChecked(e.target.checked);
    console.log(isChecked)
  };

  const onSubmit = ({ type, secretKey, domain }: FormData) => {
    console.log("this is type", type);
    console.log("this is secretkey", secretKey);
    console.log("Auto sync enabled:", isChecked);
    crm_data({
      variables: {
        input: {
          type: type!,
          secretKey: secretKey,
          domain,
          isAutoSync: isChecked, // Add isAutoSync to the input object
        },
      },
    });
  };


  return (
    <div>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <CardContent>
          <FormInput
            control={control}
            name="type"
            label="Sync method"
            select="select"
            error={errors && errors.Sync}
            fullWidth
          >
            {types.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </FormInput>
          <div style={{ marginBottom: '16px' }} />
          {(showZaphierDropdown || showFreshdeskDropdown) && (
            <FormInput
              control={control}
              type="password"
              name="secretKey"
              label="Secret Key"
              placeholder="Enter your secret key"
              fullWidth
            />
          )}
          <div style={{ marginBottom: '16px' }} />
          {(showFreshdeskDropdown) && (
            <FormInput
              control={control}
              type="text"
              name="domain"
              label="Domain"
              placeholder="i.e. stocksy.freshdesk.com"
              fullWidth
            />
          )}


          <CardContent>
            <Controller
              control={control}
              name="isChecked"
              render={({ field }) => (
                <MuiCheckbox
                  {...field}
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  color="primary"
                />
              )}
            />
            <label>Auto sync the details</label>
          </CardContent>
        </CardContent>
        <CardActions className={classes.buttonContainer}>
          <div className={classes.buttonWrapper}>
            <FormButton
              type="submit"
              color="secondary"
              variant="contained"
              fullWidth
            >
              Submit
            </FormButton>
          </div>
        </CardActions>
      </form>
    </div>
  );
}
