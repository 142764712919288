import { gql } from "@apollo/client";

export const VIEW_ADMIN_BOT_PROFILE = gql`
    fragment ViewAdminBotProfile on BotProfile {
        id
        name
        description
        company {
            id
            name
            type
        }
        isAgentRequired
        isBotInterectionsOn
        isWhatsappRequired
        isChannelSwitchingOn
        isCallbackOn
        isWebRTCVideoRequired
        isScreenSharingRequired
        isAppointment
        isForwardToPhoneRequired
        forwardNumber
        forwardMessage
        status
        priority
        callRecording
    }
`;
