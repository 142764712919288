import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Paper, Button, Link, Typography, Stack, Hidden, Box } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Avatar from "@mui/material/Avatar";
import FormInput from "../../components/form/form_input/FormInput";
import { loginSuccess } from "../../redux/actions/AuthActions";
import { RootState } from "../../redux/reducers/rootReducer";
import { SnackbarContext } from "../../components/common_snackbar/CommonSnackBar";
import { LOG_IN } from "../../adapters/mutations/Login";
import { useStyles } from "./style";
import bot from "../../assets/images/guidizyLogo.jpeg";
import guy from "../../assets/images/Flight-Image-1024x462.png";

const schema = yup.object().shape({
  email: yup.string().min(4).required(),
  password: yup.string().min(5).required(),
});

interface FormData {
  email: string;
  password: string;
}

export default function SignIn() {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useSelector((state: RootState) => state.auth);
  const { setSnack } = useContext(SnackbarContext);
  const role = user?.role;
  const dispatch = useDispatch();
  const [passwordErrorLink, setPasswordErrorLink] =useState();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const [log_in] = useMutation(LOG_IN, {
    onCompleted: ({ authUser }: any) => {
      if (authUser && authUser.link) {
        setPasswordErrorLink(authUser.link)
        setSnack({
          message: "Password reset required. Please reset your password.",
          severity: "error",
          open: true,
        });
      }
      if (authUser && authUser.error) {
        setSnack({
          message: "Login failed",
          severity: "error",
          open: true,
        });
      }

      if (authUser && authUser.token && authUser.user) {
        const user = {
          token: authUser.token,
          ...authUser.user,
        };
        dispatch(loginSuccess(user));
      }
    },
    onError: (error) => {
      setTimeout(() => {
        setSnack({
          message: "Login failed",
          severity: "error",
          open: true,
        });
        console.log(error?.message);
        console.log(error);
      }, 500);
    },
  });

  useEffect(() => {
    if(passwordErrorLink){
      window.location.href = passwordErrorLink;
    }
    if (role === "admin") {
      setTimeout(() => {
        history.replace("/admin/dashboard");
      }, 500);
    } else if (role === "owner" || role === "manager" || role === "agent") {
      setTimeout(() => {
        history.replace("/dashboard");
      }, 500);
    }
  }, [user, role, history, passwordErrorLink]);

  const onSubmit = ({ email, password }: FormData) => {
    log_in({
      variables: {
        input: {
          email: email,
          password: password,
        },
      },
    });
  };

  return (
    <Grid container className={classes.gridContainer}>
      <Grid item xs={12} sm={6}  >
        <Paper className={classes.loginContainer} elevation={0}>
          <Grid item xs={12} sm={10}   >
            <Stack spacing={1}>
              <Stack spacing={1} alignItems="center">
                <Avatar alt="demo" src={bot} sx={{ width: 56, height: 56 }} />
                <Typography variant="h6" align="center">
                  <b>Login</b>
                </Typography>
                <Typography variant="body2" align="center">
                  Don't have an account?{" "}
                  <Link href="/signup/premium" underline="hover" variant="subtitle2">
                    <b>Register</b>
                  </Link>
                </Typography>
              </Stack>

              <form onSubmit={handleSubmit(onSubmit)}>

                <Stack spacing={1}>
                  <Typography variant="body2">
                    <b>Email address*</b>
                  </Typography>
                  <FormInput
                    control={control}
                    type="email"
                    id="email"
                    name="email"
                    label="Email Address"
                    autoComplete="email"
                    fullWidth
                    error={errors?.email}
                  />

                  <Typography variant="body2">
                    <b>Password*</b>
                  </Typography>
                  <FormInput
                    control={control}
                    type="password"
                    id="password"
                    name="password"
                    label="Password"
                    autoComplete="current-password"
                    fullWidth
                    error={errors?.password}
                  />

                  <div className={classes.btnWrapper}>
                    <Button
                      type="submit"
                      name="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                    >
                      Sign In
                    </Button>
                  </div>

                  <Grid container justifyContent="center">
                    <Grid item>
                      <Link
                        href="/forgot_password"
                        underline="hover"
                        variant="subtitle2"
                      >
                        <Typography className={classes.footerText}>
                          <b>Forgot password?</b>
                        </Typography>
                      </Link>
                    </Grid>
                  </Grid>
                </Stack>

              </form>
            </Stack>
          </Grid>
        </Paper>
      </Grid>

      <Hidden smDown>
        <Grid style={{ backgroundColor: '#6366F1' }} item xs={12} sm={6} className={classes.gridItem} >
          <Box display="flex" justifyContent="center" >
            <img src={guy} alt="Guidizy" width="100%" />
          </Box>
        </Grid>
      </Hidden>
    </Grid>
  );
}




